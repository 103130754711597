/* READ ME: Inject service as provider in component - needed for deconstruction of subscriptions, etc */

import { Injectable } from '@angular/core';
import { Subscription, Subject, Observable, map, concatMap, tap, take, lastValueFrom } from 'rxjs';

/* Libraries */
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { DateTime } from 'luxon';

/* Services */
import { TimeService } from './time.service';
import { WidgetService } from './widget.service';
import { ProductsService } from './products.service';
import { ProductLocationService } from './product-location.service';
import { AvailabilityParsing } from 'src/app/v2/classes/availability-parsing';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { RentalService } from 'src/app/services/rental.service';

/* Models */
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { AvailabilityInterface, AvailabilityObject, CartQuantities } from '../models/availability.model';
import { WidgetInterface } from '../models/widget.model';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { Product } from 'src/app/models/storage/product.model';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  getAvailability: Subject<AvailabilityInterface> = new Subject(); // Subscribe to this to get the availability response
  getCollections: Subject<any> = new Subject();

  rentalSubscription: Subscription; // Handles the subscription to the rental collection
  collectionSubscriptions: Subscription = new Subscription();

  rentalsLoaded: boolean = false; // Bool determines if the algorithm should unsubscribe / resubscribe to the rental collection
  protected availabilityParsing: AvailabilityInterface;

  // Maps
  widgetMap: Object = {};
  inventoryPageMap: Object = {};
  productGroupsMap: Object = {};
  productsMap: Object = {};
  productSizeTypesMap: Object = {};
  productSizesMap: Object = {};
  locationsMap: Object = {};

  // Array
  productsArray: Array<any> = [];
  locationsArray: Array<ProductLocation> = [];
  widgetArray: Array<any> = [];

  // Loaded bools
  widgetMapLoaded: boolean = false;
  inventoryPageMapLoaded: boolean = false;
  productGroupsMapLoaded: boolean = false;
  productsMapLoaded: boolean = false;
  productSizeTypesMapLoaded: boolean = false;
  productSizesMapLoaded: boolean = false;
  locationsMapLoaded: boolean = false;
  productSizeInfoLoaded: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private timeService: TimeService,
    private widgetService: WidgetService,
    private productService: ProductsService,
    private productLocationService: ProductLocationService,
    private _currentUser: CurrentUserService,
    private _rentalService: RentalService) { }

    async runAvailabilityAlgorithm(
      startDate: DateTime,
      endDate: DateTime,
      locationID: string,
      companyID: string,
      locations?: ProductLocation[],
      products?: Product[],
      productGroupsMap?: { [key: string]: ProductGroup },
      inventoryPageMap?: { [key: string]: InventoryPage },
      widgetMap?: { [key: string]: WidgetInterface },
      productMap?: { [key: string]: Product } | null,
      cartObj?: Cart,
      ignoreRentalID?: string,
      ignoreProducts?: string[],
      productIDsToAdd?: string[],
      availabilityOverrideConfig?: AvailabilityOverrides): Promise<AvailabilityInterface> {

        console.debug(`AvailabilityService.runAvailabilityAlgorithm(
          ${startDate},
          ${endDate},
          ${locationID},
          ${companyID},
        )`, {
          locations: locations,
          products: products,
          productGroupsMap: productGroupsMap,
          inventoryPageMap: inventoryPageMap,
          widgetMap: widgetMap,
          productMap: productMap,
          cartObj: cartObj,
          ignoreRentalID: ignoreRentalID,
          ignoreProducts: ignoreProducts,
          productIDsToAdd: productIDsToAdd,
          availabilityOverrideConfig: availabilityOverrideConfig
        })

        if (!widgetMap || !productMap || !productGroupsMap || !inventoryPageMap || !locations || !products) {
          this.getCollectionData(companyID);

          let res = await lastValueFrom(this.getCollections.pipe((take(1))));

          widgetMap = widgetMap || res.widgetMap;
          productMap = productMap || res.productsMap;
          productGroupsMap = productGroupsMap || res.productGroupsMap;
          inventoryPageMap = inventoryPageMap || res.inventoryPageMap;
          locations = locations || res.locationsArray;
          products = products || res.productsArray;
      }

      this.computeAvailability(startDate, endDate, locationID, companyID, locations, products, productGroupsMap, inventoryPageMap,
        widgetMap, productMap, cartObj, ignoreRentalID, ignoreProducts, productIDsToAdd, availabilityOverrideConfig);


        let response = await lastValueFrom(this.getAvailability.pipe(take(1)));
        this.availabilityParsing = new AvailabilityParsing(response);

        return this.availabilityParsing;
    }



  /*** @description - Call this to get products Rental Availability / Timeslots */
  async computeAvailability(
    selectedStartDate: DateTime, // search start date
    selectedEndDate: DateTime, // search end date
    selectedLocationID: string, // search / selected location
    companyID: string,
    locations: Array<any>,
    products: Array<any>, // array of products returned from a firebase collection
    productGroupsMapCollection: Object,
    inventoryPageMap: Object,
    widgetMap: Object,
    productMap: Object,
    cartObj: Cart,
    ignoreRentalID?: string, // rentalID to ignore
    ignoreProductIDs?: Array<string>, // list of productIDs to ignore
    productIDsToAdd?: Array<string>,// Daniel uses to keep track of the ID's that are added to the new sudo rental,
    availabilityOverrideConfig?: AvailabilityOverrides | undefined // overrides for the availability algorithm
  ) {

    // If the rental collection has already been subscribed to / requested, unsubscribe
    if (this.rentalsLoaded) {
      this.rentalSubscription.unsubscribe();
    }

    /* EXTRA - END */

    /* SETUP - START */
    /* 1.) Using the above parameters, compute the info needed in order to calculate the availability */
    let info = await this.initSetup(locations, selectedLocationID, this.timeService.convertTimestampToLuxon(selectedStartDate), this.timeService.convertTimestampToLuxon(selectedEndDate), cartObj, ignoreRentalID, availabilityOverrideConfig); // Get necessary info needed to compute algorithm
    // Grab all rentals that occur at any point within the queried date range
    this.rangedAvailabilityObservable(info.sdsOpeningDateTime.minus({ minute: info.maxLT }).toJSDate(), companyID).pipe(take(1)
    ).subscribe(async (partiallyFilteredRentals) => {
      /* EXTRA - START */
      let algoStartTime: number = performance.now(); // Used for measuring algorithm speed (here because it's earlier placement was not getting re-considered by subscription calls)
      let response: AvailabilityObject = {};
      // Continue filtering returned rental documents by date
      /* 2.) Continue to filter rentals by the date constraints that were not already done */
      // Ex: 24hr on single day, standard processing (requires additional filtering that wasn't able to be done via the query to firebase due to technical constraints )
      let { rentals, xtraDateRentals } = await this.filterAndSortRentals(partiallyFilteredRentals, info.sdsOpeningDateTime, info.sdeClosingDateTime, info.maxLT, info)

      /* 3.) Loops through the list of products, filters by location, and creates the default values for the resultSet param being returned by the availabilitity */
      let { customerProductsAsMap, resultSet, productWidgetQuantities, cartQuantities } = await this.createProductGroupMapByLocation(products, selectedLocationID, locations, productGroupsMapCollection, productMap, selectedStartDate, selectedEndDate, info)

      // this.checkIfSearchDatesAreUnavailable(); // not yet implemented

      /* 4.) Distribute rentals to their corresponding productGroup / product in the resultSet object */
      // Additionally, some parameters in this method allow the mini-cart / backend booking to ignore specific IDs / rentals (which is necessary when attempting to calculate availability accurately in this process)
      resultSet = await this.distributeRentals(rentals, xtraDateRentals, resultSet, customerProductsAsMap, ignoreRentalID, ignoreProductIDs, productIDsToAdd);

      /* SETUP - END */

      /* PROCESSING - START */

      /* 1.) Loop through the resultSet obj and calculate the rental availability for each product (for more details & unique interactions - see method description) */
      resultSet = await this.getRentalAvailability(resultSet, info, productGroupsMapCollection);

      /* 2.) Handle increasing cartQty */
      this.countCartQuantities(cartObj, cartQuantities, productWidgetQuantities);
      /* 3.) Loop through the resultSet obj and calculate product specific timeslots according to their rules & rental availability */
      // IMPORTANT: Rental Availability is required to have been finished processing before calling this method
      await this.getTimeslots(resultSet, info, productGroupsMapCollection, cartObj, cartQuantities, productWidgetQuantities);

      /* 4.) Filter the existing timeslots by cart lock */
      await this.filterByCartLock(resultSet, info)

      /* 5.) Handle Product widget assignments on timeslot, calculate cartWidget Quantities, determine if productGroup is available based off product widget requirements */
      await this.filterByProductWidgetAvailability(resultSet, productGroupsMapCollection, inventoryPageMap, widgetMap, productMap, info.isSingleCalendarDay, info, productWidgetQuantities);

      /* 6.) Calculate cart & cart-widget quantities (only active when items are in the cart) */
      await this.calculateCartTotalsAndRemoveAllTimeslots(resultSet, productMap, cartQuantities, info, cartObj?.items[0]?.timeslotType || null); // might have to move this before filterByProductWidget


      /* PROCESSING - END */

      /* RESPONSE - START */
      let algoEndTime = performance.now();
      response['resultSet'] = resultSet;
      response['cartQuantities'] = cartQuantities;
      response['productWidgetTotals'] = productWidgetQuantities;
      response['algoMetadata'] = {
        info: info,
        cartObj: info.cartObj,
        dayStart: selectedStartDate,
        dayEnd: selectedEndDate,
        location: selectedLocationID,
        selectedDaySpan: info.daySpan, // Dayspan based upon search (note: a single day search can have a dayspan of 2 if the item is 24hrs)
        runTime: Number((algoEndTime - algoStartTime) / 1000).toString().match(/^\d+(?:\.\d{0,2})?/)[0],
        runTimeUnit: 'seconds',
        overrides: info.overrides
      }

      /* RESPONSE - END */

      this.getAvailability.next(new AvailabilityParsing(response));
      return response;
    });
  }

  async countCartQuantities(cartObj, cartQuantities, productWidgetQuantities) {
    // Increase cart quantity
    cartObj?.items.forEach((item) => {
      // Add cart quantity for cartItems
      if (cartQuantities[item.parentId + '_' + item.productSizeID]) { // if has product size ID on item (means it's a newer cart)
        cartQuantities[item.parentId + '_' + item.productSizeID].cartQty += 1
      }

      // Increase the product widget quantities cartQty if item occurs in cart (even if item appears as standard item)
      if (productWidgetQuantities[item.parentId + '_' + item.productSizeID]) { // if has product size ID on item (means it's a newer cart)
        productWidgetQuantities[item.parentId + '_' + item.productSizeID].cartQty += 1
      }

      if(item?.widgetList) {
        // Add cart quantity for selected product widgets
        item.widgetList.forEach((widget) => {
          if (widget.widgetType === 'product') {
            let element = widget.element;
            element.options.forEach((option) => { // no need to look for savedWidget (cart widget data always has element cuz snapshot of data)
              if (cartQuantities[element.groupId + '_' + option.sizeID]) { // if has product size ID on item (means it's a newer cart)
                if (option.inputValue) {
                  cartQuantities[element.groupId + '_' + option.sizeID].cartQty += option.inputValue
                }
              }

              // Adds the input value to the correct productGroup size cart quantity
              if (productWidgetQuantities[element.groupId + '_' + option.sizeID]) { // if has product size ID on item (means it's a newer cart)
                if (option.inputValue) {
                  productWidgetQuantities[element.groupId + '_' + option.sizeID].cartQty += option.inputValue
                }
              }
            })
          }
        })
      }
    })

    // // Add cart quantity for selected cart product widgets
    if (cartObj?.cartWidgetList) { // Doesn't exist on some of the older rentals
      cartObj.cartWidgetList.forEach((widget) => {
        if (widget.widgetType === 'product') {
          let element = widget.element;
          element.options.forEach((option) => { // no need to look for savedWidget (cart widget data always has element cuz snapshot of data)
            if (cartQuantities[element.groupId + '_' + option.sizeID]) { // if has product size ID on item (means it's a newer cart)
              if (option.inputValue) {
                cartQuantities[element.groupId + '_' + option.sizeID].cartQty += option.inputValue;
              }
            }

            if (productWidgetQuantities[element.groupId + '_' + option.sizeID]) { // if has product size ID on item (means it's a newer cart)
              if (option.inputValue) {
                productWidgetQuantities[element.groupId + '_' + option.sizeID].cartQty += option.inputValue;
              }
            }
          })
        }
      })
    }
  }

  checkIfSearchDatesAreUnavailable() {
    // /* 6.) Confirm that the selectedStartDate & selectedEndDates don't fall under an unavailable date */
    // // Note: If speed seems to be an issue, can make a bool that allows us to run two dates at once but only one on date-range component
    // let startDateValid = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedStartDate); // test the start date
    // let endDateValid = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedEndDate); // test the end date

    // if (!startDateValid || !endDateValid || selectedEndDate.toUTC().startOf("day") < selectedStartDate.toUTC().startOf("day") || selectedStartDate.toUTC().startOf("day") < DateTime.now().toUTC().startOf("day")) { // if one of the dates are invalid, then return{
    //   info.invalidDate = true; // include this in return if any of the flags above
    // }

    // let sdsUnavailableTimes = this.timeService.getDaysUnavailableTimes(locationObj, selectedStartDate) // an array containing unavailableTimes for the selected start date
    // let sdeUnavailableTimes = this.timeService.getDaysUnavailableTimes(locationObj, selectedEndDate) // an array containing the unavailableTimes for selected end date
  }

  filterByCartLock(resultSet, info) {
    if (info.cartObj.items.length > 0) { // dont bother with processing unless an item exists in the cart
      Object.keys(resultSet).forEach((key, index) => { // loop through productGroups
        prodLoop: for (let product of Object.keys(resultSet[key]['products'])) { // loop through products
          availLoop: for (let timeslot of resultSet[key]['products'][product]['allTimeslots']) { // loop through products availability
            // For faster processing speed, don't generate timeslots that don't match the cart in the first place... takes a lot of implementation. Suggestion: wait till test cases are built to help with testing this new change if made
            let matchesCartLock = timeslot.dayStart.hasSame(this.timeService.clearSeconds(this.timeService.convertTimestampToLuxon(info.cartObj.items[0].dayStart)).setZone(info.selectedTimezone), 'minute') && timeslot.dayEnd.hasSame(this.timeService.clearSeconds(this.timeService.convertTimestampToLuxon(info.cartObj.items[0].dayEnd).setZone(info.selectedTimezone)), 'minute')

            let matchesTimeslotType = true;
            if (info.cartObj?.items[0]?.timeslotType && info.cartObj?.items[0]?.timeslotType != ""){
              matchesTimeslotType = timeslot?.type === info.cartObj?.items[0]?.timeslotType;
            }

            if (!matchesCartLock || !matchesTimeslotType) { // If the time doesn't match the cart lock timeslot, set the timeslot as unavailable
              timeslot['unavailable'] = true;
              timeslot.unavailableData ? timeslot.unavailableData.push(`Doesn't match cart lock`) : timeslot.unavailableData = ["Doesn't match cart lock"];
            }
          }
        }
      })
    }
  }

  getCorrectRentalAvailability(singleCalendarDay, resultSet, widgetGroupID, widgetProductID, is24hrs) {
    if (singleCalendarDay && is24hrs) { // needed because sometimes we can offer hourly and 24 hr for the same item.. on a single day search
      return resultSet[widgetGroupID]['products'][widgetProductID]['secondary']['24hrOnSingleSearchDay']['rentalAvail']
    }
    else {
      return resultSet[widgetGroupID]['products'][widgetProductID]['main']['rentalAvail']
    }
  }

  calculateCartTotalsAndRemoveAllTimeslots(resultSet, productMap, cartQuantities, info, timeslotType?) {
    /* Counts & Handles hasAvailableQuantity bool */
    Object.keys(resultSet).forEach((PGID, index) => { // PG loop
      let uniqueSizes = {};
      prodLoop: for (let product of Object.keys(resultSet[PGID]['products'])) { // Prod loop
        availLoop: for (let availTimeslot of resultSet[PGID]['products'][product]['allTimeslots']) { // Avail loop
          if (!availTimeslot.hasOwnProperty('unavailable')) { // Filter out availabilities that are marked with unavailable

            // NO CART ITEMS - If no items in the cart, must rely on timeslots for bool & sizes
            if (info.cartObj.items.length <= 0) {
              resultSet[PGID]['hasAvailableQuantity'] = true;

              // If the size hasn't already been seen - and is available, then add it to sizesAvail arr
              if (!uniqueSizes[productMap[product].productSizeID]) {
                uniqueSizes[productMap[product].productSizeID] = productMap[product].productSizeID;
                resultSet[PGID]['listOfSizes'][productMap[product].productSizeID].isAvailable = true;
              }
            }

            // HAS ITEMS IN CART
            else { // can increment cartQtys, etc
              if (!timeslotType || timeslotType && timeslotType === availTimeslot.type) { // if the timeslot type matches the cart type
                cartQuantities[PGID + "_" + productMap[product].productSizeID].totalAvail += 1;

                // This is needed so available quantity is only suggested if items remain that aren't already in the cart from that product group
                if ((cartQuantities[PGID + "_" + productMap[product].productSizeID].totalAvail - cartQuantities[PGID + "_" + productMap[product].productSizeID].cartQty) > 0) {
                  resultSet[PGID]['hasAvailableQuantity'] = true;
                }
              }
            }
            break availLoop; // exit avail loop once we find at least one working availability
          }
        }

        // Splits up allTimslots intp availTimeslots and unavailTimeslots
        for (let timeslot of resultSet[PGID]['products'][product]['allTimeslots']) {
          if (!timeslot.hasOwnProperty('unavailable')) { // Filter out availabilities that are marked with unavailable
            resultSet[PGID]['products'][product]['availTimeslots'].push(timeslot); // Add availTimeslot to product
          }
          else {
            resultSet[PGID]['products'][product]['unavailTimeslots'].push(timeslot); // Add availTimeslot to product
          }
        }
        delete resultSet[PGID]['products'][product]['allTimeslots']; // Remove allTimeslots from product
      }
    })

    /* Extra processing needed to determine resultSet sizesAvail if calculations being done based off of cartQty (items are currently in the cart)*/
    // Calculate total available (total - cartTotal = actual)
    Object.keys(cartQuantities).forEach((pgSize) => {
      cartQuantities[pgSize].currentAvail = (cartQuantities[pgSize].totalAvail - cartQuantities[pgSize].cartQty);
      if (cartQuantities[pgSize].currentAvail > 0) {
        const PGID = pgSize.split("_");
        if (!resultSet[PGID[0]]['sizesAvail']) {
          resultSet[PGID[0]]['sizesAvail'] = [];
        }
        resultSet[PGID[0]]['sizesAvail'].push({ sizeID: PGID[1], sizeName: cartQuantities[pgSize].sizeName })
      }
    })
    return cartQuantities;
  }

  // Get all collection updates
  async getCollectionData(companyID, asPromise?, excludeCollections?) {

    if (this.collectionSubscriptions) {
      await this.collectionSubscriptions.unsubscribe();
      this.collectionSubscriptions = new Subscription();
    }

    this.widgetMapLoaded = false;
    this.widgetMap = {};

    this.inventoryPageMapLoaded = false;
    this.inventoryPageMap = {}

    this.productGroupsMapLoaded = false;
    this.productGroupsMap = {}

    this.productsMapLoaded = false;
    this.productsMap = {};

    this.productSizeTypesMap = {};
    this.productSizesMap = {};
    this.productSizeInfoLoaded = false;

    this.locationsMap = {};
    this.locationsMapLoaded = false;

    if (excludeCollections) {
      excludeCollections.forEach((collection) => {
        switch (collection) {
          case 'widgets':
            this.widgetMapLoaded = true;
            this.widgetMap = { excludedCollection: true }
            this.widgetArray = [{ excludeCollections: true }];

            break;
          case 'inventoryPages':
            this.inventoryPageMapLoaded = true;
            this.inventoryPageMap = { excludedCollection: true }
            break;
          case 'productGroups':
            this.productGroupsMapLoaded = true;
            this.productGroupsMap = { excludeCollections: true }
            break;
          case 'products':
            this.productsMapLoaded = true;
            this.productsMap = { excludeCollections: true }
            this.productsArray = [{ excludeCollections: true }];
            break;
          case 'productSizeInfo':
            this.productSizeInfoLoaded = true;
            this.productSizeTypesMap = { excludeCollections: true }
            this.productSizesMap = { excludeCollections: true }
            break;
          case 'productLocations':
            this.locationsMapLoaded = true;
            this.locationsMap = { excludeCollections: true }
            break;
        }
      })
    }

    /* Widget Map */
    if (!this.widgetMapLoaded) {
      this.collectionSubscriptions.add(this.widgetService.getWidgetsWithParamCompanyID(companyID).subscribe((res) => {
        this.widgetMap = {};
        this.widgetMapLoaded = false;
        this.widgetArray = res;
        res.forEach((widget) => {
          this.widgetMap[widget.id] = widget;
        })
        this.widgetMapLoaded = true;
        this.loadCollectionData();
      }))
    }

    /* Inventory Pages */
    if (!this.inventoryPageMapLoaded) {
      this.collectionSubscriptions.add(this.widgetService.getInventoryPagesWithParamCompanyID(companyID).subscribe((res) => {
        this.inventoryPageMap = {};
        this.inventoryPageMapLoaded = false;
        res.forEach((inventoryPage) => {
          this.inventoryPageMap[inventoryPage.id] = inventoryPage
        })
        this.inventoryPageMapLoaded = true;
        this.loadCollectionData();
      }))
    }

    /* Product Groups */
    if (!this.productGroupsMapLoaded) {
      this.collectionSubscriptions.add(this.productService.getProductGroupsByCompanyID(companyID).subscribe((res) => {
        this.productGroupsMap = {};
        this.productGroupsMapLoaded = false;

        res.forEach((productGroup) => {
          this.productGroupsMap[productGroup.id] = productGroup
        })
        this.productGroupsMapLoaded = true;
        this.loadCollectionData();
      }))
    }

    /* Products */
    if (!this.productsMapLoaded) {
      this.collectionSubscriptions.add(this.productService.getProductsByCompanyIDParam(companyID).subscribe((res) => {
        this.productsMap = {};
        this.productsMapLoaded = false;

        this.productsArray = res;
        res.forEach((product) => {
          this.productsMap[product.id] = product;
        })
        this.productsMapLoaded = true;
        this.loadCollectionData();
      }))
    }

    /* Product Size Info */
    if (!this.productSizeInfoLoaded) {
      this.collectionSubscriptions.add(this.productService.getCustomSizeTypesObservable(companyID).pipe(tap((res) => {
        this.productSizeTypesMap = {};
        this.productSizeInfoLoaded = false;
        // this.productSizeTypesMapLoaded = false;
        res.forEach((sizeType) => {
          this.productSizeTypesMap[sizeType.id] = sizeType;
        })
      }), concatMap(() => { return this.productService.getProductSizesWithCompanyID(companyID) }),
        tap((res) => {
          res.forEach((size) => {
            this.productSizesMap[size.id] = size;
          })
        }),
        concatMap(() => { return this.productService.getCustomSizeTypesPromiseDefault() }),
        tap((defaultInfo) => {

          defaultInfo.forEach((sizeType) => {
            this.productSizeTypesMap[sizeType.id] = sizeType

            // Get default sizes for a company
            this.productService.getCustomSizesPromise(sizeType.id).then((data) => {
              data.forEach((size) => {
                this.productSizesMap[size.id] = size;
              })
            })
          })
        })
      ).subscribe(() => {
        //
        this.productSizeInfoLoaded = true;
        this.loadCollectionData();
      }))
    }

    /* Product Locations */
    if (!this.locationsMapLoaded) {
      this.collectionSubscriptions.add(this.productLocationService.getAllProductLocationsObservable(companyID).subscribe(async (res) => {
        this.locationsMap = {};
        this.locationsMapLoaded = false;

        this.locationsArray = res;
        res.forEach((loc) => {
          this.locationsMap[loc.id] = loc;
        })

        this.locationsMapLoaded = true;
        this.loadCollectionData()
      }));
    }

    if (asPromise) {
      let collectionData = await lastValueFrom(this.getCollections.pipe(take(1)));
      this.collectionSubscriptions.unsubscribe();
      return collectionData
    }
  }

  async loadCollectionData() {
    if (this.widgetMapLoaded && this.inventoryPageMapLoaded && this.productGroupsMapLoaded && this.productsMapLoaded && this.locationsMapLoaded && this.productSizeInfoLoaded) {

      // Update collection subject if all collections are loaded
      this.getCollections.next({
        widgetMap: this.widgetMap,
        widgetArray: this.widgetArray,
        inventoryPageMap: this.inventoryPageMap,
        productGroupsMap: this.productGroupsMap,
        productsMap: this.productsMap,
        productsArray: this.productsArray,
        productSizeTypesMap: this.productSizeTypesMap,
        productSizesMap: this.productSizesMap,
        locationsMap: this.locationsMap,
        locationsArray: this.locationsArray
      })
    }
  }
  /* Main Methods */

  async initSetup(locations, selectedLocationID, selectedStartDate, selectedEndDate, cartObj, ignoreRentalID?, availabilityOverrideConfig?: AvailabilityOverrides) {

    /* 1.) Get location info */
    let { selectedLocationObj, locationLTMap, maxLT } = this.getLocationObj(locations, selectedLocationID); // Allows access to the selected location's object info

    /* 2.) Determine if search range occurs over more than one calendar day */
    let isSingleCalendarDay;
    selectedStartDate.hasSame(selectedEndDate, 'day') ? isSingleCalendarDay = true : isSingleCalendarDay = false;

    /* 3.) Get the calendar day selection day span */
    let daySpan = this.getDayspan(selectedEndDate, selectedStartDate);

    /* 4.) Confirm that SDS && SDE are both available */
    // * Verify that search variables follow the locations dotw schedule & unavailable days */
    let { customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedTimezone, selectedTimezoneName, timezoneCurrentDate } = await this.createDOTWandUnavailableDayVariables(selectedLocationObj);

    /* 4b.) Confirm that the selectedStartDate & selectedEndDates don't fall under an unavailable DOTW (separated for override flexibility - (DOTW & Unavailable Days processed at same time in daypicker)) */
    let sdsPassesDOTWCheck = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedStartDate, false, false); // test the start date
    let sdePassesDOTWCheck = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedEndDate, false, false); // test the end date

    /* 4c.) Confirm that the selectedStartDate & selectedEndDates don't fall under an unavailable date / bad DOTW */
    let sdsPassesUnavailableDayCheck = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedStartDate, false, false); // test the start date
    let sdePassesUnavailableDayCheck = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedEndDate, false, false); // test the end date

    /* 4d.) Confirm that the selectedStartDate & selectedEndDates haven't already past */
    let sdsNotInPast = selectedStartDate.setZone(selectedTimezone).startOf('day') >= timezoneCurrentDate.setZone(selectedTimezone).startOf('day');
    let sdeNotInPast = selectedEndDate.setZone(selectedTimezone).startOf('day') >= timezoneCurrentDate.setZone(selectedTimezone).startOf('day');

    // Determine if the start and end dates are available
    let sdsIsAvailable = (sdsPassesDOTWCheck && sdsPassesUnavailableDayCheck && sdsNotInPast)
    let sdeIsAvailable = (sdePassesDOTWCheck && sdePassesUnavailableDayCheck && sdeNotInPast)

    /* 5.) Get the selected dates opening and closing times - based off location */
    // Get opening and closing datetime for both selected dates. The opening and closing time is based on the dates selected and shop schedule for that day
    let { sdsOpeningDateTime, sdsClosingDateTime, sdeOpeningDateTime, sdeClosingDateTime } = this.timeService.getDateTimeByShopHour(selectedLocationObj, selectedStartDate, selectedEndDate, selectedTimezone);

    /* 5b.) If user is searching a single day, may have to see 24 hour times - thus must get that info additionally */
    let singleDay24OpeningDateTime, singleDay24ClosingDateTime, singleDay24_SDEUnavailableTimes = null;
    if (isSingleCalendarDay) {
      let extraDateTimes = this.timeService.getShopScheduleForSingleDate(selectedLocationObj, selectedEndDate.plus({ day: 1 }), selectedTimezone);
      singleDay24OpeningDateTime = extraDateTimes['dateOpen']
      singleDay24ClosingDateTime = extraDateTimes['dateClose']
    }

    /* 6.) Get the sds & sde unavailableTimes*/
    let sdsUnavailableTimes = this.timeService.getDaysUnavailableTimes(selectedLocationObj, selectedStartDate) // an array containing unavailableTimes for the selected start date
    let sdeUnavailableTimes = this.timeService.getDaysUnavailableTimes(selectedLocationObj, selectedEndDate) // an array containing the unavailableTimes for selected end date

    /* 6b.) */
    let firstAvailTimeForToday: DateTime;
    let searchIncludesToday: boolean = false;

    let singleDay24_isAvailable: boolean = true; // determines if end date (when searching for 24 hour availabilities on a single day) as

    if (isSingleCalendarDay) {
      // Determine if Date has validity
      // Verify that the 24hr end date (when searching for single calendar day) is not unavailable
      singleDay24_isAvailable = this.filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedEndDate.plus({ day: 1 },), false, false); // test the start date

      // Get unavailableHours for 24hr end day
      singleDay24_SDEUnavailableTimes = this.timeService.getDaysUnavailableTimes(selectedLocationObj, selectedEndDate.plus({ day: 1 })) // an array containing the unavailableTimes for selected end date
    }

    /* 7.) if search start is on the current shop's calendar day */
    if (selectedStartDate.hasSame(DateTime.now().setZone(selectedTimezone), 'day')) {
      // Current Rental Window
      firstAvailTimeForToday = this.getNextAvailableWindow(DateTime.now().setZone(selectedTimezone), sdsOpeningDateTime, sdsClosingDateTime)
      searchIncludesToday = true;
    }

    /* 8.) Create return obj */
    let info = {
      selectedLocationObj,
      locationLTMap,
      maxLT,
      selectedTimezone,
      selectedTimezoneName,
      sdsOpeningDateTime,
      sdsClosingDateTime,
      sdeOpeningDateTime,
      sdeClosingDateTime,
      sdsUnavailableTimes,
      sdeUnavailableTimes,
      sdsPassesDOTWCheck,
      sdePassesDOTWCheck,
      sdsPassesUnavailableDayCheck,
      sdePassesUnavailableDayCheck,
      sdsNotInPast,
      sdeNotInPast,
      sdsIsAvailable,
      sdeIsAvailable,
      isSingleCalendarDay,
      searchIncludesToday,
      firstAvailTimeForToday: firstAvailTimeForToday,
      cartObj,
      daySpan: daySpan,
      xtraDateInfo: {
        singleDay24OpeningDateTime: singleDay24OpeningDateTime,
        singleDay24ClosingDateTime: singleDay24ClosingDateTime,
        singleDay24_isAvailable: singleDay24_isAvailable,
        singleDay24_SDEUnavailableTimes: singleDay24_SDEUnavailableTimes,
        singleDay24_daySpan: 2
      },
      invalidDate: false,
      overrides: undefined
    };

    // If an availability config was provided
    if (availabilityOverrideConfig) {
      info.overrides = {}

      Object.keys(availabilityOverrideConfig).forEach((key) => {
        info.overrides[key] = { status: availabilityOverrideConfig[key], oldValue: null, newValue: null }
        // Switch / Case
        switch (key) {
          case 'overrideUnavailableHours':
            info.overrides[key].oldValue = { sdsUnavailableHours: sdsUnavailableTimes, sdeUnavailableHours: sdeUnavailableTimes, singleDay24_SDEUnavailableTimes: singleDay24_SDEUnavailableTimes }
            info.overrides[key].newValue = { sdsUnavailableHours: [], sdeUnavailableHours: [], singleDay24_SDEUnavailableTimes: [] }
            info.sdsUnavailableTimes = [];
            info.sdeUnavailableTimes = [];
            break;
          case 'overrideDOTW':
            info.overrides[key].oldValue = { sdsPassesDOTWCheck: sdsPassesDOTWCheck, sdePassesDOTWCheck: sdePassesDOTWCheck }
            info.overrides[key].newValue = { sdsPassesDOTWCheck: true, sdePassesDOTWCheck: true }
            info.sdsPassesDOTWCheck = true;
            info.sdePassesDOTWCheck = true;
            break;
          case 'overrideUnavailableDays':
            info.overrides[key].oldValue = { singleDay24_isAvailable: singleDay24_isAvailable, sdsPassesUnavailableDayCheck: sdsPassesUnavailableDayCheck, sdePassesUnavailableDayCheck: sdePassesUnavailableDayCheck }
            info.overrides[key].newValue = { singleDay24_isAvailable: true, sdsPassesUnavailableDayCheck: true, sdePassesUnavailableDayCheck: true }
            info.xtraDateInfo.singleDay24_isAvailable = true;
            info.sdsPassesUnavailableDayCheck = true;
            info.sdePassesUnavailableDayCheck = true;
            break;
          case 'overrideSearchIncludesToday':
            info.overrides[key].oldValue = info.searchIncludesToday;
            info.overrides[key].newValue = false
            info.searchIncludesToday = false;
            break;
          case 'overridePastDatePrevention':
            info.overrides[key].oldValue = { sdsNotInPast: sdsNotInPast, sdeNotInPast: sdeNotInPast };
            info.overrides[key].newValue = { sdsNotInPast: true, sdeNotInPast: true };
            info.sdsNotInPast = true;
            info.sdeNotInPast = true;
            break;
          default:
            throw 'error: availabilityOverrideConfig key not found'
        }
      })

      // After all overrides have had their values adjusted, make final changes to info parameters that depending on those values
      if(info.overrides.overrideUnavailableDays || info.overrides.overrideDOTW) {
        info.sdsIsAvailable = (info.sdsPassesDOTWCheck && info.sdsPassesUnavailableDayCheck && info.sdsNotInPast);
        info.sdeIsAvailable = (info.sdePassesDOTWCheck && info.sdePassesUnavailableDayCheck && info.sdeNotInPast);
      }

    }
    return info;
  }

  /**
   * @filtersApplied Rentals, Shop Open, Shop Close, Down time, Lead Time, Current Time, Cart Lock, implied: (Search Day Start, Search Day End, Location)
   * @description The @var rentalAvail ignores all timeslot display rules. It simply tells you if a rental is available for the scope searched by the user with the filters above applied
   * The rentalAvailability will be returned as an array of objects containing a windowStart & windowEnd Luxon DateTime object. These windows represent when an item can be rented for.
   * Timeslots require rental availabilities in order to determine if the timeslot is possible. A timeslot is only possible if the timeslot if fully encompassed within a
   * pair of windowStart / windowEnd
   */
  async getRentalAvailability(resultSet, info, productGroupsMapCollection) {
    productGroupLoop: for (let productGroupKey of Object.keys(resultSet)) { // loop through productGroups
      let downTime = this.getDowntime(productGroupsMapCollection, productGroupKey); // downTime for the product

      /* 1.) Calculate availabilities for items / rentals separately  */
      productLoop: for (let pid of Object.keys(resultSet[productGroupKey]['products'])) {
        let avail1 = []
        let avail2 = []

        // If single day searched
        if (info.isSingleCalendarDay) { // If single day, will calculate 24hr based rentalAvail even if item is not a 24 hr based item
          // so that we can use it for product widget comparisons. Ex: 24 hr main item with shop day p widget would fail without allowing the above to happen
          // if (productGroupsMapCollection[productGroupKey]['is24hrsPrice']) {
          if (resultSet[productGroupKey]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentals'].length <= 0) {
            avail2 = [{ windowStart: info.sdsOpeningDateTime, windowEnd: info.xtraDateInfo.singleDay24ClosingDateTime }]
          }
          else {
            avail2 = this.createRentalAvail(resultSet[productGroupKey]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentals'], info.sdsOpeningDateTime, info.sdsClosingDateTime, info.xtraDateInfo.singleDay24ClosingDateTime, downTime);
          }
        }

        /* If no rentals */
        if (resultSet[productGroupKey]['products'][pid]['main']['rentals'].length <= 0) {
          avail1 = [{ windowStart: info.sdsOpeningDateTime, windowEnd: info.sdeClosingDateTime }] // available for entire search period
        }
        else { /* Has rentals */
          avail1 = this.createRentalAvail(resultSet[productGroupKey]['products'][pid]['main']['rentals'], info.sdsOpeningDateTime, info.sdsClosingDateTime, info.sdeClosingDateTime, downTime);
        }

        // Assign rentalAvails
        resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'] = avail1
        resultSet[productGroupKey]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentalAvail'] = avail2

        let mainRentalAvail = resultSet[productGroupKey]['products'][pid]['main']['rentalAvail']
        let secondaryRentalAvail_24hrOnSingleSearchDay = resultSet[productGroupKey]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentalAvail'];
        // main
        this.adjustRentalTimesByCurrentTime(mainRentalAvail, productGroupKey, productGroupsMapCollection, info)
        // secondary
        this.adjustRentalTimesByCurrentTime(secondaryRentalAvail_24hrOnSingleSearchDay, productGroupKey, productGroupsMapCollection, info)

      } // productLoop end
    } // productGroupEnd

    /* This step provides each products rental availability. Only filters out known rentals + their downtimes */
    return resultSet;
  }

  adjustRentalTimesByCurrentTime(rentalAvail, productGroupKey, productGroupsMapCollection, info) {
    for (let i = rentalAvail.length - 1; i >= 0; i--) {
      // current rental window first, then check for cart lock

      let firstAvailTimeForToday = productGroupsMapCollection[productGroupKey]['isStartTime'] ? DateTime.now().setZone(info.selectedTimezone) : info.firstAvailTimeForToday;
      //

      let ws = rentalAvail[i].windowStart;
      let we = rentalAvail[i].windowEnd;

      if (info.searchIncludesToday) {
        if (ws < firstAvailTimeForToday) {
          // ws = firstAvailTimeForToday;
          rentalAvail[i].windowStart = firstAvailTimeForToday
        }

        // If we need to have times available such as 10th 5pm -> 11th 5pm while checking at 10th 5pm, we can change <= to <... but we need to start being consistent on how we handle this
        if (we <= firstAvailTimeForToday) {
          // splice
          rentalAvail.splice(i, 1);
        }
      }
    }
  }

  // Add downtime but limit it to end of day
  plusDowntime(dateTime: DateTime, minutes: number): DateTime {
    const endOf = dateTime.endOf('day')
    const downtime = dateTime.plus({ minutes: minutes })
    if (endOf < downtime) {
      return endOf
    }
    return downtime
  }

  // Subtract downtime and limit it to start of day
  minusDowntime(dateTime: DateTime, minutes: number): DateTime {
    const startOf = dateTime.startOf('day')
    const downtime = dateTime.minus({ minutes: minutes })
    if (downtime < startOf) {
      // 1 min past
      return startOf
    }
    return downtime
  }

  createRentalAvail(rentals, sdsOpeningDateTime, sdsClosingDateTime, sdeClosingDateTime, downTime) {
    const avail = [];

    let i = 0;
    rangeRentalLoop: for (const index of Object.keys(rentals)) {
      const RDS: DateTime = this.timeService.clearSeconds(rentals[index]['dayStart']); //  Rental Day Start
      let RDE: DateTime = this.timeService.clearSeconds(rentals[index]['dayEnd']) // Rental Day End
      const numRentals = Object.keys(rentals).length - 1; // Last Index
      RDE = this.plusDowntime(RDE, downTime)

      // if not extra && no rentals
      if (i == 0) {
        // If the RDS is past the opening time by at least a minute
        if (RDS > sdsOpeningDateTime && !sdsOpeningDateTime.hasSame(RDS, 'minute')) {
          avail.push({ windowStart: sdsOpeningDateTime, windowEnd: RDS }); // Slot generated is (Shop Open -> 1st Rental Day Start)
          avail.push({ windowStart: RDE }); // Prep for next slot
        }
        else {
          // prep for the next availability slot
          if (RDE > sdsOpeningDateTime && !sdsOpeningDateTime.hasSame(RDE, 'minute')) { // if RDS not greater than open, check that RDE is
            avail.push({
              windowStart: RDE
            })
          }
          else { // We can now assume that the entire rental occurs before opening, so first date is opening
            avail.push({ windowStart: sdsOpeningDateTime })
          }
        }
      }
      else { // Triggered when multiple rentals exist. Next windows RDS is based off of the last rentals RDE + DT
        avail[avail.length - 1].windowEnd = RDS;
        avail.push({
          windowStart: RDE
        })
        // When we implement LT, can implement on both sides of this rental
      }

      if (i === numRentals) { // If last index / rental in loop
        // If the last rentals RDE is the past the shop's closing time, remove the prior availability slot prep
          if (RDE > sdeClosingDateTime && !sdeClosingDateTime.hasSame(RDE, 'minute')) {
          avail.splice(avail.length - 1, 1);
        }
        else { // Otherwise, the remaining time in the shops closing day is available, set it as the last rental availabilities windowEnd
          avail[avail.length - 1].windowEnd = sdeClosingDateTime;
        }
      }
      i += 1;
    }
    return avail;
  }

  /**
   * @description Each timeslot is created based upon it's products timeslot rules. Those timeslots are then tested against the products rentalAvailability to determine if it can be offered
   */
  async getTimeslots(resultSet, info, productGroupsMapCollection, cartObj, cartQuantities, productWidgetQuantities) {

    // Loop through all of resultSet's productGroups and products and process times as needed
    productGroupLoop: for (let productGroupKey of Object.keys(resultSet)) { // cycle through all productGroups
      let hasSpecialCase: boolean = false;

      /* Special Cases */
      // ITEM IS ONLY AVAILABLE BY HOURLY RENTALS AND SEARCH RANGE IS MORE THAN ONE CALENDAR DAY
      if (!info.isSingleCalendarDay && productGroupsMapCollection[productGroupKey]['allowRentalByHour'] === true && productGroupsMapCollection[productGroupKey]['allowRentalByDay'] === false && productGroupsMapCollection[productGroupKey]['is24hrsPrice'] === false) {
        resultSet[productGroupKey]['unavailable'] = true;
        resultSet[productGroupKey]['unavailableReason'] = "hourlyOnly";
        resultSet[productGroupKey]['unavailableDesc'] = "This item is only available hourly. Please search a single day to see availabilities for this item.";
        hasSpecialCase = true;
      }

      if (!hasSpecialCase) {
        let downTime = this.getDowntime(productGroupsMapCollection, productGroupKey);
        productLoop: for (let pid of Object.keys(resultSet[productGroupKey]['products'])) { // all products
          let avail = [];

          // Only bother generating timeslots if both sds and sde are available (if hourly / one shop day sds & sde are the same value)
          if((info.sdsIsAvailable && info.sdeIsAvailable) || (info.sdsIsAvailable && info.xtraDateInfo.singleDay24_isAvailable && info.isSingleCalendarDay)){
            /* CUSTOM RENTAL TIMES - TOURS */
            // Custom Rental Times treated separately (currently only allowed on Tours - 1/29/2024)
            if (productGroupsMapCollection[productGroupKey]['isStartTime']) { // Currently tours / custom times only allowed on single day - so only need to verify sds
              // 1.) create list of plausible rental times based off start times & hours
              let possibleAvailability = [];
              productGroupsMapCollection[productGroupKey]['startTime'].forEach((ST) => { // go through each startTime
                let window = {};

                window['dayStart'] = this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime)
                if ((info.sdsOpeningDateTime <= window['dayStart'] && window['dayStart'] < info.sdsClosingDateTime)) { // If the new time is within the sds calendar day (shop schedule for that day)
                  productGroupsMapCollection[productGroupKey]['priceByHour'].forEach((hour) => {
                    // window['dayEnd'] = this.timeService.combineDateAndTime(ST.hour, sdsOpeningDateTime).plus({ hour: hour.hour, minute: downTime });
                    window['dayEnd'] = this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).plus({ hour: hour.hour });
                    if (info.sdsOpeningDateTime <= window['dayEnd'] && window['dayEnd'] <= info.sdsClosingDateTime) {
                      // Must pass like this or something similar because deep cloning ruins data type and can't pass by reference

                      //
                      let pickupUnavail = this.checkAgainstUnavailable(this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime), info.sdsUnavailableTimes);
                      let pointerUnavail = this.checkAgainstUnavailable(this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).plus({ hour: hour.hour }), info.sdeUnavailableTimes);

                      if (!pickupUnavail && !pointerUnavail) { // if both points don't fall under being unavailable

                        // Check if time is fully avail in rentalAvail
                        rentalAvailLoop: for (let index of Object.keys(resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'])) {
                          let rentalAvailDS = resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'][index].windowStart
                          let rentalAvailDE = resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'][index].windowEnd

                          let DS = this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime)
                          let DE = this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).plus({ hour: hour.hour })

                          // DS: DS must be within rentalAvailDS

                          // DE:
                          // Rental + DT must be within the availabilities. Only exception to this is
                          // when DT exceeds the shop close. Which we allow

                          // (DE <= rentalAvailDE && DE.plus({minutes: downTime}) >= sdeClosing))))
                          // Code explained: The check for that is the following: If DE <= rentalAvailDE (if the dayEnd is still within the available) and the dayEnd plus downtime exceeds the closing value, then we allow it to pass\\
                          // DE must still be available in rentalAvail but DE + DT must also exceed sdeClosing

                          if ((rentalAvailDS <= DS) &&
                            (((DE.plus({ minutes: downTime }) <= rentalAvailDE) || (DE <= rentalAvailDE && DE.plus({ minutes: downTime }) >= info.sdeClosing)))) {
                            possibleAvailability.push(
                              {
                                dayStart: this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime),
                                dayEnd: this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).plus({ hour: hour.hour }),
                                type: "hourly",
                                dayStartString: this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).toLocaleString(DateTime.DATETIME_FULL),
                                dayEndString: this.timeService.combineDateAndTime(ST.hour, info.sdsOpeningDateTime).plus({ hour: hour.hour }).toLocaleString(DateTime.DATETIME_FULL),
                                availDaySpan: 1
                            })
                            break rentalAvailLoop;
                          }
                        }
                      }
                    }
                  }) // hours
                }
              })

              avail = [...avail, ...possibleAvailability];
            }

            else {

              // ShopDay, 24hr, hourly - type determination
              let allowShopDayRental = ((productGroupsMapCollection[productGroupKey].allowRentalByDay && !productGroupsMapCollection[productGroupKey].is24hrsPrice && productGroupsMapCollection[productGroupKey].priceByDay.length > 0));
              let allow24hr = ((productGroupsMapCollection[productGroupKey].allowRentalByDay && productGroupsMapCollection[productGroupKey].is24hrsPrice && productGroupsMapCollection[productGroupKey].priceByDay.length > 0));
              let allowRentalByHour = ((productGroupsMapCollection[productGroupKey].allowRentalByHour && productGroupsMapCollection[productGroupKey].priceByHour.length > 0));

              /* SINGLE CALENDAR DAY SEARCHED */
              if (info.isSingleCalendarDay) {

                // Handle hourly times
                if (allowRentalByHour) {
                  let DS = info.sdsOpeningDateTime;

                  if (info.searchIncludesToday) {
                    DS = info.firstAvailTimeForToday;
                  }

                  while (DS <= info.sdeClosingDateTime) {
                    productGroupsMapCollection[productGroupKey]['priceByHour'].forEach(async (i) => {
                      let DE = DS.plus({ hours: i.hour })

                      let timeIsUnavailable = false;

                      // Check unavailables first
                      // Can't await here, if awaiting then times will be inaccurate in differing timezones from the shop
                      info.sdsUnavailableTimes.forEach((unavail) => { // only need to check sds because sds and sde should be the same date for hourly | single date
                        if ((unavail.dayStart <= DS && DS <= unavail.dayEnd) || (unavail.dayStart <= DE && DE <= unavail.dayEnd)) {
                          // time slot is unavailable
                          timeIsUnavailable = true;
                        }
                      })

                      if (!timeIsUnavailable) {
                        rentalAvailLoop: for (let index of Object.keys(resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'])) {
                          let rentalAvailDS = resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'][index].windowStart
                          let rentalAvailDE = resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'][index].windowEnd

                          // DS must be within rentalAvail
                          // DE if == to the closing time, must be within rentalAvail but downtime can be in excess (so don't check downtime)
                          // DE if != closing time, must be within rentalAvail with downtime included
                          if ((rentalAvailDS <= DS) && ((DE.hasSame(info.sdsClosingDateTime, 'minute') ? DE : DE.plus({ minutes: downTime })) <= rentalAvailDE)) {
                            avail.push({ dayStart: DS, dayEnd: DE, dayStartString: DS.toLocaleString(DateTime.DATETIME_FULL), dayEndString: DE.toLocaleString(DateTime.DATETIME_FULL), availDaySpan: info.daySpan, type: "hourly" })
                            break rentalAvailLoop;
                          }
                        }
                      }
                    })
                    DS = DS.plus({ minutes: 30 })
                  }
                }

                // Handle Shop Days
                if (allowShopDayRental) {
                  let shopDay = this.getShopDayTimeSlot(resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'], info.sdsOpeningDateTime, info.sdsClosingDateTime, info.sdsUnavailableTimes, info.sdeUnavailableTimes, info.daySpan, info, true);
                  avail = [...avail, ...shopDay];
                }

                // Handle 24 Hr Days
                // must only offer 24hr option if the end date is available + is 24hr price
                // otherwise, can show 24hr option if the next day is unavailable - when it should not
                if (allow24hr && info.xtraDateInfo.singleDay24_isAvailable) {
                  // getRangeAvailability(pickup, dropoff, sdsClosing, sdeOpening, sdsUnavailableTimes, sdeUnavailableTimes, ri, rentalAvail)
                  // sdsOpening, sdeClosing, sdsClosing, sdeOpening, sdsUnavailableTimes, sdeUnavailableTimes, ri, rentalAvail
                  // since sds and sde are == to the same calendar day - but we're stil showing 24 hours... we must advance the sde times to the next day

                  let results = [];
                  results = this.getRangeAvailability(info.sdsOpeningDateTime,
                    info.xtraDateInfo['singleDay24ClosingDateTime'],
                    info.sdsClosingDateTime,
                    info.xtraDateInfo['singleDay24OpeningDateTime'],
                    info.sdsUnavailableTimes,
                    info.xtraDateInfo['singleDay24_SDEUnavailableTimes'],
                    30,
                    resultSet[productGroupKey]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentalAvail'],
                    downTime,
                    info.xtraDateInfo['singleDay24_daySpan'],
                    info);


                  avail = [...avail, ...results];
                }
              }

              /* MUTILPLE (RANGE) CALENDAR DAYS SEARCHED */
              if (!info.isSingleCalendarDay) {
                //Shop Day Range
                if (allowShopDayRental) {
                  let shopDay = this.getShopDayTimeSlot(resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'], info.sdsOpeningDateTime, info.sdeClosingDateTime, info.sdsUnavailableTimes, info.sdeUnavailableTimes, info.daySpan, info, false);
                  avail = [...avail, ...shopDay];
                }

                // 24 Hour
                // Handle 24 Hr Days
                if (allow24hr) {
                  let results = this.getRangeAvailability(info.sdsOpeningDateTime, info.sdeClosingDateTime, info.sdsClosingDateTime, info.sdeOpeningDateTime, info.sdsUnavailableTimes, info.sdeUnavailableTimes, 30, resultSet[productGroupKey]['products'][pid]['main']['rentalAvail'], downTime, info.daySpan, info);
                  avail = [...avail, ...results];
                }
              }
            }
          }
          resultSet[productGroupKey]['products'][pid]['allTimeslots'] = avail;
        }
      }
    }

    return { cartQuantities, productWidgetQuantities };
  }

  getWidgetData(widget, widgetMap) {
    let element = widget.element;

    // Saved Widget
    if (widget.savedWidgetId !== "") { // saved widget
      element = widgetMap[widget.savedWidgetId].element
    }

    return element
  }

  // , productMap, customerProductsAsMap, cartObj
  async filterByProductWidgetAvailability(resultSet, productGroupsMapCollection, inventoryPageMap, widgetMap, productMap, singleCalendarDay, info, productWidgetQuantities) {

    /* 1.) Loop through all Product Groups in the resultSet - MAIN ITEM*/
    for (let PGID in resultSet) {

      /* 2.) Loop through the Product Groups widgetList and search for a product widget */
      if (productGroupsMapCollection[PGID].hasOwnProperty('inventoryPageId')) {
        let inventoryPageID = productGroupsMapCollection[PGID]['inventoryPageId']
        if (inventoryPageMap[inventoryPageID]) { // If the inventoryPage exists

          let foundProductWidget = false;
          findIfProductWidget: for (let widget of inventoryPageMap[inventoryPageID].widgetList) {
            if (widget.widgetType === 'product') {


              // If the widget that was found happened to have been a cart widget & theres no items in the cart, then ignore further processing for that product widget
              if (widget.isCartWidget && info.cartObj.items.length <= 0) {
                continue;
              }

              foundProductWidget = true;
              break findIfProductWidget;

            }
          }

          if (foundProductWidget) {

            /* 3.) Product Widget found - Loop through each of the main item's products from it's productGroup - MAIN ITEM */
            for (let mainPG_PID in resultSet[PGID]['products']) {

              /* 4.) Loop through each of the main product's availabilities - MAIN ITEM */
              // for (let [index, timeSlot] of resultSet[PGID]['products'][mainPG_PID]['allTimeslots'].entries()) {
              for (let index = resultSet[PGID]['products'][mainPG_PID]['allTimeslots'].length - 1; index >= 0; index--) {
                let exitWidgetChecksEarly = false;
                let timeSlot = resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index];
                let availProductsForTour = 0;

                // Variable holding minNumberOfProductsForTour - Only necessary if item is a tour
                let minProductsAttached = productGroupsMapCollection[PGID].minProductsAttached || 0; // returns min or 0 if variable not set up
                let widgetCount = 0;

                let promises = [];

                widgetListLoop: for (let widget of inventoryPageMap[inventoryPageID].widgetList) {

                  if (widget.widgetType === "product") {
                    let widgetIsRequired;
                    let widgetAppliesToTourProductCount
                    let widgetMin = 0;

                    let widgetGroupID
                    let element;

                    // Get element info (inventory page vs saved widget)
                    element = this.getWidgetData(widget, widgetMap);

                    widgetIsRequired = element.is_required;
                    widgetMin = element.min || 0;
                    widgetAppliesToTourProductCount = element.appliesToTourProductCount
                    widgetGroupID = element.groupId;

                    // If widget is a dropdown min count is = to 1
                    if (element.isDropdown) {
                      widgetMin = 1;
                    }

                    let downTime = this.getDowntime(productGroupsMapCollection, widgetGroupID); // get downtime of product widget so that we can add it to our o.g. timeslot and see if it's still available in rentalAvail

                    if (resultSet[widgetGroupID]) { // only bother counting product widgets whose productGroup are available in that location. (Will not count towards min quantity if not in that location) - reason is because the productGroupMap is already sorted to not have productGroups from that location and this block prevents counting them if not found in that map
                      // if (!widget.isCartWidget) { // only bother counting product widgets that are NOT cart widgets
                      /* 5.) Loop through each product from the widgetList's product Group found via the productWidget - WIDGET ITEM */
                      for (let p of Object.keys(resultSet[widgetGroupID].products)) { // all product from product group listed in product widget
                        let hasDesiredSize = false;

                        // check that the size matches the productGroup
                        /* 5b.)  Loop through options for a size being shown */
                        element.options.forEach((option) => {
                          if (option.sizeID === productMap[p].productSizeID) {
                            hasDesiredSize = true; // if option is the same size, desiredSize was found
                          }
                        })

                        if (hasDesiredSize) { // if desired size is not true, do not bother counting that product towards the totals
                          let rentalAvailForProductWidget = [];

                          let timeslotIs24hrs = false;

                          // console.log(index)
                          // If timeslot is 24hrs then we may need to get different rental availability (if we're searching a single calendar day)
                          if (resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index]['type'] == '24hr') {
                            timeslotIs24hrs = true;
                          }

                          rentalAvailForProductWidget = this.getCorrectRentalAvailability(singleCalendarDay, resultSet, widgetGroupID, p, timeslotIs24hrs)


                          /* 6.) Loop through the Rental Availability in search for a product that's available from rentals (bypasses product timeslot rules) - WIDGET ITEM */
                          prodWidgetAvails: for (let i = 0; i < rentalAvailForProductWidget.length; i++) { // all availabilities for each product

                            // if product widget is a dropdown, must be one of the required sizes in order to say it's unavailable
                            if (!resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index]['unavailable']) { // do not allow unavailable timeslots to be considered (such as cartlock)
                              if (this.timeHasAvailabilityAroundSelectedDate(timeSlot.dayStart, timeSlot.dayEnd, rentalAvailForProductWidget[i].windowStart, rentalAvailForProductWidget[i].windowEnd)) {
                                if (!resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index]['prodWidgets']) { // if doesn't exist yet, create empty arr -> then push
                                  resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index]['prodWidgets'] = [];
                                }

                                // Do not add cart widget - product widgets in each timeslot
                                // (will not be considered unless an item is in the cart.
                                // Additionally update timeslot in case user wants to use this instead of the product widget quantities)
                                if (!widget.isCartWidget) {
                                  resultSet[PGID]['products'][mainPG_PID]['allTimeslots'][index]['prodWidgets'].push(p)
                                }

                                if (Object.keys(productWidgetQuantities).length > 0) { // No need to track this if no items in cart
                                  if (!productWidgetQuantities[widgetGroupID + '_' + productMap[p].productSizeID].uniqueIDs[p]) {
                                    productWidgetQuantities[widgetGroupID + '_' + productMap[p].productSizeID].uniqueIDs[p] = p;
                                    productWidgetQuantities[widgetGroupID + '_' + productMap[p].productSizeID].totalAvail += 1;
                                  }
                                }


                                if (widgetAppliesToTourProductCount) {
                                  availProductsForTour += 1;

                                }
                                widgetCount += 1; // widgetCount for this individual widget
                                break prodWidgetAvails;
                              }
                            }
                          }
                        }
                        promises.push(new Promise((resolve, reject) => { resolve('done') }))
                      }
                      // If widget is required, check that enough products in that productGroup is available
                      if (widgetIsRequired) { // if is productWidget required will show min and max (e.g. checkbox won't)
                        if (widgetCount < widgetMin) { // ensure that num of widgets is at least able to reach min listed if isRequired
                          let unavailData = {
                            reason: "Minimum products not available for the required quantity listed on product widget",
                            dayStart: timeSlot.dayStart,
                            dayEnd: timeSlot.dayEnd,
                            extras: { widgetCount: widgetCount, totalRequired: widgetMin }
                          }
                          resultSet[PGID].products[mainPG_PID].unavailableData ? resultSet[PGID].products[mainPG_PID].unavailableData.push(unavailData) : resultSet[PGID].products[mainPG_PID].unavailableData = [unavailData];
                          // console.log(`splicing index: ${index}`)
                          resultSet[PGID].products[mainPG_PID]['allTimeslots'].splice(index, 1);
                          exitWidgetChecksEarly = true;
                          break widgetListLoop;
                          // resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailable = true;
                          // resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData ? resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData.push(`Minimum products not available for the required quantity listed on product widget. prodsAvail: ${widgetCount}, totalRequired: ${widgetMin}`) : resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData = [];
                        }
                      }
                      // }
                    }
                  }
                }

                if (exitWidgetChecksEarly) {
                  continue; // continue with timeslots instead of checking tours / continuing further processing for the spliced off main PG timeslot
                }

                await Promise.all(promises);

                if (productGroupsMapCollection[PGID].isTour) {
                  if (availProductsForTour < minProductsAttached) { // if items available at the timeslot are less than what's required by the tour then that timeslot is not available

                    let unavailData = {
                      reason: "Minimum products not available for the required quantity listed on the tour",
                      dayStart: timeSlot.dayStart,
                      dayEnd: timeSlot.dayEnd,
                      extras: { prodsAvail: availProductsForTour, totalRequired: minProductsAttached }
                    }
                    resultSet[PGID].products[mainPG_PID].unavailableData ? resultSet[PGID].products[mainPG_PID].unavailableData.push(unavailData) : resultSet[PGID].products[mainPG_PID].unavailableData = [unavailData];
                    resultSet[PGID].products[mainPG_PID]['allTimeslots'].splice(index, 1);



                    // resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailable = true;
                    // // `Minimum products not available for the required quantity listed on the tour. prodsAvail: ${availProductsForTour}, totalRequired: ${minProductsAttached}`
                    // let unavailObj = { reason: "Minimum products not available for the required quantity listed on the tour", prodsAvail: availProductsForTour, totalRequired: minProductsAttached };
                    // resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData ? resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData.push(unavailObj) : resultSet[PGID].products[mainPG_PID]['allTimeslots'][index].unavailableData = [unavailObj];
                  }
                }
              }
            }
          }
        }
      }
    }
    return
  }

  /* Main Methods - END */

  private getNextAvailableWindow(currentTime: DateTime | null, sdsOpeningDateTime: DateTime, sdsClosingDateTime: DateTime): DateTime {
    let rentalWindow: DateTime = currentTime.startOf("hour").plus({ minute: 30 });

    while (true) {
      // Re-evaluate conditions on each iteration
      const isBeforeCurrentTime: boolean = (rentalWindow <= currentTime);
      const isBeforeOpeningTime: boolean = (rentalWindow < sdsOpeningDateTime);
      const isAfterClosingTime: boolean = (rentalWindow > sdsClosingDateTime || rentalWindow.hasSame(sdsClosingDateTime, 'minute'));

      if (isBeforeCurrentTime || isBeforeOpeningTime) { // rentalIncrement
        rentalWindow = rentalWindow.plus({ minute: 30 });
      }
      else if (isAfterClosingTime) { // Break loop if after closing time
        break;
      }
      else { // Break the loop if a valid rentalWindow is found
        break;
      }
    }
    return rentalWindow;
  }

  timeHasAvailabilityAroundSelectedDate(timeSlotDS, timeSlotDE, productAvailDS, productAvailDE) {
    // 10 am -> 4 pm - orginal item time slot
    // 8 am -> 5 pm - rental avail
    // 8 am <= 10am && 4pm <= 5pm

    // Actually, it might just be because we were using downtime before - lets take that out for now - since we apply it in the rental availability processing
    if (productAvailDS <= timeSlotDS && timeSlotDE <= productAvailDE) {
      return true
    }
    else {
      return false
    }

    // Ideally, this is what we're doing: productAvailDS <= timeSlotDS && timeSlotDE.plus({ minutes: downTime }) <= productAvailDE
    // const interval = Interval.fromDateTimes(productAvailDS, productAvailDE) // Creates an interval that must fully encompass or equal the timeSlot DS & DE
    // // Luxon can't process == to due to it comparing references instead of values - so we use hasSame() to check equivalent times & the interval to confirm that it exists within the interval
    // if((interval.contains(timeSlotDS) || productAvailDS.hasSame(timeSlotDS, 'minute')) && interval.contains(timeSlotDE) || productAvailDE.hasSame(timeSlotDE, 'minute')){
    //   return true;
    // }
    // else{
    //   return false;
    // }

    /* This way is no longer used because it wouldn't allow exact time comparisons (due to how luxon compares == between two dateTime objects (ref vs value)) */
    // if (productAvailDS <= timeSlotDS && timeSlotDE.plus({ minutes: downTime }) <= productAvailDE) {
    //   return true
    // }
    // else {
    //   return false
    // }
  }

  getShopDayTimeSlot(rentalAvail, open: DateTime, CLOSE: DateTime, SDS_UNAVAILABLE_TIMES: Array<any>, SDE_UNAVAILABLE_TIMES: Array<any>, daySpan, info, isSingleCalendarDay: boolean) {

    // Handle Shop Days

    if (info.searchIncludesToday) {
      open = info.firstAvailTimeForToday;
    }

    let avail = [];
    let timeIsUnavailable = false;

    /* 1.) Confirm that the open/current and end dates aren't unavailable */
    if (isSingleCalendarDay) { // If single calendar day, Look at opening and closing for that day
      SDS_UNAVAILABLE_TIMES.forEach((unavail) => {
        if ((unavail.dayStart <= open && open <= unavail.dayEnd) || (unavail.dayStart <= CLOSE && CLOSE <= unavail.dayEnd)) {
          timeIsUnavailable = true;
        }
      })
    }
    else { // If multi day shop day: compare sdsOpen for SDS, and compare sdeClose for SDE
      // Check unavail
      SDS_UNAVAILABLE_TIMES.forEach((unavail) => {
        if ((unavail.dayStart <= open && open <= unavail.dayEnd)) {
          timeIsUnavailable = true;
        }
      })

      SDE_UNAVAILABLE_TIMES.forEach((unavail) => {
        if ((unavail.dayStart <= CLOSE && CLOSE <= unavail.dayEnd)) {
          timeIsUnavailable = true;
        }
      })
    }

    /* 2.) If the times were found to be available (passed unavailable checks), confirm that no rentals occur between the dates */
    if (!timeIsUnavailable) {
      // RentalAvail length == 1 if no rentals occur during these time periods
      // Confirm that the dates are indeed the opening/current and close (precision in minutes)

      if (Object.keys(rentalAvail).length == 1 &&
        rentalAvail[0]['windowStart'].hasSame(open, 'minute') &&
        rentalAvail[0]['windowEnd'].hasSame(CLOSE, 'minute')) {
        avail.push({ dayStart: open, dayEnd: CLOSE, dayStartString: open.toLocaleString(DateTime.DATETIME_FULL), dayEndString: CLOSE.toLocaleString(DateTime.DATETIME_FULL), availDaySpan: daySpan, type: "shopDay" });
      }
    }
    return avail;
  }

  getRangeAvailability(sdsOpening, sdeClosing, sdsClosing, sdeOpening, sdsUnavailableTimes, sdeUnavailableTimes, ri, rentalAvail, downTime, daySpan, info) {
    let pickup = sdsOpening

    if (info.searchIncludesToday) {
      pickup = info.firstAvailTimeForToday;
    }

    let dropoff = sdeClosing

    // getRangeAvailability(pickup, dropoff, sdsOpening, sdsClosing, sdeOpening, sdeClosing, sdsUnavailableTimes, sdeUnavailableTimes, minimumRental, ri, hasRentals) {
    let availabilities = [];

    // if has rental, get rental window to start with after adding downtime and leadtime to both pickup and dropoff

    let pointer = dropoff.set({ hour: pickup.toObject().hour, minute: pickup.toObject().minute, second: 0, millisecond: 0 })
    while (pointer <= dropoff && pickup <= sdsClosing) {
      // push the availability
      if (pointer >= sdeOpening) {
        // Check each point against unavailable hours
        let pickupUnavail = this.checkAgainstUnavailable(pickup, sdsUnavailableTimes);
        let pointerUnavail = this.checkAgainstUnavailable(pointer, sdeUnavailableTimes);

        if (!pickupUnavail && !pointerUnavail) { // if both points don't fall under being unavailable

          // Check if time is fully avail in rentalAvail

          rentalAvailLoop: for (let index of Object.keys(rentalAvail)) {
            let rentalAvailDS = rentalAvail[index].windowStart
            let rentalAvailDE = rentalAvail[index].windowEnd

            let DS = pickup;
            let DE = pointer;

            // DS: DS must be within rentalAvailDS

            // DE:
            // Rental + DT must be within the availabilities. Only exception to this is
            // when DT exceeds the shop close. Which we allow

            // (DE <= rentalAvailDE && DE.plus({minutes: downTime}) >= sdeClosing))))
            // Code explained: The check for that is the following: If DE <= rentalAvailDE (if the dayEnd is still within the available) and the dayEnd plus downtime exceeds the closing value, then we allow it to pass\\
            // DE must still be available in rentalAvail but DE + DT must also exceed sdeClosing

            if ((rentalAvailDS <= DS) &&
              (((DE.plus({ minutes: downTime }) <= rentalAvailDE) || (DE <= rentalAvailDE && DE.plus({ minutes: downTime }) >= sdeClosing)))) {
              availabilities.push({ dayStart: pickup, dayEnd: pointer, dayStartString: pickup.toLocaleString(DateTime.DATETIME_FULL), dayEndString: pointer.toLocaleString(DateTime.DATETIME_FULL), availDaySpan: daySpan, type: '24hr' });
              break rentalAvailLoop;
            }
          }
        }
      }
      // Increment by the ri
      pickup = pickup.plus({ minute: ri })
      pointer = pointer.plus({ minute: ri })
    }

    return availabilities;
  }

  checkAgainstUnavailable(point, unavailableTimes) {
    let timeIsUnavailable = false;
    unavailableTimes.forEach((unavail) => {
      if ((unavail.dayStart <= point && point <= unavail.dayEnd) || (unavail.dayStart <= point && point <= unavail.dayEnd)) {
        timeIsUnavailable = true;
      }
    })

    return timeIsUnavailable;
  }

  async distributeRentals(rentals, xtraDateRentals, resultSet, customerProductsAsMap, ignoreRentalID, ignoreProductIDs, productIDsToAdd) {
    // Add rentals to their corresponding products (from date range)
    await this.addRentals("rentals", rentals, customerProductsAsMap, resultSet, ignoreRentalID, ignoreProductIDs, productIDsToAdd);
    await this.addRentals('xtraDateRentals', xtraDateRentals, customerProductsAsMap, resultSet, ignoreRentalID, ignoreProductIDs, productIDsToAdd);
    return resultSet;
  }

  addRentals(rentalLocation, rentalArr, customerProductsAsMap, resultSet, ignoreRentalNums, ignoreProductIDs, productIDsToAdd) {
    rentalArr.forEach((rental) => {
      let rentalProductList;
      // If allProductsID property exists (we are on the new system, product widgets are also stored in this array. Use them. Otherwise, looking at old system rental (doesn't have product widgets in arr))
      rental.hasOwnProperty('allProductsID') ? rentalProductList = rental.allProductsID : rentalProductList = rental.productsID

      // Filter the ignored productIDs from that rentals rentalProductList
      if (ignoreRentalNums && ignoreProductIDs && rental.id === ignoreRentalNums) {
        rentalProductList = rentalProductList.filter(item => !ignoreProductIDs.includes(item));
      }

      // This might be necessary for when adding a product to a booking - when modifying bookings?
      if (ignoreRentalNums && productIDsToAdd && rental.id === ignoreRentalNums) {
        productIDsToAdd.forEach(idToAdd => {
          if (!rentalProductList.includes(idToAdd)) {
            rentalProductList.push(idToAdd)
          }
        })
      }

      rentalProductList.forEach((pid) => {
        /* Used to catch products that were filtered in the productsMap due to where clause in query not aligning with items on rentals (example - rental item is currently inactive in db)....*/
        /* An example to test against would be rentalNumber: 965. This rental has an item that is no longer active which breaks the code below. */
        if (customerProductsAsMap[pid] === undefined) {
          return
        }

        // If item doesn't exist in current location product group (will exclude items that don't belong to that location even if in rental)
        if (resultSet[customerProductsAsMap[pid]['productGroupID']] === undefined) {
          return
        }

        /* Push rental onto appropriate rental and date start */
        if (resultSet[customerProductsAsMap[pid]['productGroupID']]['products'][pid]) {
          if (rentalLocation === 'rentals') {
            resultSet[customerProductsAsMap[pid]['productGroupID']]['products'][pid]['main']['rentals'].push(rental);
          }
          if (rentalLocation === 'xtraDateRentals') {
            resultSet[customerProductsAsMap[pid]['productGroupID']]['products'][pid]['secondary']['24hrOnSingleSearchDay']['rentals'].push(rental);
          }
        }
      })
    })

    return;
  }

  async createProductGroupMapByLocation(products, searchLocation, allLocations, productGroupsMapCollection, productMap, selectedStartDate, selectedEndDate, info) {
    let locationsWithProductsInThem = {};

    /* NOTE - Since the ResultSet is based off of products filtered by available location, the resultSet will only contain products relevant to the search location */

    // Being returned
    let customerProductsAsMap = {};
    let resultSet = {};
    let productWidgetQuantities = {} // empty if no items in cart
    let cartQuantities = {}; // can only be utilized when items are in cart. Otherwise, won't be accurate due to having different nums available for 24 hrs, etc

    products.forEach((product) => {
      if (!product['isAvailable']) {
        return
      }
      customerProductsAsMap[product.id] = product;

      /* Create product map based off quantities */
      // If the product belongs to a group (meaning its a new item), and a customer location has been chosen (date range)
      if (product.productGroupID && productGroupsMapCollection[product.productGroupID]) { // Only attempt to create a productGroupMap for items that belongs to a group and if group exists

        // Look at each products available locations. If the search location is available on the item, continue
        if (productGroupsMapCollection[product.productGroupID].availableLocations) {
          productGroupsMapCollection[product.productGroupID].availableLocations.forEach((location) => { // look through shared availability array

            // Keep track of all locations that have a product attached to them. (Doesn't match if it's what we're searching for)
            if (!locationsWithProductsInThem[location]) {
              locationsWithProductsInThem[location] = true;
            }

            // If the location currently being looked at on the product is the one we're searching for in the query, add to availablity map
            if (location === searchLocation) {
              // check if that product group already exists... if it doesn't make it, otherwise push it on to it
              if (resultSet[product.productGroupID] === undefined) { // if group not already created in map // init it
                resultSet[product.productGroupID] = { products: {}, productData: productGroupsMapCollection[product.productGroupID], pgName: productGroupsMapCollection[product.productGroupID].groupName }
              }

              let cartQuantityObj: CartQuantities = { cartQty: 0, currentAvail: 0, totalAvail: 0, sizeName: productMap[product.id].productSize, sizeTypeID: productMap[product.id].productSizeTypeID, sizeTypeName: productMap[product.id].productSizeType, pgName: productGroupsMapCollection[product.productGroupID].groupName, productGroupID: product.productGroupID, sizeID: productMap[product.id].productSizeID}
              let productWidgetQuantityObj = { totalAvail: 0, sizeID: productMap[product.id].productSizeID, sizeName: productMap[product.id].productSize, sizeTypeID: productMap[product.id].productSizeTypeID, sizeTypeName: productMap[product.id].productSizeType, pgName: productGroupsMapCollection[product.productGroupID].groupName, uniqueIDs: {} }
              // Product Widget Quantities
              if (info.cartObj.items.length > 0) {
                cartQuantities[product.productGroupID + '_' + productMap[product.id].productSizeID] = JSON.parse(JSON.stringify(cartQuantityObj));
                productWidgetQuantities[product.productGroupID + '_' + productMap[product.id].productSizeID] = JSON.parse(JSON.stringify(productWidgetQuantityObj));
              }


              /* Creates init list of sizes for reference on booking flow - START */
              if (!resultSet[product.productGroupID]['listOfSizes']) { // Create list of sizes if not yet created on productGroup
                resultSet[product.productGroupID]['listOfSizes'] = {}
              }
              if (!resultSet[product.productGroupID]['listOfSizes'][productMap[product.id].productSizeID]) { // Add the size as a key on the object if not yet created
                resultSet[product.productGroupID]['listOfSizes'][productMap[product.id].productSizeID] = { sizeID: productMap[product.id].productSizeID, sizeName: productMap[product.id].productSize, sizeTypeID: productMap[product.id].productSizeTypeID, sizeTypeName: productMap[product.id].productSizeType, pgName: productGroupsMapCollection[product.productGroupID].groupName, isAvailable: false }
              }
              /* - END */

              resultSet[product.productGroupID]['products'][product.id] = { main: {}, secondary: {}, productSize: product.productSize, productSizeID: product.productSizeID, productID: product.id };

              resultSet[product.productGroupID]['products'][product.id]['main']['rentals'] = []; // holds rentals
              resultSet[product.productGroupID]['products'][product.id]['main']['rentalAvail'] = []; // holds rental availability

              resultSet[product.productGroupID]['products'][product.id]['secondary']['24hrOnSingleSearchDay'] = []; // holds rentals
              resultSet[product.productGroupID]['products'][product.id]['secondary']['24hrOnSingleSearchDay']['rentals'] = []; // holds rentals
              resultSet[product.productGroupID]['products'][product.id]['secondary']['24hrOnSingleSearchDay']['rentalAvail'] = []; // holds rental availability

              resultSet[product.productGroupID]['products'][product.id]['allTimeslots'] = []; // Overall timeslots available from both main & secondary
              resultSet[product.productGroupID]['products'][product.id]['availTimeslots'] = []; // All available timeslots
              resultSet[product.productGroupID]['products'][product.id]['unavailTimeslots'] = []; // All unavailable timeslots

              resultSet[product.productGroupID].hasAvailableQuantity = false;
            }
          })
        }
      }
    })
    return { customerProductsAsMap, resultSet, productWidgetQuantities, cartQuantities }
  }

  getDayspan(selectedEndDate, selectedStartDate) {
    let daySpan = selectedEndDate.diff(selectedStartDate, ["days", "hours", "minute"]).toObject().days + 1 // Provides daySpan (number of calendar days in search range) - Note, edgecase: 24 hr items can be shown on single day spans
    return daySpan;
  }

  getDowntime(productGroupsMapCollection, groupID) {
    let downTime = 0;
    // Try Catch
    if (productGroupsMapCollection[groupID]) {
      if (productGroupsMapCollection[groupID].hasOwnProperty('downtime')) {
        downTime = productGroupsMapCollection[groupID]['downtime'];
      }
    }
    return downTime; // temporary until we actually have values in our products for these
  }


  filterAndSortRentals(partiallyFilteredRentals: Rental[], sdsOpeningDateTime: DateTime, sdeClosingDateTime: DateTime, maxLT: number, info) {
    let rentals: Rental[] = [], // holds all the rental docs after filtering
    outsideRentalData: Rental[] = [], // holds rentals that exist outside of search range but needed for lead time referencing
    xtraDateRentals: Rental[] = [],
    xtraDateOutsideRentalData: Rental[] = [];

    /* 1.) Finish filtering the rentals by date */
    partiallyFilteredRentals.forEach((rental: Rental) => {

      // Convert to Luxon DateTime objects
      rental.dayStart = this.timeService.convertTimestampToLuxon(rental.dayStart);
      rental.dayEnd = this.timeService.convertTimestampToLuxon(rental.dayEnd);

      // If rentals are in date range based off SELECTED SEARCH RANGE
      if (rental.dayStart <= sdeClosingDateTime && rental.dayEnd >= sdsOpeningDateTime) { // rentals are in selected date range
        rentals.push(rental);
      }
      // else { (needs to also be done for xtraDateInfo)
      //   outsideRentalData.push(rental) // leadTime // rentals outside of range and used for lead time comparison purposes
      // }

      // Only happens if user is searching a singular calendar day and wants to see 24 hour item availabilities - these should not be null
      if (info.xtraDateInfo.singleDay24OpeningDateTime != null && info.xtraDateInfo.singleDay24ClosingDateTime != null) {
        // If rentals are in date range because we're searching SINGULAR CALENDAR DAY AND NEED 24 HOUR ITEM AVAILABILITIES
        if (rental.dayStart <= info.xtraDateInfo.singleDay24ClosingDateTime && rental.dayEnd >= sdsOpeningDateTime) {
          xtraDateRentals.push(rental);
        }
        // else {
        //   xtraDateOutsideRentalData.push(rental) // leadTime // rentals outside of range and used for lead time comparison purposes
        // }
      }
    })

    /* 2.) Sort rentals by startDate (past -> current) */

    // Sort all remaining unfiltered dates by startDate
    rentals.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    })

    outsideRentalData.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    })

    xtraDateRentals.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    })

    xtraDateOutsideRentalData.sort(function (a, b) {
      return a.dayStart - b.dayStart;
    })

    return { rentals, outsideRentalData, xtraDateRentals, xtraDateOutsideRentalData }
  }

  getLocationObj(locations: Array<any>, selectedLocationID: string) {
    let selectedLocationObj; // obj info containing selected location info
    let locationLTMap = {}; // An object holding the amount of lead time between each location
    let maxLT = 0; // The greatest amount of lead time found

    locations.forEach((location) => {

      /* 1.) If location has a leadtime, store it in @var locationLTMap & save the greatest value of leadtime found */
      // if (location.leadtime) {
      //   location.leadtime.forEach((lt) => {
      //     maxLT = Math.max(maxLT, lt.minutes); // the max leadTime from location A - B
      //     locationLTMap[lt.locationA + "_" + lt.locationB] = lt;
      //   })
      // }

      /* 2.) Once the selected location is found, save it's object info */
      if (location.id === selectedLocationID) {
        selectedLocationObj = location;
        return;
      }
    })

    return { selectedLocationObj, locationLTMap, maxLT }
  }

  createDOTWandUnavailableDayVariables(locationObj) {
    // Reset calendar available and unavailable days
    let customerScheduleWeekFormatedFromDbToLuxon = [];
    let customerUnavailableDaysSingle = [];
    let customerUnavailableDaysAnnually = [];

    // Set timezone Info
    let selectedTimezone = locationObj['timezone'];
    let selectedTimezoneName = locationObj['timezoneName'];

    // // Set timezones current date
    let timezoneCurrentDate = DateTime.now().setZone(selectedTimezone)

    // /* Set available days on calendar */
    // // NOTE: Luxon library treats monday as first day of the week (monday -> sunday), our db treats sunday as first day of the week (sunday -> saturday)
    for (let i = 1; i < locationObj['scheduleWeek'].length; i++) {
      if (locationObj['scheduleWeek'][i].available) { // Loop through the locations shop schedule and push the available days of the week into the var
        customerScheduleWeekFormatedFromDbToLuxon.push(i)
      }
    }

    // // Check and push Sunday separately (because Sunday is treated as index 7 (in luxon) while it's treated as index 0 in our db)
    if (locationObj['scheduleWeek'][0].available) {
      customerScheduleWeekFormatedFromDbToLuxon.push(7)
    }

    // /* Set unavailable days on calendar */
    locationObj['unavailableDays'].forEach((day) => {
      let DateTimeObj = this.timeService.convertTimestampToLuxon(day.date)
      if (day.periodicity === "One Time") { // Set the days as unavailable for this year
        customerUnavailableDaysSingle.push(DateTimeObj.setZone(selectedTimezone));
      }
      if (day.periodicity === "Every year") { // Set the days as unavailable every year
        customerUnavailableDaysAnnually.push(DateTimeObj.setZone(selectedTimezone));
      }
    })

    return { customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, selectedTimezone, selectedTimezoneName, timezoneCurrentDate }
  }

  filterDOTWandUnavailableDays(customerScheduleWeekFormatedFromDbToLuxon, customerUnavailableDaysSingle, customerUnavailableDaysAnnually, calendarDate, overrideUnavailableDays: boolean, overrideDOTW: boolean) {
    let dayAvailable = false; // Marks the day as available or unavailable in date picker (when returned via this method)

    /* Filter - Day of the week */
    // 1.) First, check that the day of the week is in the defined shop schedule. If true, set to available.

    if (overrideDOTW) { // If we're wanting to bypass the filtering by day of the week, set all days to available
      dayAvailable = true;
    }
    else {
      customerScheduleWeekFormatedFromDbToLuxon.forEach((dotw) => { // Look at locations DOTW and see if current calendar date matches
        if (calendarDate.weekday === dotw) {
          dayAvailable = true;
        }
      })
    }

    if (!overrideUnavailableDays) {
      /* Filter - Unavailable Days (Single) */
      // 2.) Next, loop through the unavailable days (single) and see if the day being checked against is in the list.
      customerUnavailableDaysSingle.forEach((dateTimeObj) => {
        if (calendarDate.hasSame(dateTimeObj, 'day')) { // implies same day, calendar year, and month
          dayAvailable = false;
        }
      })

      /* Filter - Unavailable Days (Annual) */
      // 3.) Lastly, loop through the unavailable days (annually) and see if the day being checked has the same month/day as any of the dates
      customerUnavailableDaysAnnually.forEach((dateTimeObj) => {
        // Maybe not the most secure way in case luxon updates their properties.. but can't find a method that works the same for this purpose
        if (calendarDate['c']['month'] === dateTimeObj['c']['month'] && calendarDate['c']['day'] === dateTimeObj['c']['day']) {
          dayAvailable = false;
        }
      })
    }

    return dayAvailable;
  }

  /**
   * @description Takes the resultSet and sorts the products into a singular arr by sizeOrder
   */
  sortResultSetProducts(resultSet, productMapCollection, sizeTypeCollection) {
    let sortedArr = [];

    Object.keys(resultSet)
    for (let PGID in resultSet) {
      let sortResult;
      sortResult = Object.keys(resultSet[PGID].products).sort((a, b) => {
        return sizeTypeCollection[productMapCollection[a]['productSizeTypeID']].sortOrder.indexOf[productMapCollection[a]['productSizeID']] - sizeTypeCollection[productMapCollection[a]['productSizeTypeID']].sortOrder.indexOf[productMapCollection[b]['productSizeID']]
      })

      for (let product of sortResult) {
        sortedArr.push(productMapCollection[product])
      }
    }
    return sortedArr;
  }

  /**
 * @description subscribes to rentals where the rental ends after the inputted start date.
 * @notes This method is meant to be used in conjunction with another method that filters the dayStart by the dayEnd (rental start <= inputed day end) */
  rangedAvailabilityObservable(dayStart, companyID): Observable<any> {
    return this.afs.collection('rentals', ref => ref
      .where('companyID', '==', companyID)
      .where("isCancelled", "==", false)
      // .where("isComplete", "==", false)
      // iscomplete canot be false for this algo in order for dt and lt to still be processed before search range
      .where("isConfirmed", "==", true)
      .where("dayEnd", ">=", dayStart)
    )
      .snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }

  getWidgetsWithParamCompanyID(companyID): Observable<any> { //Gets entire list of widgets
    return this.afs.collection('widgets', ref => ref
      .where('companyID', '==', companyID)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }

  getInventoryPagesWithParamCompanyID(companyID): Observable<any> { //Gets entire list of invenotry pages
    return this.afs.collection('inventoryPages', ref => ref
      .where('companyID', '==', companyID)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data;
        })
      }));
  }

  async getNextAvailableDate(): Promise<any> {
    const company = this._currentUser.currentUser.currentCompany;
    console.log(company);
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let availabledays: string[] = [];

    const pLocation = await this._rentalService.getDefaultLocation(company.defaultLocation);

    for (let i = 0; i < weekdays.length; i++) {
      const element = weekdays[i];
      const findelement = pLocation.scheduleWeek.find(
        (schedule) => schedule.day === element
      );
      if (findelement?.available) {
        availabledays.push(element);
      }
    }

    if (availabledays.length === 0) {
      return null; // No available days
    }

    let status = true;
    let i = 0;
    let dt = '';
    const currentDate = new Date();

    do {
      i++;
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + i);

      const dayName = weekdays[futureDate.getDay()];
      let res = availabledays.find((available) => available === dayName);

      if (res) {
        status = false;
        dt = futureDate.toDateString();
      }
    } while (status);

    if (pLocation.unavailableDays) {
      pLocation.unavailableDays.forEach((date) => {
        const unavailableDate = new Date(date.date.seconds * 1000);
        if (unavailableDate.toDateString() === dt) {
          i++;
          const futureDate = new Date(currentDate);
          futureDate.setDate(currentDate.getDate() + i);
          dt = futureDate.toDateString();
        }
      });
    }

    const nextAvailableDate = new Date(currentDate);
    nextAvailableDate.setDate(nextAvailableDate.getDate() + i);
    nextAvailableDate.setHours(23, 59, 0, 0);
    
    const actualAvailableDate = new Date();
    actualAvailableDate.setHours(23, 59, 0, 0);

    return { nextAvailableDate, actualAvailableDate };
  }
  
  public getDefaultAvailabilityOverride(): AvailabilityOverrides {
    return {
      overrideUnavailableHours: true,
      overrideUnavailableDays: true,
      overrideSearchIncludesToday: true,
      overrideDOTW: true,
      overridePastDatePrevention: true
    }
  }


  /**
 * @description When this service is injected to a component as a provider, we can trigger our unsubscribes on that components deconstructor */
  ngOnDestroy(): void {
    // alert('destroying availability service')
    if (this.rentalsLoaded) {
      this.rentalSubscription.unsubscribe();
    }

    if (this.collectionSubscriptions) {
      this.collectionSubscriptions.unsubscribe();
    }
  }
}
