import { ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Maintenance } from 'src/app/models/storage/maintenance.model';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { Rental } from 'src/app/models/storage/rental.model';
import { ProductRental } from 'src/app/models/rental.model';
import { LogService } from 'src/app/services/log.service';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { OrderProcessingService } from 'src/app/services/order-processing.service';
import { RentalService } from 'src/app/services/rental.service';
import { ProductsService } from 'src/app/services/products.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { NgxImageCompressService } from "ngx-image-compress";
import { RentalProduct } from 'src/app/models/order-processing.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { lastValueFrom, Subscription, take } from 'rxjs';
import { WidgetService } from 'src/app/services/widget.service';
import { DateTime } from 'luxon';
import { TimeService } from 'src/app/services/time.service';
import { PricingService } from 'src/app/services/pricing.service';
import { CartService } from 'src/app/services/cart.service';
import { uuidv4 as uuid } from '@firebase/util';
import { Company } from 'src/app/models/storage/company.model';
import { environment } from 'src/environments/environment';
import { BackendBookingsService } from 'src/app/services/backend-bookings.service';
import { Cart } from 'src/app/models/storage/cart.model';
import { CartItem } from 'src/app/models/cart.model';

import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { AvailabilityInterface, CartQuantities } from 'src/app/models/availability.model';
import { WidgetInterface, WidgetType } from 'src/app/models/widget.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model'
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-products-table2',
  templateUrl: './booking-products-table2.component.html',
  styleUrls: ['./booking-products-table2.component.scss'],
  providers: [AvailabilityService]
})
export class BookingProductsTable2Component implements OnInit {

  // rental
  @Input('company') companyObject: Company;
  @Input() rental: Rental
  @Input() rentalTotalPaid: number = 0; // bookings, just set rental.amountPaid
  @Input() editBookingOpen: boolean; // if edit booking is open, mini cart can not be interacted with
  @Output() rentalTotalsChanged = new EventEmitter<Rental>();
  @Output() miniCartRentalUpdate = new EventEmitter();
  @Output() rentalModifiedUpdate = new EventEmitter();

  productIsVisible = true
  costIsVisible = true
  checkoutIsVisible = true
  checkinIsVisible = true
  repairIsVisible = true
  tuneupIsVisible = true
  cleaningIsVisible = true
  addOnsIsVisible = true
  markAsReadyIsVisible = true


  // @Input("rentalData") rental: Rental
  protected imgco = [];
  protected pdfco = [];
  protected imgci = [];
  protected hideEditProductPrice: boolean = true;
  protected disabledmaintenance: boolean = true;
  protected productsselect = [];
  protected product: {
    priceDiscount: number,
    priceDiscount3: number,
    priceDiscount7: number,
    priceDiscount30: number,
    id: "",
    price: 0,
    description: "",
    productName: "",
    productNumber: "",
    productSize: "",
    days: 1,
  }
  protected id: any;
  protected position: any;
  protected dataPI: any = {
    stripeID: "",
    paymentID: "",
    isTesting: ""
  }
  protected productcost: number
  protected days: number
  protected totalproduct: number
  protected invalidform: boolean = false;
  protected fleetTax: number;
  protected companyTax: number;
  protected waiversArray: string[]
  protected waiversSigned: number = 0;
  protected multipleWaivers: boolean = false;
  protected selectedProduct: string
  protected iproduct: any;
  protected customerAccount: any;
  protected paymentMethod: any;
  protected metadata: any;
  protected addonsbyproduct = []
  protected lastcost = 0
  protected lastsubtotal = 0
  protected finallascost = 0
  private lastrental: any
  protected isEditing = false
  maintenanceForm: UntypedFormGroup;
  maintenanceSubmitted: boolean = false;

  protected maintenancereg: Maintenance = {
    companyName: '',
    companyID: '',
    productName: '',
    rentalID: null,
    rentalNumber: '',
    productID: '',
    date: new Date(),
    isCleaning: false,
    isTuneUp: false,
    isDamaged: false,
    isReady: false,
    description: ''
  };
  protected addonsresp: RentalProduct["addons"] = [{
    title: ""
  }];
  protected priceobject = {
    title: ""
  }
  protected productPrepared: any[] = [{
    id: 0,
    position: 0,
    rentalNumber: '',
    product: '',
    productSize: '',
    addons: [{
      addonType: '',
      id: '',
      optionSelected: [{
        detail: '',
        price: '',
        title: '',
        qty: 0,
        option: false,
        description: "",
        text: ""
      }],
      price: 0,
      title: '',
      qty: 0,
      imagesco: [{
        id: "",
        dateUpload: "",
        productID: "",
        productName: "",
        rentalID: "",
        type: "",
        url: ""
      }],
      imagesci: [{
        id: "",
        dateUpload: "",
        productID: "",
        productName: "",
        rentalID: "",
        type: "",
        url: ""
      }]
    }],
    productNumber: '',
    rentalcost: '',
    isPrepared: false,
    isCheckedOut: false,
    isCheckedIn: false
  }];
  protected productdata: any;
  protected checkedoutdata: any;
  protected checkedindata: any;
  protected productsModel: any;
  protected editAddons: boolean = false;
  protected editProduct: boolean = false;
  protected stop: boolean = false;
  protected firstime: boolean = true;
  protected productsByName = {

  }
  protected ProductByRental = {
    cost: 0,
    description: '',
    isCheckedIn: false,
    isCheckedOut: false,
    isPrepared: false,
    productName: '',
    productNumber: 0,
    productSize: '',
    addons: [{
      addonType: '',
      companyID: '',
      description: '',
      id: '',
      isPreselected: false,
      optionSelect: [{
        description: '',
        price: 0,
        qty: 0
      }],
      title: ''
    }]
  }


  protected groupInventoryPage;
  protected companyID: string;
  protected editExistingRental: boolean = false;
  protected rentalEditingIndex: number | null = null;
  private subs = new Subscription(); // group of subscriptions
  private collectionsSubscription: Subscription;
  private rentalSubscription: Subscription;
  protected isLoading: boolean = false;
  protected selectedNewWidget: WidgetInterface = null;
  private cartItem: CartItem;
  protected addonPriceChange: number = 0;
  protected productPrice: number = 0;
  protected totalProductPrice: number;
  private productGroupsMap: { [key:string] : ProductGroup };
  private productsMap: { [key:string] : Product };
  protected widgetStructureListCopy;
  discountCodeLoaded = false;
  protected availProductsList: CartQuantities[] = [];
  protected availIsLoading: boolean = false;
  private widgetArray: WidgetInterface[] = [];
  protected selectedNewProduct: CartQuantities | null = null;
  protected addingNewProduct: boolean;
  private productSizesMap = {};
  private productSizeTypesMap = {};
  protected inventoryPageMap: { [key:string] : InventoryPage } = {};
  protected discountCodeMap = {};
  protected rentalModified: boolean = false;
  protected isDataAvailable: boolean = false;
  protected newProductLoading: boolean = false;
  private originalAllProductIDs: string[];
  private currentAllProductIDs: string[];
  protected savingRentalChanges: boolean = false;
  protected isEditingCartWidgets: boolean = false;
  protected isAvailabilityOverride: boolean = false;
  protected cartWidgetsAddonsDisplay: any[] = [];
  protected rentalDayspan: number;
  protected rentalSelectedHours;
  protected luxDayStart: DateTime;
  protected luxDayEnd: DateTime;
  protected rentalPriceWithNoCartWidgets: number;
  protected cartAndProducts: { item: CartItem, product: ProductRental }[]
  private availability: AvailabilityInterface;
  private timer: ReturnType<typeof setTimeout>;

  @ViewChild('priceproduct', { static: false })
  priceproduct: ElementRef;

  constructor(
    private _cd: ChangeDetectorRef,
    private _afs: AngularFirestore,
    private rentalService: RentalService,
    private _orderService: OrderProcessingService,
    private _logService: LogService,
    private _maintenanceService: MaintenanceService,
    private _productService: ProductsService,
    private afFun: AngularFireFunctions,
    private imageCompress: NgxImageCompressService,
    private _currentUser: CurrentUserService,
    private fb: UntypedFormBuilder,
    private inventoryPageService: InventoryPageService,
    private availabilityService: AvailabilityService,
    private widgetService: WidgetService,
    private timeService: TimeService,
    private pricingService: PricingService,
    private currentUserService: CurrentUserService,
    private cartService: CartService,
    private backendBookingsService: BackendBookingsService,
    private router: Router
  ) {
        this.maintenanceForm = this.fb.group({
      cleaningCheck: [false],
      tuneupCheck: [false],
      damagedCheck: [false],
      unavailableCheck: [false],
      description: ['', Validators.required]
    }, { validators: atLeastOneCheckboxSelected() });
  }

  async ngOnInit() {
    this.setUpUI()
    this.getWaiversByRental();
    this.getSubs();
    this.isDataAvailable = false;
    this.rental = await this.inventoryPageService.createAddonsDisplayArrayByProduct(this.rental);
    this.cartWidgetsAddonsDisplay = this.inventoryPageService.createAddonsDisplayArray(this.rental.cartWidgetList);
    this.originalAllProductIDs = JSON.parse(JSON.stringify(this.rental['allProductsID']))

    // only continue to run availability if this succeeds
    if (!this.getRentalDayspanAndSelectedHours(this.rental)) {
      this.isDataAvailable = true
      return
    }

    if (this.rental?.availabilityOverrideConfig) {
      this.isAvailabilityOverride = true;
    }
    else {
      this.isAvailabilityOverride = false;
    }
    // Will give you all availabilities including the current rental products availabilities
    // You need to filter out the availabilities and cart quantities manually
    this.runAvailabilityAlgo(this.rental, this.rental.id, this.originalAllProductIDs);

    // this.createProductIDMap();
    this.cartAndProducts = this.getCartAndProducts();
  }

  async ngOnChanges(changes: SimpleChanges) {

    if (changes.rental) {
      this.isDataAvailable = false;
      this.resetAllValues();
      this.originalAllProductIDs = JSON.parse(JSON.stringify(this.rental['allProductsID']));
      this.rental = await this.inventoryPageService.createAddonsDisplayArrayByProduct(this.rental);
      this.cartWidgetsAddonsDisplay = this.inventoryPageService.createAddonsDisplayArray(this.rental['cartWidgetList']);

      // only continue to run availability if this succeeds
      if (!this.getRentalDayspanAndSelectedHours(this.rental)) {
        this.isDataAvailable = true
        return
      }

      if (this.rental?.availabilityOverrideConfig) {
        this.isAvailabilityOverride = true;
      }
      else {
        this.isAvailabilityOverride = false;
      }
      this.runAvailabilityAlgo(this.rental, this.rental.id, this.originalAllProductIDs);
    }
  }


  getSubs(){
    this.companyID = this._currentUser.currentUser.companyId;

    this.subs.add(this.cartService.getStatusActiveDiscountsParamCompanyID(this.companyID).subscribe((data) => {
      this.discountCodeMap = {};
      data.forEach((code) => {
        this.discountCodeMap[code.discountTitle.toLowerCase()] = code; // discount code based off title (assumes that validation was done on creation to only allow status: (active + unique name)
      })
      this.discountCodeLoaded = true;
    }))

    this.currentUserService
    .getCompanyInfoByID(this.rental.cartObj['companyId'])
    .then(async (data) => {
      this.companyObject = data;
    });


  }

  // return boolean status
  getRentalDayspanAndSelectedHours(rental: Rental): boolean {
    try {
      this.luxDayStart = this.timeService.convertTimestampToLuxon(rental.dayStart).setZone(this.rental.timeZone);
      this.luxDayEnd = this.timeService.convertTimestampToLuxon(rental.dayEnd).setZone(this.rental.timeZone);
      this.rentalDayspan = this.availabilityService.getDayspan(this.luxDayEnd, this.luxDayStart);
      this.rentalSelectedHours = this.backendBookingsService.getSelectedHoursBasedOnRentalType(this.rental.rentalType, this.rental);
      return true
    } catch (err) {
      console.error(`failure in getRentalDayspanAndSelectedHours(${rental?.id})`, {err, rental})
    }
    return false
  }


  async getWaiversByRental() {
    const company = await this.rentalService.getCompanyByID(this._currentUser.currentUser.companyId);
    if (company.multipleWaivers) {
      this.waiversArray = [];
      this.multipleWaivers = true;
      for (let i = 0; i < this.rental.productsID.length; i++) {
        const element = this.rental.productsID[i];
        const product = await this.rentalService.getWaiversByProduct(element);
        if (product.waivers) {
          product.waivers.forEach(waiverid => {
            const found = this.waiversArray.find(element => element == waiverid);
            found ? "" : this.waiversArray.push(waiverid)
          })
        }
      }
    } else {
      if (company.companyWaiver != "" && company.companyWaiver != undefined) {
        //this.waiversArray.push("first");
      }
    }
    this.rental.waiverSignature ? this.waiversSigned = 0 : ""
    if (this.rental.waiversSigned) {
      this.waiversSigned += this.rental.waiversSigned.length
    }
  }
  //Checks if a product has addons or not, returns boolean
  checkNoSelect(array) {
    let notSelected = true
    array.forEach((item, index) => {
      if (item.noSelected == false) {//Indicates an addon was selected
        notSelected = false;
      }
    })
    if (notSelected == true) {//Returns true if there are no addons selected
      return true
    }
    else {
      return false //Returns false if there are addons
    }
  }

  uploadPDFWaiver(id: any, event: any) {
    let pdfFile = event.target.files;
    for (let i = 0; i < pdfFile.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile[i]);
      reader.onloadend = () => {
        this.pdfco.push(reader.result);
        this._orderService.uploadPDF(id, reader.result, id + "_waiver", "CheckedOut").then(pdfFile => {
          this.rental.waiver = [pdfFile];
          this._orderService.updateWaiver(this.rental.id, this.rental);
          this._logService.addRentalLog(this.rental.id, "Waiver Document Uploaded", "Bookings");
        });
      }
    }
  }

  getProductsSelect() {
    this._productService.getProducts(true).subscribe(element => {
      this.productsselect = []
      element.forEach(data => {
        this.productsselect.push({
          id: data.id,
          number: data.productNumber,
          name: data.productName,
          size: data.productSize
        })
      })
    })
  }

  createCharge(newcost, oldcost, oldfinaltotal, productrentalaux) {
    let chargecost = (newcost - oldcost);
    productrentalaux.cost = oldcost;
    productrentalaux.finalTotal = oldfinaltotal;
    let fleetTax = Number(chargecost * this.fleetTax / 100)
    Swal.fire({
      title: 'Processing Charge',
      html: '<div style="height: 100px"><span>Please wait while processing charge...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowOutsideClick: false,
      showConfirmButton: false
    })
    this.afFun.httpsCallable('retrievePayment')(this.dataPI).subscribe(result => {
      let customerAccount = result.customer;
      let paymentMethod = result.payment_method;
      let metadata = result.metadata;
      let difference
      var dataCharge = {
        title: "Charge by new payment in the booking #" + productrentalaux.rentalNumber,
        customer: customerAccount,
        id: this.dataPI.paymentID,
        amount: Number(chargecost.toFixed(2)),
        fleetTax: this.fleetTax,
        fleetTaxNumber: Math.round(Number(fleetTax.toFixed(2))),
        totaltotransfer: difference,
        paymentMethod: paymentMethod,
        destination: this.dataPI.stripeID,
        companyID: this.dataPI.paymentID,
        amountDestination: difference,
        transactiondata: metadata,
        isDeposit: false
      }
      this.afFun.httpsCallable('createCharge')({ data: dataCharge, isTesting: this.rentalService.isTesting }).subscribe(datatransfer => {
        if (datatransfer.code) {
          Swal.fire({
            title: "Error creating Charge",
            html: "Please contact the administrator to see the problem. Error code bpc01.",
            icon: "error"
          })
        } else {
          if (productrentalaux.chargesID == undefined) {
            productrentalaux.chargesID = [{
              chargeID: datatransfer.id,
              cost: Number(chargecost.toFixed(2)),
              date: new Date()
            }]
          } else {
            productrentalaux.chargesID.push({
              chargeID: datatransfer.id,
              cost: Number(chargecost.toFixed(2)),
              date: new Date()
            })
          }
          datatransfer['type'] = 'charge';
          datatransfer['rentalID'] = this.rental.id;
          datatransfer.metadata['type'] = 'charge';
          datatransfer.metadata['rentalID'] = this.rental.id;
          datatransfer.metadata['title'] = "Charge cause increment of total";
          datatransfer.metadata['companyID'] = this.rental.companyID;
          this.rentalService.addStripeTransaction(datatransfer);
          this._orderService.updatePrepared(productrentalaux.id, productrentalaux);
          this.rentalService.getRentalByIDPromise(this.rental.id).then(rental => {
            this.lastrental = rental;
          })
          $('#ProductModal').modal('hide'); //Hide the modal
          Swal.fire({
            title: 'The charge was made and the changes were saved',
            icon: 'success'
          })
        }
      })
    })
  }
  createRefund(newcost, oldcost, oldfinaltotal, productrentalaux) {
    let difference = (oldcost - newcost)
    productrentalaux.cost = oldcost;
    productrentalaux.finalTotal = oldfinaltotal;
    Swal.fire({
      title: 'Saving Booking',
      html: '<div style="height: 100px"><span>Please wait while processing refund...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowOutsideClick: false,
      showConfirmButton: false
    })
    this.afFun.httpsCallable('retrievePayment')(this.dataPI).subscribe(result => {
      let pricePayment = result.amount / 100;
      let status = false;
      let paymentID = "";
      let position = 0;
      let cost = 0;
      if (Number(difference.toFixed(2)) < pricePayment) {
        status = true;
        paymentID = result.id;
      } else {
        for (let i = 0; i < productrentalaux.chargesID.length; i++) {
          const element = productrentalaux.chargesID[i];
          if (Number(difference.toFixed(2)) < element.cost) {
            cost = element.cost;
            position = i;
            status = true;
            paymentID = element.chargeID;
          }
        }
      }
      if (status) {
        let dataRefund = {
          isTesting: this.rentalService.isTesting,
          stripeID: this.dataPI.stripeID,
          paymentID: paymentID,
          amount: Number(difference.toFixed(2)) * 100,
          description: "Refund cause total decrease",
          companyID: productrentalaux.companyID,
          email: productrentalaux.userInfo.email,
          id: productrentalaux.id
        }
        this.afFun.httpsCallable('refundPaymentAmount')(dataRefund).subscribe(resultrefund => {
          if (resultrefund.code) {
            Swal.fire({
              title: "Error creating Refund",
              html: "Please contact the administrator to see the problem. Error code bpr01.",
              icon: "error"
            })
          } else {
            if (productrentalaux.refundsID == undefined) {
              productrentalaux.refundsID = [{
                refundID: resultrefund.id,
                amount: Number(difference.toFixed(2)),
                date: new Date()
              }]
            } else {
              productrentalaux.refundsID.push({
                refundID: resultrefund.id,
                amount: Number(difference.toFixed(2)),
                date: new Date()
              })
            }

            resultrefund['type'] = 'refund';
            resultrefund['rentalID'] = this.rental.id;
            resultrefund.metadata['type'] = 'refund';
            resultrefund.metadata['rentalID'] = this.rental.id;
            resultrefund.metadata['title'] = "Refund cause decrease of total";
            resultrefund.metadata['companyID'] = this.rental.companyID;
            this.rentalService.addStripeTransaction(resultrefund);
            this.rentalService.updateRental(productrentalaux, productrentalaux.id)
            this.lastcost = this.rental.cost
            this._logService.addRentalLog(productrentalaux.id, "Booking Change, refund amount: " + difference.toFixed(2), "Bookings");
            $('#ProductModal').modal('hide'); //Hide the modal
            Swal.fire({
              title: 'The refund was made successfully',
              icon: 'success'
            })
          }
        })
      }
    })

  }
  async addProduct() {
    let totaldays = moment(this.rental.dayEnd.seconds * 1000).diff(moment(this.rental.dayStart.seconds * 1000), 'days') + 1;
    let productprice = 0
    if (totaldays >= 1 && totaldays < 3) {
      this.product.priceDiscount ? productprice = this.product.priceDiscount : productprice = this.product.price
    } else if (totaldays >= 3 && totaldays < 7) {
      this.product.priceDiscount3 ? productprice = this.product.priceDiscount3 : productprice = this.product.price
    } else if (totaldays >= 7 && totaldays < 30) {
      this.product.priceDiscount7 ? productprice = this.product.priceDiscount7 : productprice = this.product.price
    } else if (totaldays >= 30) {
      this.product.priceDiscount30 ? productprice = this.product.priceDiscount30 : productprice = this.product.price
    }
    let canAdd: boolean = true
    let productRentalStructure = {
      cost: Number((productprice * totaldays).toFixed(2)),
      description: this.product.description ? this.product.description : "",
      isCheckedIn: false,
      isCheckedOut: false,
      isPrepared: false,
      productName: this.product.productName,
      productNumber: this.product.productNumber,
      productSize: this.product.productSize,
      addons: [],
      id: this.product.id,
      price: this.product.price,
      days: totaldays,
      rentalCost: Number((productprice * totaldays).toFixed(2)),
      productDiscount: this.product.price
    }
    productRentalStructure.addons = []
    for (let i = 0; i < this.addonsbyproduct.length; i++) {
      const element = this.addonsbyproduct[i];
      var id = "addoninput" + i.toString();
      var errorid = "errorinput" + i.toString();
      if (element.addonType == "number") {  //element.addonType == "text" ||
        const input = document.getElementById(id) as HTMLInputElement | null
        const errorInput = document.getElementById(errorid) as HTMLElement | null
        if (input.value == "" && element.isRequired) {
          input.classList.add("is-invalid");
          errorInput.style.display = "block";
          canAdd = false;
        } else {
          input.classList.remove("is-invalid");
          errorInput.style.display = "none";
          if (element.addonType == "number") {
            let object = {
              addonType: element.addonType,
              companyID: element.companyID,
              description: element.description ? element.description : "",
              id: element.id,
              title: element.title,
              optionsSelect: [{
                price: element.optionsSelect[0].price,
                qty: Number(input.value)
              }]
            }
            productRentalStructure.addons.push(object)
          }// else if (element.addonType == "text") {
          // productRentalStructure.addons.push({
          //   addonType: element.addonType,
          //   companyID: element.companyID,
          //   description: element.description || "empty",
          //   id: element.id,
          //   title: element.title,
          //   optionsSelect: [{
          //     price: element.optionsSelect[0].price,
          //     description: input.value
          //   }]
          // })
          //}
          input.style.border = "1px solid green";
          errorInput.style.display = "none";
        }
      } else if (element.addonType == "select") { //|| element.addonType == "boolean"
        const input = document.getElementById(id) as HTMLSelectElement | null
        const errorInput = document.getElementById(errorid) as HTMLElement | null
        if (input.value == "" && element.isRequired) {
          input.classList.add("is-invalid");
          errorInput.style.display = "block";
          canAdd = false;
        } else {
          input.classList.remove("is-invalid");
          errorInput.style.display = "none";
        }
        productRentalStructure.addons.push(element)
      }
    }
    if (canAdd) {
      this.rentalService.companyID = this.rental.companyID
      this.rentalService.getCompanyInfo().then(async data => {
        let totalCostByProduct = 0
        let totalCostWODiscount = 0
        totalCostByProduct += productRentalStructure.cost
        totalCostWODiscount += productRentalStructure.price * totaldays
        productRentalStructure.addons.forEach(element => {
          if (element.addonType == "select") {
            element.optionsSelect.forEach(option => {
              if (option.isSelected) {
                totalCostByProduct += option.price
                totalCostWODiscount += option.price
              }
            })
          } else {
            totalCostByProduct += (element.optionsSelect[0].price * element.optionsSelect[0].qty)
            totalCostWODiscount += (element.optionsSelect[0].price * element.optionsSelect[0].qty)
          }
        })
        this.rental.discount += Number((totalCostWODiscount - totalCostByProduct).toFixed(2));
        this.rental.subtotal = Number((this.rental.subtotal + totalCostWODiscount).toFixed(2));
        this.rental.cost = Number((this.rental.cost + totalCostByProduct))

        this.rental.products.push(productRentalStructure as ProductRental)
        this.rental.productsID.push(this.product.id)
        this.rental.productsNames.push(this.product.productName)
        this.rental.productsNamesMonths.push(this.product.productName + moment(new Date(this.rental.dayStart.seconds * 1000)).format("MMMM"))
        this.cancelAddProduct();
        this.displayHide();
        //this.rentalService.updateRental(this.rental, this.rental.id)
        localStorage.setItem("isEditing", "true");
        var edit = localStorage.getItem("isEditing");
        if (edit == "true") {
          this.isEditing = true
        }
        Swal.fire(
          'Success',
          'This product has been added to the rental',
          'success'
        )
      })


    }
  }
  cancelChanges() {
    Swal.fire({
      title: 'Are you sure you want to cancel the existing changes?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("isEditing", "false");
        var edit = localStorage.getItem("isEditing");
        if (edit == "false") {
          this.isEditing = false
        }
        this.rental = this.lastrental;
        Swal.fire(
          'Cancelled!',
          'Your changes has been cancelled.',
          'success'
        )
      }
    })
  }
  async saveChanges() {
    const rentalget = await this.rentalService.getRentalByIDPromise(this.rental.id);
    this.rental.amountPaid = rentalget.amountPaid;
    this.rental.amountPending = rentalget.amountPending;
    if (this.lastcost != this.rental.cost) {
      let difference = Number(this.rental.cost - this.lastcost)
      let subdifference = Number(this.rental.subtotal - this.lastsubtotal);
      let negative = false
      difference > 0 ? negative = false : negative = true
      difference = Math.abs(difference);
      let taxfees = this.companyTax + this.fleetTax;
      let totalNewCharge = difference + (difference * (taxfees / 100));
      let text = ""
      negative ? text = "The total has decreased by $" + totalNewCharge.toFixed(2) : text = "The total has increased by $" + totalNewCharge.toFixed(2)
      Swal.fire({
        title: "Changes on Booking",
        html: "There some changes on this booking. " + text + ". Do you want to proceed?",
        icon: "question",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then(result => {
        if (result.isConfirmed) {
          let logchange = "Pending amount"
          negative ? logchange = logchange + ", was reduced by $" + totalNewCharge.toFixed(2) : "";
          !negative ? logchange = logchange + ", was increased by $" + totalNewCharge.toFixed(2) : "";
          this._logService.addRentalLog(this.rental.id, logchange, "Bookings");
          negative ? this.rental.cost = Number((this.lastcost - totalNewCharge)) : this.rental.cost = Number((this.lastcost + totalNewCharge));
          negative ? this.rental.finalTotal = Number((this.finallascost - totalNewCharge)) : this.rental.finalTotal = Number((this.finallascost + totalNewCharge));
          negative ? this.rental.amountPending -= difference : this.rental.amountPending += difference;
          this.rental.amountPaid == 0 ? this.rental.amountPaid = this.lastcost : 0;
          //negative ? this.rental.subtotal -= subdifference : this.rental.subtotal += subdifference;
          // this.rental.amountPaid = Number(this.bookingscomponent.rentalTotalPaid.toFixed(2))
          //this.rental.reservationPaid = Number((Number(this.bookingscomponent.rentalTotalPaid) * ((100-(this.companyTax+this.fleetTax))/100)).toFixed(2))
          //this.rental.reservationPending = true;
          this.rental.taxes = Number(this.rental.cost - Number((this.rental.subtotal - this.rental.discount).toFixed(2)))
          delete this.rental['amountPendingAbs'];
          this.rentalService.updateRental(this.rental, this.rental.id)
          this.checkProductsDeleted(this.lastrental, this.rental);
          this.checkProductsAdded(this.lastrental, this.rental);
          this.lastcost = this.rental.cost
          this.isEditing = false
          this.rentalTotalsChanged.emit(this.rental)
          localStorage.setItem("isEditing", "false");

          Swal.fire({
            icon: "success",
            title: 'This booking have some changes',
            text: text,
            confirmButtonText: 'Ok',
          })
          this.rental = this.rental;
        }
      })
    } else if (this.lastcost == this.rental.cost) {
      Swal.fire({
        title: 'This booking have some changes',
        text: 'The total has been maintained, it is not necessary to make extra charges',
        showCancelButton: true,
        confirmButtonText: 'Save changes',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */

        if (result.isConfirmed) {
          localStorage.setItem("isEditing", "false");
          this.rentalTotalsChanged.emit(this.rental)
          this.rentalService.updateRental(this.rental, this.rental.id).then(() => {
            this.isEditing = false
            Swal.fire('Booking Save', '', 'success')
            this.checkProductsDeleted(this.lastrental, this.rental);
            this.checkProductsAdded(this.lastrental, this.rental);
            this.lastcost = this.rental.cost
          })
        }
      })
    }
  }
  cancelAddProduct() {
    this.selectedProduct = ""
    for (let i = 0; i < this.addonsbyproduct.length; i++) {
      const element = this.addonsbyproduct[i];
      var id = "addoninput" + i.toString();
      var errorid = "errorinput" + i.toString();
      if (element.addonType == "number") { //element.addonType == "text" ||
        const input = document.getElementById(id) as HTMLInputElement | null
        const errorInput = document.getElementById(errorid) as HTMLElement | null
        input.style.border = "1px solid lightgray";
        errorInput.style.display = "none";
      } else if (element.addonType == "select") { // || element.addonType == "boolean"
        const input = document.getElementById(id) as HTMLSelectElement | null
        const errorInput = document.getElementById(errorid) as HTMLElement | null
        input.style.border = "1px solid lightgray";
        errorInput.style.display = "none";

      }// else if (element.addonType == "textArea") {
      // const input = document.getElementById(id) as HTMLTextAreaElement | null
      // const errorInput = document.getElementById(errorid) as HTMLElement | null
      // input.style.border = "1px solid lightgray";
      // errorInput.style.display = "none";
      //}
    }
    this.addonsbyproduct = []
    this.displayHide()
  }

  enableForm(i: any) {
    var idform = "disabledform" + i.toString();
    const btnf = document.getElementById(idform) as HTMLButtonElement | null;
    btnf.style.display = 'block';
  }
  disabledForm(i: any) {
    var idform = "disabledform" + i.toString();
    const btnf = document.getElementById(idform) as HTMLButtonElement | null;
    btnf.style.display = 'none';
  }

  getProductInfo(productId: any, id: any, position: any) {
    this.productsModel = [];
    this.rentalService.getProductById(productId).then(data => {
      this.productsModel = [];
      this.productsModel = data
      this.productsModel.isCheckedOut = true;
      this._orderService.updateDamaged(this.productsModel.id, this.productsModel);
      this._logService.addProductLog(this.productsModel.id, "Product Checked Out, RentalNumber: " + this.checkedoutdata.rentalNumber, "Bookings");
      this._logService.addRentalLog(this.checkedoutdata.id, "Product: " + this.productsModel.productName + " is checked Out", "Bookings");
    })
  }
  getProductInfoCI(productId: any, id: any, position: any) {
    this.productsModel = [];

  }
  async removeProduct(rental, i) {
    this.rentalService.getRentalByIDPromise(this.rental.id).then(rental => {
      this.lastrental = rental;
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.rentalService.companyID = this.rental.companyID
        this.rentalService.getCompanyInfo().then(async data => {
          let companyTax = data.companyTax
          let fleetTax = data.fleetTax
          let totalCostByProduct = rental.products[i].cost
          let totalCostwoDiscount = rental.products[i].price
          rental.products[i].addons.forEach(element => {
            if (element.addonType == "select") {
              element.optionsSelect.forEach(option => {
                if (option.isSelected) {
                  totalCostByProduct += option.price
                  totalCostwoDiscount += option.price
                }
              })
            } else {
              totalCostByProduct += (element.optionsSelect[0].price * element.optionsSelect[0].qty)
              totalCostwoDiscount += (element.optionsSelect[0].price * element.optionsSelect[0].qty)
            }
          })
          let discount = totalCostByProduct - totalCostwoDiscount;
          let totalFees = Number((totalCostByProduct + (totalCostByProduct * (companyTax / 100))).toFixed(2));
          let fleetTaxNumber = Number((totalCostByProduct * (fleetTax / 100)).toFixed(2));
          let totalfeesfleet = totalFees + fleetTaxNumber;
          rental.cost = Number(rental.cost - totalCostByProduct);
          rental.subtotal -= totalCostwoDiscount;
          rental.discount += discount;
          rental.products.splice(i, 1);
          rental.productsID.splice(i, 1);
          rental.productsNames.splice(i, 1);
          rental.productsNamesMonths.splice(i, 1);
          //this.rentalService.updateRental(rental, rental.id)
          localStorage.setItem("isEditing", "true");
          var edit = localStorage.getItem("isEditing");
          if (edit == "true") {
            this.isEditing = true
          }
          Swal.fire(
            'Deleted!',
            'The product has been deleted.',
            'success'
          )
        })

      }
    })
  }

  displayAccordion(product, i, hide?: string) {

    this.rentalService.getProdructsByProductName(product.productName, "booking-product-table on displayAccordion", product.productSize).then(products => {
    })
    var iddiv = "accordion" + i.toString() + product.productNumber.toString();
    const div = document.getElementById(iddiv) as HTMLDivElement | null
    var idbtnhide = "btnaccordionnoedit" + i.toString() + product.productNumber.toString();
    var idbtnshow = "btnaccordionedit" + i.toString() + product.productNumber.toString();
    const btnhide = document.getElementById(idbtnhide) as HTMLDivElement | null
    const btnshow = document.getElementById(idbtnshow) as HTMLDivElement | null
    if (hide == "show") {
      btnhide.style.display = "block"
      btnshow.style.display = "none"
      div.style.display = 'block'
    } else if (hide == "hide") {
      btnhide.style.display = "none"
      btnshow.style.display = "block"
      div.style.display = 'none'
    }
  }

  displayAdd() {
    this.rentalService.getRentalByIDPromise(this.rental.id).then(rental => {
      this.lastrental = rental;
    })
    const linkhide = document.getElementById("linkhideproduct") as HTMLLinkElement | null
    const linkshow = document.getElementById("linkaddproduct") as HTMLLinkElement | null
    linkhide.style.display = "block"
    linkshow.style.display = "none"
  }

  displayHide() {
    const linkhide = document.getElementById("linkhideproduct") as HTMLLinkElement | null
    const linkshow = document.getElementById("linkaddproduct") as HTMLLinkElement | null
    linkhide.style.display = "none"
    linkshow.style.display = "block"
  }

  getProducts() {
    if (this.rental !== undefined) {
      this.rental.productsNames.forEach(productName => {
        this.rentalService.getProdructsByProductName(productName, "booking-products-table").then(products => {
          this.productsByName[productName] = products
        })
      });
    }
  }

  setUpUI() {
    this.getProductsSelect()
    this.lastcost = this.rental.cost
    this.lastsubtotal = this.rental.subtotal
    this.rental.finalTotal ? this.finallascost = this.rental.finalTotal : this.finallascost = this.rental.cost
    this.rentalService.companyID = this.rental.companyID
    this.rentalService.getCompanyInfo().then(async data => {
      this.fleetTax = data.fleetTax;
      this.companyTax = data.companyTax;
      this.dataPI = {
        stripeID: data.stripeID,
        paymentID: this.rental.paymentID,
        isTesting: this.rentalService.isTesting
      }
    })
  }

  // repairClick(product){
  //   for (let p of this.rental.products){
  //     // if (p["isDamaged"])
  //   }
  // }

  openImgNewTab(img) {
    window.open(img, "_blank")
  }

  getFileURL(file: any, type: string, product: any) {

    for (let i = 0; i < this.rental.products.length; i++) {
      if (this.rental.products[i].productID == product.productID) {
        if (type == "checkOut") {
          if (this.rental.products[i]["checkOutImgs"]) {
            this.rental.products[i]["checkOutImgs"].push(file["url"])
          } else {
            this.rental.products[i]["checkOutImgs"] = [file["url"]]
          }
        } else if (type == "checkIn") {
          if (this.rental.products[i]["checkInImgs"]) {
            this.rental.products[i]["checkInImgs"].push(file["url"])
          } else {
            this.rental.products[i]["checkInImgs"] = [file["url"]]
          }
        }


        this._afs.collection("rentals").doc(this.rental.id).update({
          "products": this.rental.products
        })
      }
    }

    // for (let rentalProduct of this.rental.products) {
    //   if (rentalProduct.productID == product.productID) {
    //     if (rentalProduct["checkInImgs"]) {
    //       rentalProduct["checkInImgs"].push(file)
    //     } else {
    //       rentalProduct["checkInImgs"] = file
    //     }
    //     this._afs.collection("rentals").doc(this.rental.id).update(
    //       rentalProduct
    //     )
    //   }
    // }


    // this._afs.collection("rentals").doc(this.rental.id).collection("files").add({
    //   "created": new Date(),
    //   "name": file.name,
    //   "url": file.url,
    //   "isActive": true,
    //   "type": type

    // })
  }

  public isReadyAlert = false

  markAsReady(i?: any) {
    const btn = document.getElementById('markasreadybtn') as HTMLButtonElement | null;
    let errorMessage = ''

    this.rental.products.forEach(product => {

      errorMessage = "Mark a product as ready is Required ( " + product.productName + " )"

      if (product.productID === undefined) {
        this.isReadyAlert = true
      } else {
        this.isReadyAlert = false
      }
    });


    if (this.isReadyAlert === false) {
      let r = confirm("are you sure you want to mark as ready?")
      if (r) {

        btn.disabled = true;
        this.rental.isReady = true
        this.rentalService.updateRental(this.rental, this.rental.id)
        this.lastcost = this.rental.cost
      }
    }

    btn.disabled = false;
  }

  validateNumbers(event) {
    if (event.target.value < 0 || event.target.value == null || event.target.value == undefined || event.target.value == "") {
      event.target.value = 0;
      return
    }
  }

  async createLogs(productrentalaux) {
    this.checkAddons(this.lastrental, productrentalaux);
  }
  ObjCompare(obj1, obj2) {
    const Obj1_keys = Object.keys(obj1);
    const Obj2_keys = Object.keys(obj2);
    if (Obj1_keys.length !== Obj2_keys.length) {
      return false;
    }
    for (let k of Obj1_keys) {
      if (obj1[k] !== obj2[k]) {
        return false;
      }
    }
    return true;
  }
  async checkAddons(lastrental, rental) {
    let addonschange = false;
    await lastrental.products.forEach(oldproduct => {
      const sameproduct = rental.products.find(newproduct => newproduct.id === oldproduct.id);
      if (sameproduct) {
        oldproduct.addons.forEach(oldaddon => {
          const sameaddon = sameproduct.addons.find(newaddon => newaddon.id === oldaddon.id);
          if (sameaddon) {
            if (oldaddon.addonType === "select") {
              const oldselected = oldaddon.optionsSelect.find(oldadd => oldadd.isSelected === true);
              const newselected = sameaddon.optionsSelect.find(newadd => newadd.isSelected === true);
              if (this.ObjCompare(oldselected, newselected)) {
              } else {
                addonschange = true;
              }
            }
            if (oldaddon.addonType === "number") {
              if (this.ObjCompare(oldaddon.optionsSelect[0], sameaddon.optionsSelect[0])) {
              } else {
                addonschange = true;
              }
            }
          }
        })
        if (addonschange == false) {
        } else {
          this.checkFirstLog(lastrental);
          var oldtotal = 0;
          var newtotal = 0;
          oldtotal += oldproduct.cost;
          newtotal += sameproduct.cost;
          oldproduct.addons.forEach(addon => {
            if (addon.addonType == "number") {
              oldtotal += addon.optionsSelect[0].price * addon.optionsSelect[0].qty;
            }
            if (addon.addonType == "select") {
              addon.optionsSelect.forEach(option => {
                if (option.isSelected) {
                  oldtotal += option.price;
                }
              })
            }
          })
          sameproduct.addons.forEach(addon => {
            if (addon.addonType == "number") {
              newtotal += addon.optionsSelect[0].price * addon.optionsSelect[0].qty;
            }
            if (addon.addonType == "select") {
              addon.optionsSelect.forEach(option => {
                if (option.isSelected) {
                  newtotal += option.price;
                }
              })
            }
          })
          let logaddon = {
            type: "pdfRental",
            key: "productChange",
            details: "Product " + oldproduct.productName + " Addons Change",
            change: "addons",
            oldProduct: oldproduct,
            newProduct: sameproduct,
            totaldifference: newtotal - oldtotal,
            timestamp: new Date(),
            firstName: this._currentUser.currentUser ? this._currentUser.currentUser.firstName : "public",
            lastName: this._currentUser.currentUser ? this._currentUser.currentUser.lastName : "public",
            userID: this._currentUser.currentUser ? this._currentUser.currentUser.id : "public"
          }
          this._logService.addRentalPDFLog(this.rental.id, logaddon);
        }

      }
    })

  }
  async checkProductsDeleted(lastrental, rental) {
    await lastrental.products.forEach(oldproduct => {
      const sameproduct = rental.products.find(newproduct => newproduct.id === oldproduct.id);
      if (sameproduct) {
      } else {
        this.checkFirstLog(lastrental);
        let logaddon = {
          type: "pdfRental",
          key: "productDeleted",
          details: "Product " + oldproduct.productName + " Deleted",
          oldProduct: oldproduct,
          newProduct: "",
          timestamp: new Date(),
          firstName: this._currentUser.currentUser ? this._currentUser.currentUser.firstName : "public",
          lastName: this._currentUser.currentUser ? this._currentUser.currentUser.lastName : "public",
          userID: this._currentUser.currentUser ? this._currentUser.currentUser.id : "public"
        }
        this._logService.addRentalPDFLog(this.rental.id, logaddon);
      }
    })
  }
  async checkProductsAdded(lastrental, rental) {
    await rental.products.forEach(newproduct => {
      const sameproduct = lastrental.products.find(oldproduct => oldproduct.id === newproduct.id);
      if (sameproduct) {
      } else {
        this.checkFirstLog(lastrental);
        let logaddon = {
          type: "pdfRental",
          key: "productAdded",
          details: "Product " + newproduct.productName + " Added",
          oldProduct: "",
          newProduct: newproduct,
          timestamp: new Date(),
          firstName: this._currentUser.currentUser ? this._currentUser.currentUser.firstName : "public",
          lastName: this._currentUser.currentUser ? this._currentUser.currentUser.lastName : "public",
          userID: this._currentUser.currentUser ? this._currentUser.currentUser.id : "public"
        }
        this._logService.addRentalPDFLog(this.rental.id, logaddon);
      }
    })
  }
  async checkFirstLog(lastrental) {
    const firstlog = await this._logService.searchFirstLogCollection(lastrental.id, true);
    if (firstlog.length == 0) {
      let logaddon = {
        type: "pdfRental",
        details: "Original Rental Saved",
        change: "first",
        rental: lastrental,
        timestamp: new Date(),
        firstName: this._currentUser.currentUser ? this._currentUser.currentUser.firstName : "public",
        lastName: this._currentUser.currentUser ? this._currentUser.currentUser.lastName : "public",
        userID: this._currentUser.currentUser ? this._currentUser.currentUser.id : "public"
      }
      this._logService.addRentalPDFLog(this.rental.id, logaddon);
    }
  }

  async editproductPrice(rental, i) {
    if(this.rentalModified){
      Swal.fire({
        title: 'Cannot Edit Item price',
        html: 'Rental is already being edited. Please save or cancel your changes before editing item price.',
        icon: 'error',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    }
    else{
      const rental2 = await this.rentalService.getRentalByIDPromise(rental.id);
      const rentalresp = Object.assign({}, rental2);
      var discountresp = rental2.discount.toString();
      var subtotalresp = rental2.subtotal.toString();
      var amountPendingresp = rental2.amountPending.toString();
      this.hideEditProductPrice = false;
      this.productcost = rental2.products[i].cost / rental2.products[i].days;
      this.days = rental2.products[i].days;
      this.totalproduct = rental2.products[i].cost;
      Swal.fire({
        title: 'Edit Product Price',
        icon: "info",
        html: this.priceproduct.nativeElement,
        confirmButtonText: "Save Changes",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        preConfirm: () => {
          this.invalidform = this.totalproduct <= 0;
          return !this.invalidform;
        }
      }).then(result => {
        if (result.isConfirmed) {
          rental2.discount = Number(discountresp);
          rental2.subtotal = Number(subtotalresp);
          rental2.amountPending = Number(amountPendingresp);
          var oldproduct = rental2.products[i]
          var newproduct = JSON.parse(JSON.stringify(rental2.products[i]))
          newproduct.cost = this.totalproduct;
          newproduct.rentalCost = this.totalproduct;
          newproduct.subtotal = this.totalproduct;
          //Calculating the old cost of the product
          var olddiscount = (oldproduct.price * oldproduct.days) - oldproduct.cost;
          var newdiscount = (oldproduct.price * oldproduct.days) - newproduct.cost;
          var difference = newproduct.cost - oldproduct.cost;
          if (olddiscount > 0) {
            rental2.discount -= Math.abs(olddiscount);
          } else if (olddiscount < 0) {
            rental2.subtotal -= Math.abs(olddiscount);
          }
          if (newdiscount > 0) {
            rental2.discount += Math.abs(newdiscount);
          } else if (newdiscount < 0) {
            rental2.subtotal += Math.abs(newdiscount);
          }
          rental2.amountPending += difference
          //difference > 0 ? rental2.amountPending += difference : rental2.amountPending -= difference;
          var differencetax = difference + (difference * (this.companyTax / 100)) + (difference * (this.fleetTax / 100))
          var html = ""
          var negative: boolean = false
          if (difference == 0) {
            html = "The price has not changed"
          } else if (difference > 0) {
            html = "There is a difference of <b>$" + difference.toFixed(2) + "</b> and <b>$" + differencetax.toFixed(2) + "</b> will be added to the rental price."
          } else {
            negative = true;
            html = "There is a difference of <b>$" + Math.abs(difference).toFixed(2) + "</b> and <b>$" + Math.abs(differencetax).toFixed(2) + "</b> will be subtracted from the rental price."
          }
          Swal.fire({
            title: "Are you sure you want to change the product price?",
            html: html,
            icon: "question",
            showCancelButton: true
          }).then(async result => {
            if (result.isConfirmed) {
              this.checkFirstLog(rentalresp);

              // Added by Morgan - adding new price to cart obj item, then converting rental to update all fields
              rental.cartObj['items'][i]['adjustedPrice'] = this.totalproduct;
              let newRental = await this.convertCartObjToRental(rental.cartObj, true);

              try {
                this.rentalService.updateRental(newRental, newRental.id);
                this._logService.addRentalLog(newRental.id, 'Product "' + oldproduct.productName + '" #'+ oldproduct.productNumber+' price change from $' + (oldproduct.cost / oldproduct.days) + ' to $' + (newproduct.cost / newproduct.days), "Bookings");
              }
              catch (err) {
                console.error(`failure editProductPrice(${newRental.id})`, err)
                Swal.fire({
                  title: 'Processing Error',
                  html: `This operation failed to complete. Please try again, or if the issue persists, reach out to <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a> for further assistance`,
                  icon: 'error',
                  allowEnterKey: true,
                  allowEscapeKey: true,
                  allowOutsideClick: true,
                  confirmButtonText: "Ok",
                  showConfirmButton: true,
                })
              }

              this.rentalTotalsChanged.emit(this.rental)
              this.cartAndProducts = this.getCartAndProducts();

              Swal.fire({
                title: "Changes have been saved",
                icon: "success"
              })
            } else {
              rental2.discount = Number(discountresp);
              rental2.subtotal = Number(subtotalresp);
              rental2.amountPending = Number(amountPendingresp);
              Swal.fire({
                title: "Changes have been discarded",
                icon: "info"
              })
            }
            this.hideEditProductPrice = true
          })
        } else {
          rental2.discount = Number(discountresp);
          rental2.subtotal = Number(subtotalresp);
          rental2.amountPending = Number(amountPendingresp);
        }
      })
    }
  }
  changeprice() {
    this.totalproduct = this.productcost * this.days;
    this.invalidform = this.totalproduct <= 0;
  }



  onCheckBoxChange(e, controlName){
    const control = this.maintenanceForm.get(controlName);

    if(e.target.checked){
      // look for the control in the form
      // if it exists, set it to true
      if(control){
        control.setValue(true);
      }

    } else {
      // look for the control in the form
      // if it exists, set it to false
      if(control){
        control.setValue(false);
      }

    }
  }
  resetForm(){
    this.maintenanceForm.reset();
    this.maintenanceSubmitted = false;
  }

  // --------------------------------  Rebuild mini cart ----------------------- //
  closeEditExistingProduct(){
    this.isEditingCartWidgets = false;
    this.editExistingRental = false;
    this.groupInventoryPage = null;
    this.rentalEditingIndex = null;
  }

  // Mini cart

  // ------------ Adding new product to rental  ------------ //
  async addNewRentalProductDropdown(){
    if (this.editBookingOpen) {
      this.cantEditWarning();
    }
    else {
      this.resetAllValues();
      this.groupInventoryPage = null;
      this.addingNewProduct = true;
      await this.createAvailProductGroupsList();
    }
  }

  // Updates rental with a new product
  async addNewProductToRental(newProduct: CartQuantities) {
    let saveChanges = await this.verifyRequiredWidgets('add product');

    if (!saveChanges) {
      return;
    }

    await this.calculateTotalAddonPrice(newProduct['productGroupID'], false);
    let currentIDs = JSON.parse(JSON.stringify(this.rental['allProductsID']));

    this.groupInventoryPage.widgetList = await this.widgetService.assignProductWidgetIDsParsing(this.groupInventoryPage['widgetList'], this.availability, currentIDs).widgetList;

    const newCartItem: CartItem = {
      addonPrice: this.groupInventoryPage?.totalAddonPrice,
      cartItemID: uuid(),
      parentId: newProduct.productGroupID,
      priceByDay: this.productGroupsMap[newProduct.productGroupID].priceByDay,
      priceByHour: this.productGroupsMap[newProduct.productGroupID].priceByHour,
      productId: newProduct['productID'] || "",
      productName: newProduct.pgName,
      productSize: newProduct.sizeName,
      productSizeID: newProduct.sizeID,
      widgetList: this.groupInventoryPage.widgetList,
      dayStart: this.luxDayStart.toJSDate(),
      dayEnd: this.luxDayEnd.toJSDate(),
      dayStartString: this.luxDayStart.toLocaleString(DateTime.DATETIME_FULL),
      dayEndString: this.luxDayEnd.toLocaleString(DateTime.DATETIME_FULL),
      dayStartISO: this.luxDayStart.toISO(),
      dayEndISO: this.luxDayEnd.toISO(),
      customerLocalTimezone: this.rental.cartObj.items.length > 0
          ? this.rental.cartObj.items[0].customerLocalTimezone
          : this.rental.timeZone,
      dateAdded: new Date(),
      daySpan: this.rentalDayspan,
      downtime: this.productGroupsMap[newProduct.productGroupID].downtime,
      is24Hrs: this.productGroupsMap[newProduct.productGroupID].is24hrsPrice,
      locationID: this.rental.rentalLocationID,
      locationTimeZone: this.rental.timeZone,
      selectedHours: this.rentalSelectedHours,
      companyId: this.rental.companyID,
      timeslotType: this.rental?.timeslotType || null,
      isCheckedIn: false,
      isCheckedOut: false,
      isPrepared: false
  };

    // let sudoCartObj = JSON.parse(JSON.stringify(this.rental.cartObj))
    this.rental.cartObj.items.push(newCartItem);

    this.rental.cartObj.cartWidgetList = await this.getUpdatedCartWidgetList(this.rental?.cartObj?.items, this.rental?.cartObj?.cartWidgetList || []);

    await this.convertCartObjToRental(this.rental.cartObj);
    this.rentalModified = true;
    this.rentalModifiedUpdate.emit(true);

    // Reset all variables
    this.resetAllValues();

  }

  // On drop down change update the inventory page being displayed and the price
  protected async onSelectedNewProduct(): Promise<void> {
    if (this.selectedNewProduct) {
      this.newProductLoading = true;

      // this.cartItem = JSON.parse(JSON.stringify(this.rental.cartObj.items[0]));
      let invPageID;
      invPageID = this.productGroupsMap[this.selectedNewProduct.productGroupID].inventoryPageId;

      this.groupInventoryPage = {};

      this.groupInventoryPage = {
        displayID : "1",
        widgetList: JSON.parse(JSON.stringify(this.inventoryPageMap[invPageID]['widgetList']))
      }
      // Removes cart widgets
      this.groupInventoryPage['widgetList'] = await this.widgetService.filterWidgetCartList(this.groupInventoryPage['widgetList'], false);
      // Filter and update the widget list
      this.groupInventoryPage = await this.widgetService.getSavedWidgetsInfo(this.groupInventoryPage, this.widgetArray);
      this.groupInventoryPage = await this.widgetService.getProductWidgetData(this.groupInventoryPage, this.productGroupsMap);
      this.groupInventoryPage = await this.widgetService.getProductOptionSizes(this.groupInventoryPage, this.productSizesMap, this.productSizeTypesMap, this.companyID);

      await this.groupInventoryPage['widgetList'].forEach(async widget => {
        if (widget['widgetType'] == WidgetType.product) {
          widget['element']['price'] = await this.widgetService.calculateProductWidgetPrice(widget['element'], this.rentalSelectedHours, this.rental.rentalType, this.productGroupsMap[this.selectedNewProduct.productGroupID]?.is24hrsPrice);
        }
      })

      // Assign a product ID to the new product
      this.selectedNewProduct['productID'] = await this.getNewProductIDFromTimeSlot(this.selectedNewProduct);


      // Find product widget availabilities and set maxes
      this.groupInventoryPage['widgetList'] = await this.inventoryPageService.setProductWidgetMaxesWithProductWidgetQuantitiesParsing(this.groupInventoryPage['widgetList'], this.availability);

      let newPrice = this.pricingService.calculateIndividualRentalPrice(this.rentalDayspan, this.rentalSelectedHours, this.productGroupsMap[this.selectedNewProduct.productGroupID]);
      this.productPrice = newPrice.rentalPrice;

      this.totalProductPrice = 0;
      await this.calculateTotalAddonPrice(this.selectedNewProduct.productGroupID, false);
      this.newProductLoading = false;
    }
  }

  widgetInputChange() {
    if (this.isEditingCartWidgets) {
      this.calculateTotalAddonPrice('', true);

    }
    else if (this.editExistingRental) {
      this.calculateTotalAddonPrice(this.productsMap[this.groupInventoryPage.productID].productGroupID, false);
    }
    else {
      this.calculateTotalAddonPrice(this.selectedNewProduct.productGroupID, false);
    }
  }

  protected hideNewRentalProductDisplay(): void {
    this.groupInventoryPage = null;
    this.cartItem = null;
    this.productPrice = null;
    this.totalProductPrice = null;
    this.selectedNewProduct = null;
    this.editExistingRental = false;
    this.addingNewProduct = false;
  }

  // -------- Editing existing rental product ------------- //
  protected async editExistingProduct(rental: Rental, ind: number): Promise<void> {
    if (this.editBookingOpen) {
      this.cantEditWarning();
    }
    else {
      // Reset variables just in case
      this.isEditingCartWidgets = false;
      this.editExistingRental = false;
      this.groupInventoryPage = null;
      this.rentalEditingIndex = null;

      this.isLoading = true;
      let productWidget;

      // Dont want the cart obj/ rental to be updated until changes are saved
      this.productPrice = JSON.parse(JSON.stringify(rental.products[ind].cost));
      this.cartItem = JSON.parse(JSON.stringify(rental.cartObj.items[ind]));

      // Make a deep clone of the widget list so the data doesnt get updated on the
      // real rental unless the user saves
      let widgets = JSON.parse(JSON.stringify(rental.cartObj.items[ind].widgetList))
      this.groupInventoryPage = {
        displayID : "1",
        widgetList: widgets,
        productID: rental.cartObj.items[ind].productId
      }
      this.calculateTotalAddonPrice(rental.cartObj.items[ind].parentId, false);
      this.groupInventoryPage['originalAddonPrice'] = this.pricingService.calculateAddonPrice(this.groupInventoryPage['widgetList'], this.rentalDayspan, this.rentalSelectedHours, this.productGroupsMap[rental.cartObj['items'][ind]['parentId']], false);

      // If the widget doesnt have the css saved then get the css
      this.groupInventoryPage.widgetList.forEach(widget => {
        if(widget.widgetType == WidgetType.product){
          productWidget = true;
        }
        if(!widget['componentSettings']){
          widget['componentSettings'] = this.inventoryPageService.getWidgetCssSettings(widget)
        }
      })
      // If there is a product widget then run the availability to get the maxes for each product widget
      if (productWidget) {
        this.editExistingRental = true;
        // Remove the product widget ID's to include in the availability from the currentALLProductsIDs list(THEY ARE AVAILABLE)
        this.currentAllProductIDs = await this.removeProductIDsAttachedToCurrentEditItem(JSON.parse(JSON.stringify(rental.allProductsID)), JSON.parse(JSON.stringify(rental.cartObj.items[ind].widgetList)));
        await this.getTimeSlotData();
        this.rentalEditingIndex = ind;
        this.addingNewProduct = false;
      }
      else {
        this.rentalEditingIndex = ind;
        this.editExistingRental = true;
        this.addingNewProduct = false;
        this.isLoading = false;
      }

    }
  }


  protected async editCartWidgets(rental: Rental): Promise<void> {
    if (this.editBookingOpen) {
      this.cantEditWarning();
    }
    else {
      // Reset just in case
      this.isEditingCartWidgets = false;
      this.editExistingRental = false;
      this.groupInventoryPage = null;
      this.rentalEditingIndex = null;
      this.isLoading = true;
      let productWidget;


      // Make a deep clone of the widget list so the data doesnt get updated on the
      // real rental unless the user saves
      let widgets = JSON.parse(JSON.stringify(rental.cartObj.cartWidgetList))
      this.groupInventoryPage = {
        displayID : "1",
        widgetList: widgets
      }

      this.calculateTotalAddonPrice('', true);
      this.groupInventoryPage['originalAddonPrice'] = this.pricingService.calculateAddonPrice(this.groupInventoryPage['widgetList'], this.rentalDayspan, this.rentalSelectedHours, {}, true, this.rentalPriceWithNoCartWidgets);

      // If the widget doesnt have the css saved then get the css
      this.groupInventoryPage.widgetList.forEach(widget => {
        if (widget.widgetType == WidgetType.product) {
          productWidget = true;
        }
        if (!widget['componentSettings']) {
          widget['componentSettings'] = this.inventoryPageService.getWidgetCssSettings(widget)
        }
      })

      this.isEditingCartWidgets = true;
      // If there is a product widget then run the availability to get the maxes for each product widget
      if (productWidget) {
        // Remove the product widget ID's to include in the availability from the currentALLProductsIDs list(THEY ARE AVAILABLE)
        this.currentAllProductIDs = await this.removeProductIDsAttachedToCurrentEditItem(JSON.parse(JSON.stringify(rental.allProductsID)), JSON.parse(JSON.stringify(rental.cartObj.cartWidgetList)));
        await this.getTimeSlotData();
        this.addingNewProduct = false;
      }
      else {
        this.addingNewProduct = false;
        this.isLoading = false;
      }
    }
  }


  // remove the product widget Ids from the all products IDs list.
  // The all products IDs list tells you what IDs are not available but the current product widget IDs all become temporarily available while product is being edited
  protected removeProductIDsAttachedToCurrentEditItem(allProductsIDs: string[], widgetList: WidgetInterface[]): string[]{
    let newAllProductsIDs = [];
    let allProdsCheckout = [];

    // Get all the product widget ids in use
    widgetList.forEach(widget => {
      if(widget.widgetType == WidgetType.product && 'options' in widget.element){
        widget.element.options.forEach(option => {
          if(option?.productsCheckedOut){
            option.productsCheckedOut.forEach(prodID => {
              allProdsCheckout.push(prodID)
            })
          }
        })
      }
    })

    // create  new list of all the ids that are not a product widget id in use
    allProductsIDs.forEach(productID => {
      if(!allProdsCheckout.includes(productID)){
        newAllProductsIDs.push(productID)
      }
    })

    return newAllProductsIDs
  }


  // Adding a created widget to the product rental
  protected async addNewWidgetToProduct(newWidget: WidgetInterface): Promise<void> {

    if (this.isEditingCartWidgets) {
      newWidget.isCartWidget = true;
    }
    else {
      newWidget.isCartWidget = false;
    }

    if(newWidget.widgetType == WidgetType.checkbox && 'inputValue' in newWidget.element){
      newWidget.element.inputValue = true;
    }
    else if(newWidget.widgetType == WidgetType.quantity && 'inputValue' in newWidget.element){
      newWidget.element.inputValue = 1;
    }

    newWidget['id'] = uuid();
    newWidget['sortOrder'] = this.groupInventoryPage.widgetList.length + 1;

    this.groupInventoryPage.widgetList.push(JSON.parse(JSON.stringify(newWidget)));

    this.selectedNewWidget = null;
    this.widgetStructureListCopy = null;
    await this.inventoryPageService.createAddonsDisplayArrayByProduct(this.rental);

    if (!this.isEditingCartWidgets) {
      this.calculateTotalAddonPrice(this.cartItem['parentId'], false);
    }
    else {
      this.calculateTotalAddonPrice('', true);
    }

    $('#addNewWidgetModal').modal('hide');

  }


  async verifyRequiredWidgets(html: string) {
    let saveChanges: boolean;
    let requiredWidgetPass = this.widgetService.requiredWidgetsCheck(this.groupInventoryPage.widgetList);

    if (!requiredWidgetPass) {
      const result = await new Promise<any>((resolve) => {
        Swal.fire({
          title: 'Required Widgets Missing',
          icon: 'warning',
          html: 'Would you like to ' + html + ' anyways?',
          showCancelButton: true,
          cancelButtonColor: '#6e7881',
          cancelButtonText: 'Cancel',
          showConfirmButton: true,
          confirmButtonColor: '#7066e0',
          confirmButtonText: 'Save',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          // Use preConfirm to handle the result when the user clicks on the confirm button
          preConfirm: () => {
            resolve(Swal.getConfirmButton());
          }
        });
      });

      if (result.isDismissed) {
        saveChanges = false;
      } else {
        saveChanges = true;
      }
    } else { // Passes widget check
      saveChanges = true;
    }
    return saveChanges;
}

  async saveProductEditChanges(rental: Rental, ind?: number, isCartWidgetListEdit?: boolean) {
    try {
      const saveChanges = await this.verifyRequiredWidgets('save changes');

      if (!saveChanges) {
        return;
      }

      // All the Id's will be reset for the thign being editied anyways, so dont consider any Ids currently in use
      const cartObjCopy = JSON.parse(JSON.stringify(this.rental.cartObj))
      if (this.groupInventoryPage['productID'] ) {
        cartObjCopy.items.forEach((item, ind) => {
          if (item.productId == this.groupInventoryPage['productID']) {
            cartObjCopy['items'][ind]['widgetList'] = [];
          }
        });
      } else {
        cartObjCopy['cartWidgetList'] = [];
      }
      const currentIDs = this.inventoryPageService.getAllCurrentRentalIDs(cartObjCopy, true, true, true);

      this.groupInventoryPage.widgetList = await this.widgetService.assignProductWidgetIDsParsing(this.groupInventoryPage.widgetList, this.availability, currentIDs).widgetList;

      if (isCartWidgetListEdit) {
        rental.cartObj.cartWidgetList = JSON.parse(JSON.stringify(this.groupInventoryPage.widgetList));
      } else {
        // Update the rental cart object with the newly added widgets and new total widget price
        rental.cartObj.items[ind].widgetList = JSON.parse(JSON.stringify(this.groupInventoryPage.widgetList));
        rental.cartObj.items[ind].addonPrice = JSON.parse(JSON.stringify(this.groupInventoryPage.totalAddonPrice));
      }

      await this.convertCartObjToRental(rental.cartObj);
      this.rentalModified = true;
      this.rentalModifiedUpdate.emit(true);

      this.resetAllValues();

    } catch (error) {
      console.error('Error:', error);
    }
  }

  // Opens edit widget modal
  protected editExisitingWidget(ind: number, widgetList): void {
    this.selectedNewWidget = null;
    this.selectedNewWidget = JSON.parse(JSON.stringify(widgetList[ind]));
    $('#editExisintWidgetModal').modal('show');
  }

  // Opens edit widget modal
  protected deleteExisitingWidget(e): void {
    if (this.editBookingOpen) {
      this.cantEditWarning();
    }
    else {
      Swal.fire({
        title: 'Remove Widget',
        html: 'Are you sure you want to remove this widget from this product?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#6e7881',
        cancelButtonText: 'Cancel',
        showConfirmButton: true,
        confirmButtonColor: '#7066e0',
        confirmButtonText: 'Confirm',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (e.item?.isCartWidget) {
            this.groupInventoryPage.widgetList.splice(e.index, 1);
            this.calculateTotalAddonPrice('', true);
          }
          else {
            this.groupInventoryPage.widgetList.splice(e.index, 1)

            if (this.addingNewProduct) {
              await this.calculateTotalAddonPrice(this.selectedNewProduct.productGroupID, false);
            }
            else if (this.editExistingRental) {
              this.calculateTotalAddonPrice(this.rental.cartObj.items[this.rentalEditingIndex].parentId, false);
            }
          }

          this.rentalModified = true;
          this.rentalModifiedUpdate.emit(true);
          this.addingNewProduct = false;
          this.selectedNewProduct = null;
        }
      });
    }
  }
  // Updates the current group inventory page with the new widget information,
  // recalls addon price calculations and closes modal
  protected updateExistingWidget(newWidget: WidgetInterface): void {
    // find widget to update
      this.groupInventoryPage.widgetList.forEach((widget, ind) => {
      if(widget.id == newWidget.id){
        this.groupInventoryPage.widgetList[ind] = newWidget;
        if (!widget?.isCartWidget) {

          this.calculateTotalAddonPrice(this.cartItem.parentId, false);
        }
      }
    })


    this.selectedNewWidget = null;
    $('#editExisintWidgetModal').modal('hide');
  }

  returnCartObjForAvailability(rental: Rental) {

    if (rental?.cartObj && rental?.cartObj?.items && rental?.cartObj?.items.length > 0) {
      return rental.cartObj;
    }
    else if (rental?.cartObj?.items.length === 0 && this.rental?.dayStart && this.rental?.dayEnd) {
      return {items: [{ dayStart: rental.dayStart, dayEnd: rental.dayEnd, timeslotType: this.rental?.timeslotType || ''}]}
    }
    else {
      return {items:[]}
    }
  }

  applyOverrideConfig() {
    let availabilityOverrideConfig: AvailabilityOverrides | null = null;
    if (this.isAvailabilityOverride) {
      availabilityOverrideConfig = { overrideUnavailableHours: true, overrideUnavailableDays: true, overrideSearchIncludesToday: true, overrideDOTW: true, overridePastDatePrevention: true }
    }
    else {
      availabilityOverrideConfig = null;
    }
    return availabilityOverrideConfig;
  }

  private async runAvailabilityAlgo(rental: Rental, rentalId?: string, allProductsID?: string[]): Promise<void> {

    if (this.timer != null) { // Reset timer to max length if timer is currently still running
      clearInterval(this.timer)
    }

    this.timer = setTimeout(async () => { // To help avoid multiple calls to the availability

      let currentCartObj = this.returnCartObjForAvailability(rental);
      this.availabilityService.getCollectionData(this.companyID);
      let availabilityOverrideConfig: AvailabilityOverrides = this.applyOverrideConfig();

      // Await necessary collection results
      let res = await lastValueFrom(this.availabilityService.getCollections.pipe((take(1))));
      this.productGroupsMap = res.productGroupsMap;
      this.productsMap = JSON.parse(JSON.stringify(res.productsMap));
      this.inventoryPageMap = res.inventoryPageMap;
      this.productSizesMap = res.productSizesMap;
      this.productSizeTypesMap = res.productSizeTypesMap;
      this.widgetArray = res.widgetArray;

      this.availability = await this.availabilityService.runAvailabilityAlgorithm(
        this.luxDayStart, // search start date
        this.luxDayEnd, // search end date
        rental.rentalLocationID, // search / selected location
        this.companyID,
        res.locationsArray,
        res.productsArray,
        res.productGroupsMap,
        res.inventoryPageMap,
        res.widgetMap,
        res.productsMap,
        currentCartObj,
        rentalId, // rental to ignore
        allProductsID, // product Ids to ignore
        null,
        availabilityOverrideConfig)

      console.debug(`BookingProductsTable2Component.runAvailabilityAlgo()`, this.availability)

      if (!this.editExistingRental && !this.savingRentalChanges) {
        this.isDataAvailable = true;
        this.getRentalPriceWithNoCartWidgets();
      }

      if (this.savingRentalChanges) {
        this.comfirmProductAvailabilities();
      }
    }, 500)

    return
  }

  protected async getRentalPriceWithNoCartWidgets(): Promise<void> { // This is necessary when trying to get accurate tip widget calculations from calclateAddonPrice()
    this.rentalPriceWithNoCartWidgets = 0;

    let {subTotal, promoCodeDiscountTotal, cartRentalTax, cartRentalFees, cartRentalPrice, widgetsSubTotal, widgetsTaxes,
      widgetsFees, cartWidgetPrice } = await this.pricingService.calculateCartTotal(this.rental.cartObj, this.productGroupsMap, null);
    this.rentalPriceWithNoCartWidgets = cartRentalPrice + widgetsSubTotal - cartWidgetPrice;
  }

  private async createAvailProductGroupsList(): Promise<void> {
    this.availIsLoading = true;
    this.availProductsList = await this.availability.availCartQuantitiesList()
    this.availIsLoading = false;

    return
  }


  // Finds the correct time slot and gets the product widget availability
  private async getTimeSlotData(): Promise<void> {
    this.groupInventoryPage['widgetList'] = await this.inventoryPageService.setProductWidgetMaxesWithProductWidgetQuantitiesMiniCartParsing(this.groupInventoryPage['widgetList'], this.availability);
    this.isLoading = false;
    return
  }

  // Make sure to remove product widget Id's currently being used by the rental
  private removeExcessProductWidgetIDs(prodWidgetsIDs: string[]): string[] {
    let availProdWidgetId = [];

    prodWidgetsIDs.forEach(id => {
      // If id isnt currently in use
      if(!this.currentAllProductIDs.includes(id)){
        availProdWidgetId.push(id)
      }
    })
    return availProdWidgetId
  }

  // Finds a product with the correct size and matchign time slot
  // Sets the selectedNewProduct productID and returns the product widgets for that specified time slot
  // Uses initial algo res so that the data is accurate and it wont include the rentals products
  private async getNewProductIDFromTimeSlot(newProduct: CartQuantities): Promise<void> {
    let foundSlot = false;
    let foundID;

    const availableIDs: string[] = this.availability.getGroupSizeIDsAvailableByTime(newProduct.productGroupID, newProduct.sizeID, this.luxDayStart, this.luxDayEnd);

    for (let availID of availableIDs) {
      if (!foundSlot && !this.rental.allProductsID.includes(availID)) {
        foundSlot = true;
        foundID = availID;
      }
    }

    if (!foundID) {
      console.error('No productID found with a matching time slot and size');
    }

    return foundID
  }

  calculateTotalAddonPrice(productGroupID: string, isEditingCartWidgets: boolean) {
    this.groupInventoryPage['totalAddonPrice'] = 0;
    this.groupInventoryPage['totalAddonPrice'] = this.pricingService.calculateAddonPrice(this.groupInventoryPage['widgetList'], this.rentalDayspan, this.rentalSelectedHours, this.productGroupsMap[productGroupID], isEditingCartWidgets, this.rentalPriceWithNoCartWidgets);

    if (!this.groupInventoryPage['totalAddonPrice'] && this.groupInventoryPage['totalAddonPrice'] != 0) {
      this.groupInventoryPage['totalAddonPrice'] = 0;
      throw new Error('Error: Total addon price is not a number');
    }

    this.calculateTotalPrice();
    return
  }

  private calculateTotalPrice(): void {
    this.totalProductPrice = 0;
    this.totalProductPrice = this.groupInventoryPage['totalAddonPrice'] + this.productPrice;
  }

  protected addWidgetModal(e): void{
    this.selectedNewWidget = null;
    // Necesary so modal will reset fields
    this.widgetStructureListCopy = JSON.parse(JSON.stringify(this.widgetService.getWidgetStructureList()))
    $('#addNewWidgetModal').modal('show');
  }

  protected closeAddWidgetModal(): void {
    this.selectedNewWidget = null;
    this.widgetStructureListCopy = null;
    $('#addNewWidgetModal').modal('hide');
  }

  protected closeEditWidgetModal(): void {
    this.selectedNewWidget = null;
    $('#editExisintWidgetModal').modal('hide');
  }


  protected async removeProductFromRental(i): Promise<void> {
    if(this.editBookingOpen) {
      this.cantEditWarning();
    }
    else {
      Swal.fire({
        title: 'Remove Product',
        html: 'Are you sure you want to remove this product from this rental?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#6e7881',
        cancelButtonText: 'Cancel',
        showConfirmButton: true,
        confirmButtonColor: '#7066e0',
        confirmButtonText: 'Confirm',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.rental.cartObj.items.splice(i, 1);
          this.rental.cartObj.cartWidgetList = await this.getUpdatedCartWidgetList(this.rental.cartObj.items, []);
          await this.convertCartObjToRental(this.rental.cartObj);
          this.cartWidgetsAddonsDisplay = this.inventoryPageService.createAddonsDisplayArray(this.rental?.cartWidgetList || []);
          this.rentalModified = true;
          this.rentalModifiedUpdate.emit(true);
          this.addingNewProduct = false;
          this.selectedNewProduct = null;
        }
      });
    }
  }


  // When there have been any changes made to the rental (added widget, addded product, deleted product, deleted widget)
  // This is the last part of the process which update the database, the current rental and notifys the parent to adjust pricing
  async convertCartObjToRental(cartObj: Cart, databaseUpdate?: boolean): Promise<Rental> {
    let newRental: Rental;
    let oldRental: Rental = JSON.parse(JSON.stringify(this.rental))
    if (!this.rental.cartObj?.items || this.rental.cartObj?.items.length < 1) {
      // I pass in the original rental, not deep cloned becuase I dont want to have to update all the date firelds int he rental and cart obj
      // This is okay because when a user presses "cancel" for the mini cart changes, I get the original rental fromt he database, so this rental variable doesnt matter
      try {
        newRental = await this.inventoryPageService.quickBookUpdateCostAndProductsOnRental(this.rental, cartObj, this.productsMap, this.productGroupsMap, this.discountCodeMap, this.rentalDayspan, this.rentalSelectedHours);
      }
      catch (error) {
        console.error('Quick book rental conversion failed:', error);
        this.onErrorCatch();
      }
    }
    else {
      try {
        newRental = await this.inventoryPageService.convertCartObjToRental(cartObj, this.productGroupsMap, this.productsMap, this.discountCodeMap, oldRental, true);
      }
      catch (error) {
        console.error('Rental conversion failed:', error);
        this.onErrorCatch();
      }
    }

    let currentIDs = newRental.allProductsID;
    this.inventoryPageService.resetAlgoCartQuantitesParsing(this.originalAllProductIDs, currentIDs, this.productsMap, this.availability);
    this.originalAllProductIDs = currentIDs // set originalAllProductIDs = to currentIDs so that already used IDs can be filtered against -> so cartQuantities can be calculated

    // update the current rental variable so the changes are reflected on the page after updating the collection object
    this.rental = newRental;

    this.rental = await this.inventoryPageService.createAddonsDisplayArrayByProduct(this.rental);
    this.cartWidgetsAddonsDisplay = this.inventoryPageService.createAddonsDisplayArray(this.rental.cartWidgetList);
    this.cartAndProducts = this.getCartAndProducts();

    if (databaseUpdate) {
      this.saveRentalChangesToDatabase();
    }
    else {
      this.miniCartRentalUpdate.emit({rentalID: this.rental.id, databaseUpdate: false});
    }

    return this.rental
  }


  onErrorCatch() {
      Swal.fire({
      title: 'Processing Error',
      html: `This operation failed to complete. Please try again, or if the issue persists, reach out to <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a> for further assistance`,
      icon: 'error',
      allowEnterKey: true,
      allowEscapeKey: true,
      allowOutsideClick: true,
      confirmButtonText: "Ok",
      showConfirmButton: true,
    })
  }


  // On save changes button click run teh availability to get the current product availability
  // Call the availability check function in the algorithm run method, so it is called after the algorithm response
  protected onSaveAllChangesToRental(): void {
    this.savingRentalChanges = true;
    this.runAvailabilityAlgo(this.rental, this.rental.id, this.originalAllProductIDs);
  }


  private async comfirmProductAvailabilities(): Promise<void> {
    let flagUserItems = "";
    let flagUserItemsArray = [];
    let flagUser = false;
    let allIDs = [];

    // Iterate through items in the rental cart
    await Promise.all(this.rental.cartObj.items.map(async item => {
      allIDs.push(item.id);
        // Check if the item passes the availability check
        const groupID = this.productsMap[item.productId].productGroupID;
        const sizeID = this.productsMap[item.productId].productSizeID;
        const isAvailableResult: { passCheck: boolean; amountUnavail: number } = await this.inventoryPageService.checkAlgoCartQuantitiesParsing(groupID, sizeID, this.availability);

        if (!isAvailableResult.passCheck && !flagUserItemsArray.includes(groupID + '_' + sizeID)) {
          flagUser = true;
          flagUserItemsArray.push(groupID + '_' + sizeID)
          flagUserItems += '<br>' + '&#x2022 ' + this.productsMap[item.productId].productName + ', ' + this.productsMap[item.productId].productSize + ' x' + isAvailableResult.amountUnavail;
        }

      // Iterate through widgets in the item
      await Promise.all(item.widgetList.map(async (widget) => {
        if (widget.widgetType === WidgetType.product) {
          let element = widget.element;
          await Promise.all(element.options.map(async (option) => {
            if (option.inputValue > 0) {
              let isAvailableResult = await this.inventoryPageService.checkAlgoProductQuantitiesParsing(widget['element']['groupId'], option['sizeID'], this.availability).passCheck;
              if (!isAvailableResult) {
                flagUser = true;
                flagUserItems += '<br>' + '&#x2022 ' + widget['element']['name'] + ', ' +  option['size'];
              }
            }

            if (option.inputValue > 0 && option['productsCheckedOut']) {
              allIDs.push(...option.productsCheckedOut);
            } else if (option.inputValue === 0) {
              option['productsCheckedOut'] = [];
            }
          }));
        }
      }));
    }));

    // Iterate through widgets in the rental cart
    if (this.rental.cartObj?.cartWidgetList && this.rental.cartObj?.cartWidgetList.length > 0) {
      await Promise.all(this.rental.cartObj.cartWidgetList.map(async (widget) => {
        if (widget.widgetType === WidgetType.product) {
          let element = widget.element;
          await Promise.all(element.options.map(async (option) => {
            if (option.inputValue > 0) {
              let isAvailableResult = await this.inventoryPageService.checkAlgoProductQuantitiesParsing(widget['element']['groupId'], option['sizeID'], this.availability).passCheck;
              if (!isAvailableResult) {
                flagUser = true;
                flagUserItems += '<br>' + '&#x2022 ' + widget['element']['name'] + ', ' +  option['size'];
              }
            }

            if (option.inputValue > 0 && option['productsCheckedOut']) {
              allIDs.push(...option.productsCheckedOut);
            } else if (option.inputValue === 0) {
              option['productsCheckedOut'] = [];
            }
          }));
        }
      }));
    }

    // If an item cannot be switched then warn the user and don't allow them to save to the database
    if (flagUser) {
      this.savingRentalChanges = false;
      Swal.fire({
        title: 'Items Unavailable',
        html: 'Please remove these items' + flagUserItems,
        icon: 'error',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
    } else {
      this.checkRentalAvailabilityIDSwapProductWidgets();
    }
  }


  private async checkRentalAvailabilityIDSwapProductWidgets(): Promise<void> {
    let newIDList = [];
    let allProductWidgetIDs = [];
    let IDSwapped = false;

    allProductWidgetIDs = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, false, true, true);

    allProductWidgetIDs.forEach(id => {
      if (!this.originalAllProductIDs.includes(id)) {
        newIDList.push(id);
      }
    });

    // Use Promise.all to wait for all asynchronous operations to complete
    await Promise.all(newIDList.map(async productID => {

      const uniqueIDs: string[] = this.availability.getProductWidgetCurrentAvailUniqueIDs(this.productsMap[productID].productGroupID, this.productsMap[productID].productSizeID)
      // If the id is not in the uniqueIds list then that means that the Id has been taken an you need to swap to a new one
      if (!uniqueIDs.includes(productID)) {
        let found = false;
        // Get a new Id from the unique IDs array
        uniqueIDs.forEach(async uniqueID => {
          // Make sure the ID is not already being used
          if (!allProductWidgetIDs.includes(uniqueID) && !found) {
            found = true;
            IDSwapped = true;
            allProductWidgetIDs.push(uniqueID);
            this.rental.cartObj = await this.inventoryPageService.swapRentalProductWidgetID(productID, uniqueID, this.rental.cartObj);
          }
        });
      }
    }));

    // If there was an ID swap then update the rental
    if (IDSwapped) {
      await this.convertCartObjToRental(this.rental.cartObj);
    }

    // Ensure that all prior operations complete before calling this function
    await this.checkRentalAvailabilityIDSwapProduct();
    this.savingRentalChanges = false;
  }

  private async checkRentalAvailabilityIDSwapProduct(): Promise<void> {
    let newIDList = [];
    let IDSwapped = false;
    let allProductIDs = [];
    allProductIDs = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, true, false, false);

    allProductIDs.forEach(id => {
      if (!this.originalAllProductIDs.includes(id)) {
        newIDList.push(id)
      }
    });

    // Use Promise.all to wait for all asynchronous operations to complete
    await Promise.all(newIDList.map(async productID => {
      let availTimeFound = false;
      if (this.availability.checkProductAvailability(this.productsMap[productID].productGroupID, productID)) {
        availTimeFound = true;
      }

      if (!availTimeFound) { // Means that times slot was taken and a new item needs to be found
        let found = false;
        const availableIDs: string[] = this.availability.getGroupSizeIDsAvailableByTime(this.productsMap[productID].productGroupID, this.productsMap[productID].productSizeID, this.luxDayStart, this.luxDayEnd);

        for (let availID of availableIDs) {
          if (!found && !this.rental.allProductsID.includes(availID)) {
            found = true;
            IDSwapped = true;
            this.rental.cartObj = await this.inventoryPageService.swapRentalProductID(productID, availID, this.rental.cartObj);
          }
        }
      }
    }));

    // Run rental cartObj through the cart conversion so the new IDs will be fully integrated
    if (IDSwapped) {
      await this.convertCartObjToRental(this.rental.cartObj);
    }

    // Ensure that all prior operations complete before running this.savingRentalChangesToDatabase()
    await this.saveRentalChangesToDatabase();
    this.savingRentalChanges = false;
  }


  // Update the database and notify the parent component of the rental change
  async saveRentalChangesToDatabase() {
    this.rental = await this.backendBookingsService.convertAllRentalObjectTimesToJSDate(this.rental);
    this.originalAllProductIDs = JSON.parse(JSON.stringify(this.rental.allProductsID));

    try {
      this.rentalService.updateRental(this.rental, this.rental.id);
    }
    catch (err) {
      console.error(`failure saveRentalChangesToDatabase(${this.rental.id})`, err, this.rental)
      Swal.fire({
        title: 'Processing Error',
        html: `This operation failed to complete. Please try again, or if the issue persists, reach out to <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a> for further assistance`,
        icon: 'error',
        allowEnterKey: true,
        allowEscapeKey: true,
        allowOutsideClick: true,
        confirmButtonText: "Ok",
        showConfirmButton: true,
      })
    }

    this.miniCartRentalUpdate.emit({rentalID: this.rental.id, databaseUpdate: true})
    this.resetAllValues();
    this.rentalModified = false;
    this.rentalModifiedUpdate.emit(false);
  }

  private resetAllValues(): void {
    this.groupInventoryPage = null;
    this.cartItem = null;
    this.editExistingRental = false;
    this.isEditingCartWidgets = false;
    this.addonPriceChange = 0;
    this.productPrice = 0;
    this.totalProductPrice = null;
    this.currentAllProductIDs = [];
    this.selectedNewProduct = null;
    this.addingNewProduct = false;
    this.rentalEditingIndex = null;
    this.savingRentalChanges = false;
  }


  private async getUpdatedCartWidgetList(cartList: CartItem[], existingCartWidgetList: WidgetInterface[]): Promise<WidgetInterface[]> {
    // Updates cart widget list
    let newCartWidgetList = [];
    try {
      newCartWidgetList = await this.inventoryPageService.getCartWidgetListData(cartList, existingCartWidgetList, this.inventoryPageMap, this.productGroupsMap, this.companyID);
    }
    catch (err) {
      console.error('Error getting updated cart widget list', err);
      newCartWidgetList = [];
    }

    if(newCartWidgetList.length > 0) {
      let newGroupInvPage = {};
      newGroupInvPage['widgetList'] = newCartWidgetList;
      // Get the saved widget info and product info
      newGroupInvPage = await this.widgetService.getSavedWidgetsInfo(newGroupInvPage, this.widgetArray, true);
      newGroupInvPage = await this.widgetService.getProductWidgetData(newGroupInvPage, this.productGroupsMap);
      newGroupInvPage = await this.widgetService.getProductOptionSizes(newGroupInvPage, this.productSizesMap, this.productSizeTypesMap, this.companyID);

      await newGroupInvPage['widgetList'].forEach(async widget => {
        if (widget['widgetType'] == WidgetType.product) {
          widget['element']['price'] = await this.widgetService.calculateProductWidgetPrice(widget['element'], this.rentalDayspan, this.rentalSelectedHours, this.productGroupsMap[cartList[0]['parentId']]?.is24hrsPrice);
        }
      })
      newCartWidgetList = newGroupInvPage['widgetList'];
    }
    return newCartWidgetList
  }

  protected onCancelAllChangesToRental(): void {
    this.isDataAvailable = false;
    this.resetAllValues();
    this.rentalModified = false;
    this.rentalModifiedUpdate.emit(false);
    this.rentalService.getRentalByIDPromise(this.rental.id).then(async (res) => {
      this.rental = res;
      this.cartAndProducts = this.getCartAndProducts();
      this.cartWidgetsAddonsDisplay = this.inventoryPageService.createAddonsDisplayArray(this.rental.cartWidgetList);
      this.runAvailabilityAlgo(this.rental, this.rental.id, this.originalAllProductIDs); // reset cart quantities
    })
  }

  private cantEditWarning(): void {
    Swal.fire({
      title: 'Cannot Edit Rental',
      html: 'Rental is already being edited above. Please finish editing rental or cancel your changes.',
      icon: 'error',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
  }

  ngOnDestroy(): void { /* Deconstructor */
    if (this.rentalSubscription) {
      this.rentalSubscription.unsubscribe();
    }

    if(this.collectionsSubscription){
      this.collectionsSubscription.unsubscribe();
    }

    this.resetAllValues();
    this.editBookingOpen = false;
    this.rentalModified = false;
    this.subs.unsubscribe(); // unsubscribe from all subscriptions
  }


  trackByTitle(index: number, item: any): number {
    return item.addonID; // Assuming id is a unique identifier for each item
  }

  getCartAndProducts(): { item: CartItem, product: ProductRental }[] {
    if (!this.rental?.products || !this.rental?.cartObj?.items) {
      return [];
    }

    return this.rental.products
      .map((product, index) => {
        const cartItem = this.rental.cartObj.items[index];
        return cartItem ? { item: cartItem, product } : null;
      })
      .filter(Boolean);
  }

} // End of class

function atLeastOneCheckboxSelected(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const cleaningCheck = control.get('cleaningCheck');
    const tuneupCheck = control.get('tuneupCheck');
    const damagedCheck = control.get('damagedCheck');

    if (!cleaningCheck.value && !tuneupCheck.value && !damagedCheck.value) {
      return { atLeastOneCheckboxSelected: true };
    }

    return null;
  };



}


