<div class="container-fluid">
  <!-- Header and Filters -->
  <div class="row mb-2 mt-2">
    <!-- Header -->
    <div class="col-lg-6">
      <div class="row align-items-start">
        <div class="col-md-6 text-left mt-2 mb-2">
          <h1 style="font-size: 32px">Booking Suite</h1>
        </div>
      </div>

      <!-- New Template / Page - Buttons -->
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <button
            type="button"
            class="btn btn-success"
            (click)="openCreateTemplateModal()"
          >
            New Template
          </button>
          <button
            type="button"
            class="btn btn-success ml-2"
            (click)="openPageModal(false, '')"
          >
            New Page
          </button>
        </div>
      </div>
    </div>

    <!-- Search Pipe -->
    <div class="col-lg-3">
      <label class="form-label">Search By Title</label>
      <input
        class="form-control"
        type="text"
        placeholder="Type to search..."
        (keyup)="search(searchBox.value)"
        #searchBox
      />
    </div>

    <!-- Filter Radio Buttons -->
    <div class="col-lg-3">
      <label class="form-label">Filter By Type</label>
      <div style="border: solid #dee2e6; padding: 1em">
        <div class="form-check">
          <input
            [(ngModel)]="filter"
            class="form-check-input"
            type="radio"
            name="filter"
            id="showAll"
            value="all"
            checked
          />
          <label class="form-check-label" for="showAll"> All </label>
        </div>
        <div class="form-check">
          <input
            [(ngModel)]="filter"
            class="form-check-input"
            type="radio"
            name="filter"
            id="showTemplates"
            value="templates"
          />
          <label class="form-check-label" for="showTemplates">
            Templates
          </label>
        </div>
        <div class="form-check">
          <input
            [(ngModel)]="filter"
            class="form-check-input"
            type="radio"
            name="filter"
            id="showPages"
            value="pages"
          />
          <label class="form-check-label" for="showPages"> Pages </label>
        </div>
      </div>
    </div>
  </div>

  <!-- Template / Page Table  -->
  <!-- <div class="table-responsive" style="max-height:600px !important; margin-top: 0.2em;"> -->
  <div
    class="table-responsive"
    style="height: 76vh !important; margin-top: 0.2em"
  >
    <table class="table" id="productListForGroup">
      <thead>
        <tr>
          <!-- <th style="width: 23%;" scope="col">ID</th> -->
          <th style="width: 23%" scope="col">Title</th>
          <th style="width: 10%" scope="col">Type</th>
          <!-- <th style="width: 22%;" scope="col">Created</th> -->
          <th style="width: 22%" scope="col">Num Pages</th>
          <th style="width: 23%" scope="col">Edit / Delete</th>
        </tr>
      </thead>
      <tbody>
        <!-- Templates -->
        <ng-container *ngIf="filter == 'all' || filter == 'templates'">
          <tr
            *ngFor="
              let template of templates | booking : searchValue;
              let i = index
            "
          >
            <ng-container>
              <!-- <td style="width: 23.5%;">{{template.id}}</td> -->
              <td>
               <div *ngIf="!edit[i]" (click)="templateTitleInteraction(i,'open')" [class.edit]="edit[i]">
                 <i id="pencilactive" class="fa fa-solid fa-pencil mx-1" [class.pencilactive]="edit[i]"></i>
                 <span>{{ template.title }}
                   </span>
                </div>

                <input
                  *ngIf="edit[i]"
                  [(ngModel)]="templateTitlesWhileEditing[templates[i].id]"
                />

                <i
                  *ngIf="edit[i]"
                  tooltip="Save"
                  class="fa fa-check-circle mx-1 text-success"
                  (click)="templateTitleInteraction(i, 'save')"
                ></i>

                <i
                  *ngIf="edit[i]"
                  tooltip="Cancel"
                  class="fa fa-times-circle text-danger"
                  (click)="templateTitleInteraction(i, 'cancel')"
                ></i>
              </td>
              <td>
                <ng-container *ngIf="currentUser?.isDeveloper; else nonDeveloper">
                  <a href="https://console.firebase.google.com/project/{{ projectId }}/firestore/databases/-default-/data/~2FbookingTemplates~2F{{ template.id }}" target="_blank" rel="noopener noreferrer" style="text-decoration: none">
                    Template
                  </a>
                </ng-container>
                <ng-template #nonDeveloper>
                  Template
                </ng-template>
              </td>
              <!-- <td>{{template.dateCreated | date:'shortDate'}}</td> -->
              <!-- <td>{{template.dateCreated | date:'shortDate'}}</td> -->
              <td></td>
              <td>
                <div style="display: flex; flex-direction: row">
                  <div
                    [tooltip]="
                      template?.editState?.isEditing
                        ? 'Being edited by ' + template?.editState?.user
                        : ''
                    "
                    [placement]="'left'"
                  >
                  <!-- Edit -->
                    <button
                      type="button"
                      [disabled]="template?.editState?.isEditing"
                      (click)="editTemplate(template)"
                      style="margin-bottom: 1em"
                      class="btn btn-success mr-2"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>

                    <!-- Delete -->
                    <button
                      type="button"
                      [disabled]="template?.editState?.isEditing"
                      (click)="delete(template, 'template')"
                      style="margin-bottom: 1em"
                      class="btn btn-danger mr-2"
                    >
                      <i class="fas fa-trash" aria-hidden="true"></i>
                    </button>

                    <!-- Options -->
                    <!-- data-toggle="modal" data-target="#bookingFlowConfigModal" -->
                    <button
                    (click)="editTemplateSettings(template)"
                    type="button"
                    style="margin-bottom: 1em; background-color: #586f91; color: white;"
                    class="btn mr-2"
                  >
                  <i class="fa fa-cogs" aria-hidden="true"></i>
                  </button>

                  </div>
                  <a
                    target="_blank"
                    [routerLink]="['/book/', template.id]"
                    style="
                      margin-bottom: 1em;
                      background-color: #18abab !important;
                      color: white;
                    "
                    class="btn btn-info mr-2"
                    ><i aria-hidden="true" class="fas fa-external-link-alt"></i
                  ></a>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-container>
        <!-- Pages -->
        <ng-container *ngIf="filter == 'all' || filter == 'pages'">
          <tr *ngFor="let page of pages | booking : searchValue">
            <ng-container>
              <!-- <td style="width: 23.5%;">{{page.id}}</td> -->
              <td>{{ page.title }}</td>
              <td>
                <ng-container *ngIf="currentUser?.isDeveloper; else nonDeveloper2">
                  <a href="https://console.firebase.google.com/project/{{ projectId }}/firestore/databases/-default-/data/~2FbookingPages~2F{{ page.id }}" target="_blank" rel="noopener noreferrer" style="text-decoration: none">
                    Page
                  </a>
                </ng-container>
                <ng-template #nonDeveloper2>
                  Page
                </ng-template>
              </td>
              <!-- <td>{{page.dateCreated | date:'shortDate'}}</td> -->
              <td>{{ page.content.length }}</td>
              <td>
                <button
                  type="button"
                  (click)="openPageModal(true, page)"
                  style="margin-bottom: 1em"
                  class="btn btn-success mr-2"
                >
                  <i class="fa fa-pencil"></i>
                </button>
                <button
                  type="button"
                  (click)="delete(page, 'page')"
                  style="margin-bottom: 1em"
                  class="btn btn-danger mr-2"
                >
                  <!-- <i class="fa fa-trash" aria-hidden="true"></i> -->
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <!-- && this.templatesLoaded && this.pagesLoaded -->
    <div *ngIf="templates.length <= 0 && pages.length <= 0">
      <span>No templates or pages found</span>
    </div>
  </div>
</div>

<!-- Create Template Modal -->
<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="createTemplateModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create Template</h4>
        <button
          type="button"
          class="btn btn-danger"
          (click)="closeTemplateModal()"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <form
          class="needs-validations"
          #groupf="ngForm"
          [formGroup]="templateForm"
          (ngSubmit)="onSubmit()"
        >
          <!-- title -->
          <div class="row">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="form-check-label" for="templateTitle">Title</label>
              <span class="text-danger">*</span>
              <!-- Required Symbl -->
              <input
                class="form-control"
                type="text"
                name="templateTitle"
                id="templateTitle"
                formControlName="title"
              />
            </div>
            <span
              class="text-danger mb-2"
              *ngIf="title.errors?.['required'] && title.touched"
            >
              A template must have a title.
            </span>
          </div>

          <!-- Buttons -->
          <div class="container-xxl">
            <div class="row justify-content-center mt-2">
              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-success"
                  [disabled]="templateForm.invalid"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- add page modal -->
<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="pageModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="!isEditingPage">Create Page</h4>
        <h4 class="modal-title" *ngIf="isEditingPage">Edit Page</h4>
        <button
          type="button"
          class="btn btn-danger"
          (click)="closePageModal()"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <app-page-form
          (getPageFormData)="setPageFormData($event)"
          (closePageModal)="closePageModal()"
        ></app-page-form>
      </div>
    </div>
  </div>
</div>

<!-- Edit Template Settings Modal -->
<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="bookingFlowConfigModal"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Template Configuration</h4>
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <app-bookingFlowConfigModal
          [template]="selectedTemplate"
          (bookingFlowConfigFormSubmission)="closeBookingFlowConfigModal()">
        </app-bookingFlowConfigModal>
      </div>
    </div>
  </div>
</div>

<!-- Delete Confirmation Modal -->
<div
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="deleteConfirmationModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h2 *ngIf="allowDelete">Delete | Are you sure?</h2>
        <h2 *ngIf="!allowDelete">Delete | Warning</h2>
        <button
          type="button"
          class="btn btn-danger"
          aria-label="Close"
          data-bs-dismiss="modal"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <app-delete-confirmation
          [ngStyle]="allowDelete ? { display: 'block' } : { display: 'none' }"
        ></app-delete-confirmation>
        <div
          [ngStyle]="!allowDelete ? { display: 'block' } : { display: 'none' }"
        >
          <div class="alert alert-danger" role="alert">
            <h3>Warning!</h3>
            <span
              >Unable to delete page while the following templates are being
              edited:</span
            >
            <br />
            <ul>
              <li *ngFor="let info of templatesBeingEdited">
                {{ info?.title }} - {{ info?.user }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
