import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import 'firebase/compat/storage';
import { map } from "rxjs/operators";
import { AngularFireStorage } from '@angular/fire/compat/storage';

//Models
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { Image } from 'src/app/models/storage/rental-photos.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { Maintenance } from '../models/storage/maintenance.model';

//Services
import { RentalService } from './rental.service';
import { CurrentUserService } from './current-user.service';
@Injectable({
  providedIn: 'root'
})
export class OrderProcessingService {
  public date = new Date();//Obtain the actual date
  public today: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
  public today2: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 24);
  public tomorrow: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1);
  public tomorrow2: any = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 2);
  constructor(
    private firestore: AngularFirestore,
    private currentUserService: CurrentUserService,
    private storage: AngularFireStorage,
    private _rentalService: RentalService
  ) { }

  getProducts(): Observable<any> {
    return this.firestore.collection('products', (ref) =>
      ref.where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as any;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  getRentals(availablenext): Observable<any> {
    return this.firestore.collection('rentals', (ref) =>
      ref.where('dayStart', "<", availablenext)
        .where('isConfirmed', '==', true)
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as any;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }
  getRentalsRebuild(availablenext): Observable<any> {
    return this.firestore.collection('rentals', (ref) =>
      ref.where('dayStart', "<", availablenext)
        .where('isConfirmed', '==', true)
        .where('isCheckedIn', '==', false)
        .where('isCancelled', '==', false)
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as any;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }
  getRentalsPastDue(datenow) {
    console.log(this.today)
    return this.firestore.collection('rentals', (ref) =>
      ref.where('dayStart', "<", this.today)
        .where('isConfirmed', '==', true)
        .where('isCheckedIn', '==', false)
        .where('isCancelled', '==', false)
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as any;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }


  getRentalsCheckIn(): Observable<any> {
    return this.firestore.collection('rentals', (ref) =>
      ref.where('isCheckedOut', '==', true)
        .where('isCheckedIn', '==', false)
        .where('isCancelled', '==', false)
        .where('isConfirmed', '==', true)
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
        .orderBy('dayEnd', 'asc')
    ).snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as any;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }
  getRentalsTomorrow(): Observable<any> {
    return this.firestore.collection('rentals', ref =>
      ref
        .where('dayStart', ">", this.tomorrow)
        .where('dayStart', "<", this.tomorrow2)
        .orderBy('dayEnd', 'asc')
        .where('companyID', '==', this.currentUserService.currentUser.companyId)
        .where('isConfirmed', '==', true)
    ).snapshotChanges();
  }

  getRental(id: any): Observable<any> {
    return this.firestore.collection('rentals').doc(id).get();
  }

  getStripeTransaction(rentalID): Observable<any> {
    return this.firestore.collection('stripeTransactions', ref =>
      ref.where('rentalID', '==', rentalID)
        .where('type', '==', "charge")
    ).snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        let data = action.payload.doc.data() as any
        data.id = action.payload.doc.id
        return data
      })
    }));
  }
  getProduct(productNum: any) {
    let ref = this.firestore.collection('products').ref
      .where("productNumber", "==", productNum)
      .where('companyID', '==', this.currentUserService.currentUser.companyId)
    return ref.get().then(res => {
      return res.docs.map(r => {
        let data = r.data() as any
        data.id = r.id
        return data
      })
    })
  }

  getCompletedRentals(): Observable<Rental[]> {
    return this.firestore.collection(Collection.Rentals, ref =>
      ref.where('companyID', '==', this.currentUserService.currentUser.companyId)
      .where('isComplete', '==', true)
      .where('isConfirmed', '==', true)
      .orderBy('rentalNumber')
    ).snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        let data = action.payload.doc.data() as Rental
        data.id = action.payload.doc.id
        return data
      })
    }));
  }

  updatePrepared(id: any, rental: any): Promise<any> {
    return this.firestore.collection('rentals').doc(id).update(rental);
  }

  updateDamaged(id: any, product: any): Promise<any> {
    return this.firestore.collection('products').doc(id).update(product);
  }

  updateProduct(id: any, product: any): Promise<any> {
    return this.firestore.collection('products').doc(id).update(product);
  }

  async uploadImg(id: string, imgBase64: any, productname: string, uploadtype: string) {
    try {
      if (uploadtype == "CheckedOut") {
        let response = await this.storage.ref("rentalsProducts/" + id + "/" + "checkedOut/" + productname).putString(imgBase64, "data_url");
        return response.ref.getDownloadURL();
      } else {
        let response2 = await this.storage.ref("rentalsProducts/" + id + "/" + "checkedIn/" + productname).putString(imgBase64, "data_url");
        return response2.ref.getDownloadURL();
      }
    } catch (err) {
      return null;
    }
  }
  async uploadImgProductGroup(imgBase64: any, imgname: string) {
    try {
      let response = await this.storage.ref("productGroup/" + imgname).putString(imgBase64, "data_url");
      return response.ref.getDownloadURL();
    } catch (err) {
      return null;
    }
  }
  async uploadPDF(id: string, document: any, productname: string, rental: any) {
    try {
      let response = await this.storage.ref("bookings/" + id + "/" + "checkedOutWaiver/" + productname).putString(document, "data_url");
      return response.ref.getDownloadURL();
    } catch (err) {
      return null;
    }
  }

  updateWaiver(id: any, rental: any): Promise<any> {
    return this.firestore.collection('rentals').doc(id).update(rental);
  }
  updateRentalPhotos(rentalphoto: any) {
    return this.firestore.collection('rentalPhotos').add(rentalphoto);
  }
  getCOImagesURL(id: any, product: any) {
    let ref = this.firestore.collection('rentalPhotos').ref
      .where("productID", "==", product)
      .where('rentalID', '==', id)
      .where('type', '==', "Check Out")
    return ref.get().then(res => {
      return res.docs.map(r => {
        let data = r.data() as Image
        data.id = r.id
        return data
      })
    })
  }
  getCIImagesURL(id: any, product: any) {
    let ref = this.firestore.collection('rentalPhotos').ref
      .where("productID", "==", product)
      .where('rentalID', '==', id)
      .where('type', '==', "Check In")
    return ref.get().then(res => {
      return res.docs.map(r => {
        let data = r.data() as Image
        data.id = r.id
        return data
      })
    })
  }
  addMaintenance(maintenance: Maintenance) {
    return this.firestore.collection('maintenance').add(maintenance);
  }

  async uploadCompanyImg(id: string, imgBase64: any, name: any) {
    try {
      let response = await this.storage.ref("companies/" + id + "/" + "data/" + name).putString(imgBase64, "data_url");
      return response.ref.getDownloadURL();
    } catch (err) {
      return null;
    }
  }
}
