import { Injectable } from '@angular/core';
import { Observable, concatMapTo, lastValueFrom, map, take } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { v4 as uuid } from 'uuid';
import { WidgetInterface, ProductElementInterface, WidgetType } from 'src/app/models/widget.model';
import { ProductsService } from 'src/app/services/products.service';
import * as _ from 'lodash';
import { DocumentChangeType } from 'firebase/firestore';
import { advenireSavedWidgetId, reservationSavedWidgetId } from '../models/widget.model';
import { AvailabilityInterface } from '../models/availability.model';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {


  constructor(
    private currentUserService: CurrentUserService, 
    private afs: AngularFirestore, 
    private productsService: ProductsService,
  ) {

  }



  //------------- Database Subscriptions -----------------//

  getWidgets(): Observable<any> { //Gets entire list of widgets
    return this.afs.collection('widgets', ref => ref
      .where('companyID', '==', this.currentUserService.currentUser.companyId)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }

  getWidgetsWithParamCompanyID(companyID): Observable<any> { //Gets entire list of widgets
    return this.afs.collection('widgets', ref => ref
      .where('companyID', '==', companyID)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data
        })
      }));
  }

  getWidgetChangesByCompanyID(companyID): Observable<any> {
    return this.afs
      .collection('widgets', (ref) =>
        ref.where('companyID', '==', companyID).where('isActive', '==', true)
      )
      .stateChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data() as WidgetInterface;
            data.id = action.payload.doc.id;
            let documentChangeType: DocumentChangeType = action.payload.type;
            return {doc: data, documentChangeType: documentChangeType};
          });
        })
      );
  }

  getInventoryPages(): Observable<any> { //Gets entire list of invenotry pages
    return this.afs.collection('inventoryPages', ref => ref
      .where('companyID', '==', this.currentUserService.currentUser.companyId)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data;
        })
      }));
  }

  getInventoryPagesWithParamCompanyID(companyID): Observable<any> { //Gets entire list of invenotry pages
    return this.afs.collection('inventoryPages', ref => ref
      .where('companyID', '==', companyID)
      .where('isActive', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(action => {
          let data = action.payload.doc.data()
          data['id'] = action.payload.doc.id
          return data;
        })
      }));
  }

  getInventoryPageChangesByCompanyID(companyID): Observable<any> {
    return this.afs
      .collection('inventoryPages', (ref) =>
        ref.where('companyID', '==', companyID).where('isActive', '==', true)
      )
      .stateChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data() as any;
            data.id = action.payload.doc.id;
            let documentChangeType: DocumentChangeType = action.payload.type;
            return {doc: data, documentChangeType: documentChangeType};
          });
        })
      );
  }

  //------------ Helpful Functions ------------------//


  //----------- Initial widget list set-up ---------//

  // Gets the data for saved widgets to make sure they are up to date when loaded in
  async getSavedWidgetsInfo(groupInventoryPage, widgets, isCartWidget?){
    let originalGroupInventoryPage = JSON.parse(JSON.stringify(groupInventoryPage));
    for(let i = groupInventoryPage.widgetList.length - 1; i >= 0; i--){
      let found = false;
        if(groupInventoryPage.widgetList[i].savedWidgetId){
          for (const item of widgets) {
            if(item.id == groupInventoryPage.widgetList[i].savedWidgetId){
              groupInventoryPage.widgetList[i]['element'] = item.element;
              groupInventoryPage.widgetList[i]['savedWidgetId'] = item.id;
              groupInventoryPage.widgetList[i]['widgetName'] = item.widgetName? item.widgetName : "";
              // this.inventoryPage.widgetList[i]['id'] = this.inventoryPage.widgetList[i].id; 
              found = true; 
            }
          }
          // If a saved widget is not found then remove the widget
          if(!found && (groupInventoryPage.widgetList[i].savedWidgetId != advenireSavedWidgetId && groupInventoryPage.widgetList[i].savedWidgetId != reservationSavedWidgetId)){
            groupInventoryPage.widgetList.splice(i, 1);
            break;
          }

          if(isCartWidget){
            // If the inventory page has already been shown in the cart
            if(originalGroupInventoryPage.widgetList[i]['element']){
              groupInventoryPage.widgetList[i] = await this.putOrignalProductWidgetInputsBack(groupInventoryPage.widgetList[i], originalGroupInventoryPage.widgetList[i])
            }
          }
        }
        // If the item widget doesn not have a unique id then add one
        if(!groupInventoryPage.widgetList[i]['id']){
          groupInventoryPage.widgetList[i]['id'] = uuid();
        }
    }
    return groupInventoryPage
  }


  putOrignalProductWidgetInputsBack(newWidget, originalWidget){
    if (newWidget.widgetType == "product") {
      if (newWidget['element']['isDropdown']) {
        newWidget['element']['options'].forEach((option, ind) => {
          newWidget['element']['options'][ind]['is_selected'] = originalWidget['element']['options'][ind]['is_selected'];
        })
      }
      else {
        newWidget['element']['options'].forEach((option, ind) => {
          newWidget['element']['options'][ind]['inputValue'] = originalWidget['element']['options'][ind]['inputValue'];
        })
      }
    }
    else if(newWidget.widgetType == 'heightInput'){
      newWidget.element.feetInput = originalWidget.element.feetInput;
      newWidget.element.inchInput = originalWidget.element.inchInput;
    }
    else if(newWidget.widgetType == 'radios' || newWidget.widgetType == 'dropdown'){
      newWidget['element']['inputValue'] = originalWidget['element']['inputValue'];
      newWidget['element']['options'].forEach((option, ind) => {
        newWidget['element']['options'][ind]['is_selected'] = originalWidget['element']['options'][ind]['is_selected'];
      })
    }
    else if (newWidget.widgetType == 'quantity' || newWidget.widgetType == 'checkbox' || newWidget.widgetType == 'weightInput') {
      newWidget.element.inputValue = originalWidget.element.inputValue;
    }

    return newWidget
  }


  // Catches any updates to a products data 
  // Loops throught the added widgets list to find product widgets
  getProductWidgetData(groupInventoryPage, productGroupsMap) {

    for(let ind = groupInventoryPage['widgetList'].length - 1; ind >= 0; ind--){

      if(groupInventoryPage['widgetList'][ind]['widgetType'] == 'product') {
        groupInventoryPage['widgetList'][ind]['element'] = this.setProductWidgetData(groupInventoryPage['widgetList'][ind], productGroupsMap);
        
        // If no group was found then delete the widget, it means productGroup was deleted
        if(!groupInventoryPage['widgetList'][ind]['element']['groupId']){
          groupInventoryPage['widgetList'].splice(ind, 1);
        }
        
        // If there are no options set to show or there are no options then remove the widget
        else if(!groupInventoryPage['widgetList'][ind]['element']['options'] || groupInventoryPage['widgetList'][ind]['element']['options'].length < 1){
          groupInventoryPage['widgetList'].splice(ind, 1);
        }

        else if(groupInventoryPage['widgetList'][ind]['element']['options']){
          let optionShow = false;
          groupInventoryPage['widgetList'][ind]['element']['options'].forEach(option => {
            if(option.show){
              optionShow = true;
            }
          })
          if(!optionShow){
            groupInventoryPage['widgetList'].splice(ind, 1);
          }
        }
      }
    }
    return groupInventoryPage
  }

  setProductWidgetData(widget, productGroupsMap) {
    let newWidgetElement = JSON.parse(JSON.stringify(widget['element']));

    if(productGroupsMap[newWidgetElement['groupId']]){
        newWidgetElement.name = productGroupsMap[newWidgetElement['groupId']].groupName;
        newWidgetElement.groupImage = productGroupsMap[newWidgetElement['groupId']].groupImage;
        newWidgetElement.description = productGroupsMap[newWidgetElement['groupId']].groupDescription;
        newWidgetElement.groupId = productGroupsMap[newWidgetElement['groupId']].id;
        newWidgetElement.is24hrsPrice = productGroupsMap[newWidgetElement['groupId']].is24hrsPrice;
    }
    else{
      newWidgetElement.groupId = null;
    }

    return newWidgetElement
  }

  async getProductOptionSizes(groupInventoryPage, allProductSizesMap, sizeTypesMap, companyID, noDatabaseUpdate?){
    let needSave = false;
    let originalWidget;
    let widgetSizesReturn;

    // First loop: Process the widget list and set option sizes
    for (let widget of groupInventoryPage['widgetList']) {
      if (widget['widgetType'] == WidgetType.product) {
          originalWidget = widget;

          // Await the completion of setOptionSizes
          widgetSizesReturn = await this.setOptionSizes(widget, allProductSizesMap, sizeTypesMap, companyID);
          widget = widgetSizesReturn.widget;

          if (widgetSizesReturn.optionChanged) {
              needSave = true;
              // Update the saved widget if it exists
              if (widget?.savedWidgetId) {
                  await this.productsService.updateWidget(widget['savedWidgetId'], widget);
              }
          }
      }
    }

    // Update the inventory page if needed
    if (needSave && groupInventoryPage?.id && !noDatabaseUpdate) {
      await this.productsService.updateInventoryPage(groupInventoryPage.id, groupInventoryPage);
    }

    // Call helperFunction if needed
    groupInventoryPage = this.removeProductWidgetsWithNoSizes(groupInventoryPage);

    return groupInventoryPage;
}


  removeProductWidgetsWithNoSizes(groupInventoryPage) {
    // Second loop: Loop backwards to check and remove products with no options
    for (let i = groupInventoryPage['widgetList'].length - 1; i >= 0; i--) {
      if (groupInventoryPage['widgetList'][i]['widgetType'] == WidgetType.product) {
          if (!groupInventoryPage['widgetList'][i]?.element?.options || groupInventoryPage['widgetList'][i]?.element?.options.length === 0) {
              groupInventoryPage['widgetList'].splice(i, 1);
          }
      }
    }
    return groupInventoryPage
  }

  // Get product group, get sizes of the products in that group, set sizes
  async setOptionSizes(widget, allProductSizesMap, sizeTypesMap, companyID): Promise<{ widget, optionChanged: boolean }> {

    let productsInGroup;
    let productWidgetSizes = {}
    let sortOrderList = [];
    let optionChanged = false;

    // Get all the products that belong to this product group
    productsInGroup = await this.productsService.getProductByGroupIDWithParamCompanyID(widget['element']['groupId'], companyID)
    
    productsInGroup.forEach(product => {
      productWidgetSizes[product['productSizeID']] = allProductSizesMap[product['productSizeID']];
      if (sizeTypesMap[product['productSizeTypeID']]?.sortOrder) {
        sortOrderList = sizeTypesMap[product['productSizeTypeID']]['sortOrder'];
      }
    })

    // If there are already options for the product
    if (widget.element.options) {
      widget.element.options.forEach((option, ind )=> {

        // If size doesnt exist anymore then remove it
        if (!productWidgetSizes.hasOwnProperty(option['sizeID'])) {
          delete widget.element.options[ind];
          optionChanged = true;
        }
        // Otherwise make sure the size name is up to date
        // Remove size from map since it is already an option
        else {
          option['size'] = productWidgetSizes[option['sizeID']]['size'];
          delete productWidgetSizes[option['sizeID']];
        }

        // To fix isSelected not showing up for daniel
        if (!option['is_selected']) {
          option['is_selected'] = false;
        }
      })
    }

    // If there are new sizes add them to the product widgets options
    if (Object.keys(productWidgetSizes).length > 0) {
      for (let key in productWidgetSizes) {
        widget.element.options.push(
          {
            id: uuid(),
            size: productWidgetSizes[key]['size'],
            sizeID: productWidgetSizes[key]['id'],
            inputValue: 0,
            show: true
          }
        )
      }
      optionChanged = true;
    }
  
    // Sorting
    let optionsList = [];
    let tempOptionsMap = {};

    // Makes a copy of the options sizeID
    widget.element.options.forEach(option => {
      optionsList.push(option.sizeID);
    })
    
    if (sortOrderList.length > 0) {
      // Sorts the ID's based on the sort order in the product size types collection
      optionsList.sort((a, b) => sortOrderList.indexOf(a) - sortOrderList.indexOf(b));
    }

    // Copies the options
    let tempOptions = widget.element.options;
    widget.element.options = [];

    // Makes the copied option into a map
    tempOptions.forEach((option) => {
      tempOptionsMap[option.sizeID] = option;
    })

    // Pushes the options back onto the real component in the correct order
    optionsList.forEach(option => {
      widget.element.options.push(tempOptionsMap[option])
    })

    return { widget, optionChanged }
  }


  calculateProductWidgetPrice(productWidget, numberOfDaysChosen, selectedHours, is24hrsPrice?: Boolean) {
    let newPrice = 0;
    // If it is a multiple day rental
    if (numberOfDaysChosen > 1) {
      let numberOfDaysCharged;
      numberOfDaysCharged = JSON.parse(JSON.stringify(numberOfDaysChosen));
      let alreadyFound = false;
      // --------- If it is a 24 hour rental --------- //
      if (is24hrsPrice || selectedHours == "24 Hour Rental") {
        numberOfDaysCharged = numberOfDaysCharged - 1;
      }

      for (let i = productWidget['priceByDay'].length - 1; i >= 0; i--) {

        if (productWidget['priceByDay'][i]['day'] <= numberOfDaysCharged && !alreadyFound) {
          alreadyFound = true;
          // Add inital price
          newPrice = Number(productWidget['priceByDay'][i]['price'])
          // Calculate amount to add for extra incremented days, get number of increments
          let incrementAmountAdded = numberOfDaysCharged - productWidget['priceByDay'][i]['day'];
          // Multiply number of increments by increment price and add it to total
          newPrice += incrementAmountAdded * Number(productWidget['priceByDay'][i]['increment'])
        }
      }
    }

    // If it is a single day rental
    else {
      if (selectedHours == "All Day Rental" || selectedHours == "24 Hour Rental") {
        newPrice = productWidget['priceByDay'][0]['price'];
      }
      else {
        if (productWidget['priceByHour'][0]['increment']) {
          newPrice = Number(productWidget['priceByHour'][0]['increment']) * (selectedHours - 1)
        }
        newPrice += Number(productWidget['priceByHour'][0]['price'])
      }
    }
    return Math.round(newPrice)
  }




//----- Widget list logic -----//

  // Splits the widget list into regular widgets and cart widgets for display
  filterWidgetCartList(list, cartBool: Boolean){
    let newList = []
      list.forEach(widget => {
        if(widget?.isCartWidget == cartBool){
          newList.push(widget);
        }
        // if the isCartWidget is not set
        else if(!widget.isCartWidget && !cartBool){
          newList.push(widget);
        }
      })
    return newList
  }



// -- Widget validation for inventory page parent && cart component -- //

requiredWidgetsCheck(widgetList) {
  let pass = true;
  
  widgetList.forEach((widget) => {
    // If a widget is required make sure there is an input value
    if (widget['element']?.is_required) {

      //If it is a productWidget it will be treated differently
      if (widget.widgetType == 'product') {
      
        if (widget['element']['isDropdown']) {
          let foundSelected = false;
          widget['element']['options'].forEach(option => {
            if (option['is_selected']) {
              foundSelected = true;
            }
          })
          if (!foundSelected) {
            pass = false;
          }
        }
        else {
          let inputAmount = 0;
          widget['element']['options'].forEach(option => {
            if (option['inputValue']) {
              if (option['inputValue'] > option['max']) {
                pass = false;
              }
              inputAmount += Number(option.inputValue);
            }
          })
          // If the input was less than the min or greater than the max return false
          if (widget['element']['min'] && inputAmount < widget['element']['min']) {
            pass = false;
          }
          else if(widget['element']['max'] && inputAmount > widget['element']['max']) {
            pass = false;
          }
        }
      }

      else if (widget.widgetType == 'quantity') {
        // If widget doesnt have an input value
        if (!widget['element']['inputValue'] ) {
          // If widget has an input value of 0 but the min is 0 then ignore it
          if (widget['element']['inputValue'] == 0 && widget['element']['min'] == 0) {

          }
          else {
            pass = false;
          }
          
        }
        // If widget does have an input value
        else {
          if (widget['element']['min'] && widget['element']['inputValue'] < widget['element']['min']) {
            // if the input does not meet the min requirement
            pass = false;
            
          }
          // if the input is greater than the max
          else if (widget['element']['max'] && widget['element']['inputValue'] > widget['element']['max']) {
            pass = false;
          }
        }
      }
      // ---- Check box ---- //
      else if (widget.widgetType == 'checkbox') {
        if (!widget['element']['inputValue']) {
          pass = false;
        }
      }

      else if(widget.widgetType == 'heightInput'){
        if(!widget['element']['feetInput'] || !widget['element']['inchInput'] && widget['element']['inchInput'] != 0 ){
          pass = false;
        }
      }

      else if(widget.widgetType == 'weightInput'){
        if(!widget['element']['inputValue']){
          pass = false;
        }
      }
      
    }

    // If widget is not required
    else {

      if (widget.widgetType == 'product') {
        // If product widget is not a dropdown, make sure the inputs are not greater than the max allowed
        let inputAmount = 0;
        widget['element']['options'].forEach(option => {
          if (option['inputValue']) {
            if (option['inputValue'] > option['max']) {
              pass = false;
            }
            inputAmount += Number(option.inputValue);
          }
        })
        
      }
    }

  })
  

  return pass
}


// -- Cleaning widget list & set up for databse -- //

  assignProductWidgetIDs(widgetList, algoRes, IDsUsed){
    widgetList.forEach((widget, index) => {
      if (widget.widgetType == 'product') {
        widget['element']['options'].forEach((opt, ind) => {
          if (opt.inputValue > 0) {
            let counter = 0;
            let tempArray = []
            //
            Object.keys(algoRes['productWidgetTotals'][widget.element.groupId + "_" + opt['sizeID']]['uniqueIDs']).forEach(productID => {
              if (!IDsUsed.includes(productID) && counter != opt.inputValue) {
                tempArray.push(productID)
                IDsUsed.push(productID);
                counter ++
              }
            })
              widgetList[index]['element']['options'][ind]['productsCheckedOut'] = tempArray;
            }
            else {
              widgetList[index]['element']['options'][ind]['productsCheckedOut'] = [];
            }
        })
      }
    })

    return {widgetList, IDsUsed}
  }

  // Assigns available IDs to product widgets
  public assignProductWidgetIDsParsing(widgetList: WidgetInterface[], availabilityParsing: AvailabilityInterface, IDsUsed: string[]): { widgetList: WidgetInterface[], IDsUsed: string[] } {
    widgetList.forEach((widget) => {
      if (widget.widgetType === WidgetType.product) {
        if (widget.element && 'options' in widget.element && ('groupId' in widget.element)) {
          const productElement = widget.element as ProductElementInterface;
          productElement.options.forEach((option) => {
            // reset everytime to avoid any product widget availability issues
            option.productsCheckedOut = [];

            // if there are already IDs in products checkeout out then that means there are already IDs assigned to product widget
            // if there is an input value but there are too little/ too many products checked out then reset and find new IDs
            if (option.inputValue > 0) {
              let assignedCount = 0;
              // const assignedIDs: string[] = [];
              let productWidgetTotalsUniqueIDs;
              
              try {
                productWidgetTotalsUniqueIDs = availabilityParsing.getGroupSizeProductWidgetTotalsObject(productElement.groupId, option.sizeID).uniqueIDs;
                for (const productID in productWidgetTotalsUniqueIDs) {
                  if (!IDsUsed.includes(productID) && (assignedCount < option.inputValue)) {
                    option.productsCheckedOut.push(productID);
                    IDsUsed.push(productID);
                    assignedCount++;
                  }
                };
              } 
              catch (error) {
                throw new Error("Product widget totals unique IDs not found"); // Need to throw an error because a rental saved without IDs assigned to product widgets will cause issues such as double bookings
              }

              if (option.productsCheckedOut.length < option.inputValue) {
                throw new Error("Not enough products available for this product widget option");
              }
            } 
          });
        }
      }
    });

    return { widgetList, IDsUsed };
  }


setProductWidgetIDs(widgetForm, productMap, cartQuantities) {
  // Clearing the tempProductList
  // Add products to productsCheckout
  widgetForm.widgetList.forEach(widget => {

    if (widget.widgetType == "product") {
      delete widget['tempProductWidgets'];

      if(widget['element']['isDropdown']){
        widget['element']['options'].forEach(option => {
          option['productWidgetGroupID'] = widget.element.groupId;
          let previousQty = 0; // keeps track of if you increse or decrese qty for algo= cart quantities
          if(option['productsCheckedOut']){
            previousQty = option.productsCheckedOut.length
          }

          if (option['is_selected'] && option['productsAvail'].length > 0) {
            option['productsCheckedOut'] =  this.getIndividualProductWidgetID(option, true);
          }

          else if (option['is_selected'] && option['productsAvail'].length == 0){
            option['inputValue'] = 0;
            option.productsCheckedOut = [];
          }
          else{
            option.productsCheckedOut = [];
          }

          let newAmount = option['productsCheckedOut'].length - previousQty;
          // cartQuantities = this.updateAlgoCartQuantities(widget.element.groupId, option['sizeID'], newAmount, cartQuantities);
        })
      }
      else{
        widget['element']['options'].forEach(option => {
          option['productWidgetGroupID'] = widget.element.groupId;
          let previousQty = 0; // keeps track of if you increse or decrese qty for algo= cart quantities
          if(option['productsCheckedOut']){
            previousQty = option.productsCheckedOut.length
          }

          if (option['inputValue'] && option['productsAvail'].length > 0) {
            option['productsCheckedOut'] =  this.getIndividualProductWidgetID(option, false);
          }
          else if(!option['inputValue']){
            option['inputValue'] = 0;
            option.productsCheckedOut = [];
          }
          else{
            option.productsCheckedOut = [];
          }

          let newAmount = option['productsCheckedOut'].length - previousQty;
          // cartQuantities = this.updateAlgoCartQuantities(widget.element.groupId, option['sizeID'], newAmount, cartQuantities);
        })
      }
    }
  })
  return {widgetForm, cartQuantities};
  }


getIndividualProductWidgetID(option, isDropdown: boolean){
  let checkout = [];
  let count = 0;
  let found = false;
  let productIDUsed = [];

  // Check to see if the products checked out has already been completed
  if(option?.productsCheckedOut){
    // If there are already options set then set the chekout list equal to the already set options
    if(option.productsCheckedOut.length < option.inputValue){
      count = option.productsCheckedOut.length;
      checkout = option.productsCheckedOut;
    }
    // If the products checked out has already been filled out then return the productCheckedOut list early
    else if(option.productsCheckedOut.length == option.inputValue){
      count = option.productsCheckedOut.length;
      return option.productsCheckedOut
    }
  }

    // If it is a dropdown just run through the loop once
    if(isDropdown){
      if(count < 1){
        option['productsAvail'].forEach(product => {
          if(!productIDUsed.includes(product) && !found){
            checkout.push(product);
            productIDUsed.push(product)
            count += 1;
            if(count == 1){
              found = true;
            }
          }
        })
      }
    }
    else{
      // If it is a quantity, loop through the products avail list and add product IDs untill you reach the input number, 
      // Make sure to not add duplicate ID'S
      if(count < option['inputValue']){
        option['productsAvail'].forEach(product => {
          if(!productIDUsed.includes(product) && !found){
            
            checkout.push(product);
            productIDUsed.push(product)
            count += 1;
            if(count == option['inputValue']){
              found = true;
            }
          }
        })
      }
    }

  return checkout
  }


  getWidgetStructureList(){

    let componentSettings = {
      background_color: '',
      border: false,
      rounded_corners: 0,
      padding_top: 10,
      padding_right: 0,
      padding_bottom: 10,
      padding_left: 0,
      height: 0,
      alignment: 'left',
      text_color: '',
      text_size: 15,
    };
    let widgetStructureList =

    [
    // ------ Set checkbox list ------ //
    {
      widgetType: 'checkbox',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Checkbox',
      iconClass: 'far fa-check-square fa-2x',
      element: {
        label: 'Checkbox label',
        is_required: false,
        price: null,
        totalPrice: null,
        description: '',
        inputValue: false,
        recurringPrice: false,
        apply_taxes: true,
        is_percentage: false,
      },
      componentSettings: {
        background_color: '',
        border: true,
        rounded_corners: 10,
        padding_top: 10,
        padding_right: 24,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
    },
  
  // ------ Set quantity list ------ //
    {
      widgetType: 'quantity',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Quantity',
      iconClass: 'fa fa-hashtag fa-2x',
      element: {
        label: 'Quantity label',
        is_required: false,
        price: null,
        totalPrice: null, // Price * inputValue
        description: '',
        inputValue: 0,
        recurringPrice: false,
        min: 0,
        max: null,
        apply_taxes: true,
        is_percentage: false,
      },
  
      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
  
    },
  
  // ------ Set price list ------ //
    {
      widgetType: 'price',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Price',
      iconClass: 'fa fa-dollar-sign fa-2x',
      element: {
        label: 'Price label',
        is_required: false,
        price: null,
        totalPrice: null,
        description: '',
        inputValue: true,
        recurringPrice: false,
        apply_taxes: true,
        is_percentage: false,
      },
      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
  
    },
  
  // ------ Set textarea list ------ //
    {
      widgetType: 'textarea',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Textarea',
      iconClass: 'far fa-window-maximize fa-2x',
      element: {
        label: 'Textarea label',
        is_required: false,
        show_label: true,
        content: 'Placeholder text',
        description: '',
      },
      componentSettings: componentSettings,
  
    },
  
    // ------ Set image list ------ //
    
    {
      widgetType: 'image',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Image',
      iconClass: 'far fa-image fa-2x',
      element: {
        image_link: '../assets/images/notAvailable.png',
        label: '',
        alignment: 'center',
        alt_text: '',
        width: 35,
        description: '',
      },
      componentSettings: componentSettings,
    },
  
  
    // ------ Set radios list ------ //
      
      {
        widgetType: 'radios',
        isCartWidget: null,
        fontSize: 16,
        widgetName: 'New',
        savedWidgetId: '',
        sectionContentId: '',
        sectionPosition: [],
        isSelected: false,
        name: 'Radios',
        iconClass: 'fas fa-list-ul fa-2x',
        element: {
          label: 'Radio buttons label',
          totalPrice: null,
          recurringPrice: false,
          description: '',
          inputValue: '',
          options: [
            {
              id: uuid(),
              text: 'Option 1',
              description: '',
              is_selected: true,
              price: null,
    
            },
            {
              id: uuid(),
              text: 'Option 2',
              description: '',
              is_selected: false,
              price: null,
            },
            {
              id: uuid(),
              text: 'Option 3',
              description: '',
              is_selected: false,
              price: null,
            },
          ],
          is_required: false,
          show_label: true,
          apply_taxes: true,
          is_percentage: false,
        },
  
        componentSettings: {
          background_color: '',
          border: false,
          rounded_corners: 0,
          padding_top: 10,
          padding_right: 15,
          padding_bottom: 10,
          padding_left: 15,
          height: 0,
          alignment: 'left',
          text_color: '',
          text_size: 15,
  
        },
      },
      
  
  
    // ------ Set dropdown list ------ //
      
      {
        widgetType: 'dropdown',
        isCartWidget: null,
        widgetName: 'New',
        savedWidgetId: '',
        sectionContentId: '',
        sectionPosition: [],
        isSelected: false,
        name: 'Dropdown',
        iconClass: 'far fa-caret-square-down fa-2x',
        element: {
          label: 'Dropdown label',
          totalPrice: null,
          recurringPrice: false,
          description: '',
          is_required: false,
          show_label: true,
          inputValue: '',
          options: [
            {
              id: uuid(),
              text: 'Option 1',
              is_selected: true,
              price: null,
            },
            {
              id: uuid(),
              text: 'Option 2',
              is_selected: false,
              price: null,
            },
            {
              id: uuid(),
              text: 'Option 3',
              is_selected: false,
              price: null,
            },
          ],
          apply_taxes: true,
          is_percentage: false,
        },
        componentSettings: {
          background_color: '',
          border: false,
          rounded_corners: 0,
          padding_top: 10,
          padding_right: 15,
          padding_bottom: 10,
          padding_left: 15,
          height: 0,
          alignment: 'left',
          text_color: '',
          text_size: 15,
          width: 100,
        },
      },
      
  
    // ------ Set product list ------ //
      {
        widgetType: 'product',
        isCartWidget: null,
        widgetName: 'New',
        savedWidgetId: '',
        sectionContentId: '',
        sectionPosition: [],
        isSelected: false,
        name: 'Product',
        iconClass: 'fas fa-box fa-2x',
        element: {
          groupId: null,
          label: 'Select a size ',
          name: '',
          size: '',
          description: '',
          appliesToTourProductCount: true,
          is_required: false,
          isDropdown: false,
          min: 0,
          max: null,
          priceByDay: null,
          priceByHour: null,
          is24hrsPrice: null,
          price: null,
          totalPrice: null,
          show_label: true,
          show_description: true,
          show_price: true,
          show_price_label: false,
          show_name: true,
          inputValue: 0,
          options: [],
          apply_taxes: true,
          is_percentage: false,
        },
        componentSettings : {
          background_color: '',
          border: false,
          rounded_corners: 0,
          padding_top: 10,
          padding_right: 15,
          padding_bottom: 10,
          padding_left: 15,
          height: 0,
          alignment: 'left',
          text_color: '',
          text_size: 15,
          width: 100
          }
      },
      
  
    // ------ Set height input list ------ //
    {
      widgetType: 'heightInput',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Height Input',
      iconClass: 'fa fa-ruler-vertical fa-2x',
      element: {
        label: 'Height Input Label',
        description: '',
        is_required: false,
        price: null,
        totalPrice: null,
        recurringPrice: false,
        feetInput: null,
        inchInput: null
      },
      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
    },
  
    // ------ Set weight input list ------ //
    {
      widgetType: 'weightInput',
      isCartWidget: null,
      widgetName: 'New',
      savedWidgetId: '',
      sectionContentId: '',
      sectionPosition: [],
      isSelected: false,
      name: 'Weight Input',
      iconClass: 'fa fa-weight-scale fa-2x',
      element: {
        label: 'Weight Input Label',
        description: '',
        is_required: false,
        price: null,
        totalPrice: null,
        recurringPrice: false,
        inputValue: null,
      },
      componentSettings: {
        background_color: '',
        border: false,
        rounded_corners: 0,
        padding_top: 10,
        padding_right: 15,
        padding_bottom: 10,
        padding_left: 15,
        height: 0,
        alignment: 'left',
        text_color: '',
        text_size: 15,
      },
      }
    ]
    return widgetStructureList
  }
}
