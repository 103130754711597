<div class="card-body">
  <div class="header" class="mb-2">
    <div class="row justify-content-center">
      <div class="col text-left">
        <h1 class="mb-2 component-title">
          Product Sizes
          <button type="button" [style.background]="gethemetext" [style.color]="gethemecolor" class="btn"
            style="cursor: pointer" (click)="openNewSizeList(false)">
            <i class="fas fa-plus"></i>
          </button>
        </h1>
        <p class="mt-2" style="font-size: 16px">
          This section creates the custom size options available for each
          product type
        </p>
      </div>
    </div>
  </div>

  <!-- Start of Custom Size Lists dashboard -->

  <ng-container *ngIf="sizesMap.size == 0">You currently have no sizing lists set up
    <button type="button" class="btn btn-success" (click)="openNewSizeList(false)" data-bs-toggle="modal"
      data-bs-target="#newSizeListModal">
      Create Custom Size List <i class="fa fa-plus fa-lg"></i>
    </button>
  </ng-container>
  <div class="row">
    <ng-container *ngFor="let productType of sizesMap | keyvalue : asIsOrder; let j = index" class="container-fluid">
      <div class="col-md-6 col-lg-4 col-xl-3 mt-2">
        <div class="product-type-list pt-1 pr-2 pl-2 pb-1">
          <div class="row">
            <div class="col pt-2">
              <h2 class="list-title pl-3">
                <ng-container *ngIf="currentUser?.isDeveloper; else nonDeveloper">
                  <a href="https://console.firebase.google.com/project/{{ projectId }}/firestore/databases/-default-/data/~2FproductSizeTypes~2F{{ productType.value.id }}" target="_blank" rel="noopener noreferrer" style="text-decoration: none">
                    <strong>{{ productType.value.productType }}
                    </strong>
                  </a>
                </ng-container>
                <ng-template #nonDeveloper>
                  <strong>{{ productType.value.productType }}
                  </strong>
                </ng-template>
              </h2>
            </div>
            <div class="col-sm-1 mt-2 mb-2">
              <!-- Edit List Button -->

              <button *ngIf="productType.value.editable" type="button" class="btn btn-success btn-circle float-right"
                (click)="openNewSizeList(true, productType.key)">
                <i class="fa fa-edit fa-lg"></i>
              </button>
            </div>
          </div>

          <div cdkDropList class="size-list mb-2 pl-2 pr-2 row" (cdkDropListDropped)="drop($event, productType.key)">
            <div class="size-box col-sm-12" [ngClass]="productType.value.isDefault ? 'no-dragme' : 'dragme'"
              *ngFor="let size of productType.value.sortOrder; let i = index" cdkDrag
              [cdkDragDisabled]="productType.value.isDefault">
              <div [hidden]="showEdit === this.EDIT_ID_PREFIX + size['id']">
                <div class="row">
                  <div class="col-xs-6 col-md-7 mt-2 mb-3">
                    <strong>
                      <p>
                        <ng-container *ngIf="currentUser?.isDeveloper; else nonDeveloper2">
                          <a href="https://console.firebase.google.com/project/{{ projectId }}/firestore/databases/-default-/data/~2FproductSizes~2F{{ size['id'] }}" target="_blank" rel="noopener noreferrer" style="text-decoration: none">
                            <span class="vertical-center">{{ size["size"]
                              }}</span>
                          </a>
                        </ng-container>
                        <ng-template #nonDeveloper2>
                          <span class="vertical-center">{{ size["size"]
                            }}</span>
                        </ng-template>
                      </p>
                    </strong>
                  </div>
                  <div class="col-xs-6 col-md-5 mt-1 mb-1">
                    <button *ngIf="productType.value.editable" type="button"
                      class="btn btn-success btn-circle float-left" (click)="
                        showEditDiv(
                          size['id'],
                          false,
                          size['size'],
                          size['productType']
                        )
                      ">
                      <i class="fa fa-edit fa-lg"></i>
                    </button>
                    <button *ngIf="productType.value.editable" type="button"
                      class="btn btn-danger btn-circle float-right" (click)="
                        showEditDiv(
                          size['id'],
                          false,
                          size['size'],
                          size['productType'],
                          true
                        )
                      ">
                      <!-- <i class="fa fa-trash fa-lg"></i> -->
                      <i class="fas fa-trash fa-lg" aria-hidden="true"></i>
                    </button>
                    <!-- delete('size',size['id']) -->
                  </div>
                </div>
              </div>
              <div [hidden]="!(showEdit === this.EDIT_ID_PREFIX + size['id'])" [id]="this.EDIT_ID_PREFIX + size['id']"
                class="size-box">
                <form #editSizeForm="ngForm" [formGroup]="sizeForm"
                  (ngSubmit)="validateSizeForm(editSizeForm, size['id'])" novalidate>
                  <!-- <label for="size"> Edit Size: {{size['size']}}</label> -->
                  <input class="form-control" type="text" formControlName="size" name="size" />
                  <!-- <input class="form-control" type="text" formControlName="size" name="size" [ngClass]="{'is-invalid': sizeFormControls.productType.errors}"> -->
                  <div class="float-right mt-1 mb-1">
                    <button type="button" class="btn btn-outline-danger float-left mr-1" (click)="hideEditDiv()">
                      Cancel
                    </button>
                    <button [disabled]="disabledButton" type="submit" id="sizeSubmitbtn"
                      class="btn btn-outline-success float-right">
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div [hidden]="!(showEdit === this.EDIT_ID_PREFIX + productType.key)"
              [id]="this.EDIT_ID_PREFIX + productType.key" class="size-box">
              <form #addNewSizeForm="ngForm" [formGroup]="sizeForm" (ngSubmit)="
                  validateSizeForm(addNewSizeForm, productType.value.id)
                " novalidate>
                <label for="size">New Size</label>
                <input class="form-control" [ngClass]="{
                    'is-invalid': this.sizeForm.get('size')?.invalid
                  }" type="text" formControlName="size" name="size" />
                <!-- <input class="form-control" type="text" formControlName="size" name="size" [ngClass]="{'is-invalid': sizeFormControls.productType.errors}"> -->
                <div class="float-right mt-1 mb-1">
                  <button type="button" class="btn btn-danger float-left mr-1" (click)="hideEditDiv()">
                    Cancel
                  </button>
                  <button [disabled]="disabledButton" type="submit" id="sizeSubmitbtn"
                    class="btn btn-success float-right">
                    Create
                  </button>
                </div>
              </form>
            </div>

            <!-- Button to display/hide new size div (change 'hidden' attribute?) -->
            <div *ngIf="productType.value.editable" [hidden]="showEdit === this.EDIT_ID_PREFIX + productType.key"
              class="size-box add-new col-sm-12" (click)="showEditDiv(productType.key, false)">
              Add New Size <i class="fas fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- end of product-type-list -->
    </ng-container>
  </div>

  <!-- End of display size Lists & drag/drop -->
</div>

<!-- Start of CreateList/EditList Modal -->

<div class="modal" id="newSizeListModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <ng-container *ngIf="isEditing"> Edit Size List </ng-container>
          <ng-container *ngIf="!isEditing"> Add New Size List </ng-container>
        </h4>
        <button type="button" class="btn btn-danger" (click)="closeModalList()" aria-label="Close">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="needs-validation" [formGroup]="listForm" (ngSubmit)="validateListForm()" novalidate>
          <div class="row">
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="productType">Product Type / Title of Size List <b style="color: red">*</b>
                <span>
                  (Editing the size type here will update the size type on all
                  the products listed below).</span>
              </label>
              <input class="form-control" type="text" formControlName="productType" name="productType"
                placeholder='For example: "Bikes" or "European shoe sizes"' [ngClass]="{
                  'is-invalid': listFormControls.productType.errors && submitted
                }" />
            </div>
            <div *ngIf="listFormControls.productType.errors?.['required'] && submitted" class="text-danger">
              Please provide a Product Type or Valid Title
            </div>
          </div>

          <div class="text-end mt-2 mb-2">
            You can individually re-assign sizes prior to saving that you don't
            whish to be affected by selected size change above
          </div>

          <div class="row" *ngIf="!isEditing">
            <!-- <div class="row" *ngIf="!isEditing"> -->
            <div class="form-group-sm mb-2 input-group-sm">
              <label class="mb-1" for="sizes"><span *ngIf="isEditing">New </span>Sizes</label>
              <textarea class="form-control" type="textarea" formControlName="sizes" name="sizes"
                placeholder="Separate with commas, ex: XS,S,M,L,XL or 150cm,152cm,155cm" [ngClass]="{
                  'is-invalid': listFormControls.sizes.errors && submitted
                }" rows="3"></textarea>
              <!-- TestData:  1,2,3, S, M, L, s, m, l, Size With Space, SizeWithNoSpace, emptyStrings:,, , duplicate, duplicate -->
            </div>
            <div *ngIf="listFormControls.sizes.errors?.['required'] && submitted" class="text-danger">
              Please list at least one size
            </div>
          </div>

          <ng-container>
            <div class="row mb-3" [hidden]="editableH">
              <label class="col-sm-3 col-form-label">Editable</label>
              <div class="col-sm-9">
                <div class="form-check form-switch mt-2">
                  <input class="form-check-input" type="checkbox" formControlName="editable"
                    id="flexSwitchCheckChecked" />
                  <label class="form-check-label" for="flexSwitchCheckChecked">New size list is editable ?</label>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="row mb-3">
            <mat-accordion *ngIf="isEditing">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title> Products </mat-panel-title>
                  <mat-panel-description>
                    Change product size
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div *ngIf="
                    productsSizeType.length > 0;
                    then withData;
                    else noData
                  "></div>

                <ng-template #withData>
                  <div formArrayName="productsList">
                    <ng-container *ngFor="
                        let c of productsListControl.controls;
                        let i = index
                      " [formGroupName]="i">
                      <div class="row">
                        <div class="col-2">
                          <div class="mt-1">
                            <p class="fw-bold" [ngClass]="i === 0 ? 'product-name' : ''">
                              {{ productsSizeType[i].productName }}
                            </p>
                            <input type="text" class="form-control form-control-sm" formControlName="productID"
                              name="productID" hidden />
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group-sm mb-1 input-group-sm">
                            <label *ngIf="i === 0" class="mb-1" for="productSize">Product Size Type</label>

                            <select class="form-select mb-3" type="text" formControlName="productSizeType"
                              (change)="changeCustomSizeType($event, i, 'list')" name="productSizeType">
                              <option value="" selected disabled>
                                Choose a product type...
                              </option>
                              <option *ngFor="
                                  let productTypeID of sizesMap
                                    | keyvalue : asIsOrder
                                " [value]="productTypeID.value.id">
                                {{ productTypeID.value.productType }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col">
                          <div class="form-group-sm mb-1 input-group-sm">
                            <label *ngIf="i === 0" class="mb-1" for="productSize">Product Size</label>

                            <select class="form-select mb-3" type="text" formControlName="productSize"
                              name="productSize">
                              <option value="" selected disabled>
                                Choose a size...
                              </option>
                              <option *ngFor="
                                  let sizeObject of customSizeObjectsArray[i]
                                    | keyvalue : asIsOrder
                                " [value]="sizeObject.value['id']">
                                {{ sizeObject.value["size"] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>

                <ng-template #noData>
                  <div class="text-center">No products linked to this size</div>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <!-- <div>
                            Put list of products (which this type is applied to) here
                        </div> -->

          <div class="row d-flex justify-content-center">
            <div class="col-6 col-lg-4 text-left">
              <button type="button" class="btn btn-outline-danger btn-sm mt-1" (click)="delete('list', editId)"
                *ngIf="isEditing">
                Delete this List
              </button>
            </div>
            <div class="col-6 col-lg-4 text-center">
              <button [disabled]="disabledButton" type="submit" id="listSubmitbtn" class="btn btn-success">
                <ng-container *ngIf="isEditing"> Update </ng-container>
                <ng-container *ngIf="!isEditing"> Submit </ng-container>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- End of Create/Edit Modal -->

<!-- Start of edit size of products and Size Modal -->

<div class="modal fade" id="EditSizeProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="EditSizeProductLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="EditSizeProductLabel">{{ title }}</h1>
        <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form #editSizeForm="ngForm" autocomplete="off" [formGroup]="myForm">
          <div class="mb-3" [hidden]="deleteSize">
            <label for="" class="form-label">Size
              <span>(Editing the size here will update the size on all the products
                listed below).</span></label>
            <input type="text" class="form-control form-control-sm" [ngClass]="{
                'is-invalid': this.myForm.get('size')?.invalid
              }" formControlName="size" name="size" />
          </div>
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title> Products </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="productsSizeType.length > 0; then withData; else noData"></div>

              <ng-template #withData>
                <!-- Size -->
                <div formArrayName="products">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Product Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let c of productsControl.controls; let i = index" [formGroupName]="i">
                        <tr>
                          <td>{{productsSizeType[i].productName}}</td>
                          <td>{{productsSizeType[i].productLocationName}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                </div>
              </ng-template>

              <ng-template #noData>
                <div class="text-center">No products linked to this size</div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="text-end mt-2" *ngIf="this.deleteSize">
            If you delete this size, the products shown above will also be deleted.
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="validateSizeFormProducts(editSizeForm, productSizeID)"
          [hidden]="deleteSize">
          Save changes
        </button>
        <button type="button" class="btn btn-danger" (click)="validateSizeFormProducts(editSizeForm, productSizeID)"
          [hidden]="deleteSize ? false : true">
          Delete size
        </button>
      </div>
    </div>
  </div>
</div>
