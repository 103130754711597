import { RAPIDCYCLING, UTPOWERTOYS, DEVELOPER, BOATCATEGORY } from './constants';

export const environment = {
  production: true,
  app: {
    apiDomainName: "api.fleetmaid.com",
    appDomainName: "fleetmaid.com",
    contactEmail: "support@fleetmaid.com"
  },
  firebase: {
    apiKey: "AIzaSyCNqCCIy00pTaG1xA5uIHLar_8YmRNFnxA",
    authDomain: "fleet-adventures.firebaseapp.com",
    projectId: "fleet-adventures",
    storageBucket: "fleet-adventures.appspot.com",
    messagingSenderId: "294419644763",
    appId: "1:294419644763:web:0c1985a67c502cc47d9a7e",
    measurementId: "G-P31WHJB32Q"
  },
  google: {
    region: 'us-central1'
  },
  hardCodedFeatures: {
    advenireCompanyIDs: [RAPIDCYCLING, DEVELOPER],
    reservationCompanyIDs: [UTPOWERTOYS, DEVELOPER],
    reservationCategoryIDs: [BOATCATEGORY]
  },
  stripe: {
    defaultAccount: 'acct_1ARjJlB8APgzR4zo',
    key: 'pk_live_51Kehx6GsGs3VcMtU6X5PAMQL8r1f6LclluH9b1DVdUY6LU4hRYebFKsbIhHd1deG1w7QAa1a2c26JVrAj8Da1fCb00bHIMFdve'
  }
};
