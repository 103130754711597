// Angular and Others
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, take } from 'rxjs';

// Services
import { CartService } from 'src/app/services/cart.service';
import { RentalService } from 'src/app/services/rental.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';

// Dependencies
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import * as ccsJSON from 'countrycitystatejson';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { Rental } from 'src/app/models/storage/rental.model';

interface Country {
  shortName: string;
  name: string;
}

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent {
  // GLOBALS

  @Input() userBelongsToCompany: boolean | undefined;
  @Input() pmtLinkRentalDoc: Rental | null = null; //If we need to create a rental

  @Input() cartObj; // Input to receive cart data
  @Input() isCreator;
  @Input() isDev;
  @Input() isPartner;
  @Output() customerInfoAddedToCart = new EventEmitter<any>(); // Output event emitter to notify when customer info is added to the cart
  @Output() customerInfoAddedToCartWOPayment = new EventEmitter<any>(); // Output event emitter to notify when customer info is added to the cart and payment is not required
  @Output() goBackToCart = new EventEmitter<any>(); // Output event emitter to go back if something is missing
  @Output() goToPaymentIntent = new EventEmitter<any>(); // Output event emitter to go back to custmer info if something is missing

  @Output() updateCart = new EventEmitter<Cart>();

  // Cart
  private cartData = {}; // Private variable to store cart data
  private cartSubscription: Subscription; // Subscription for cart data updates

  //Stripe
  public isTestStripe: boolean = false;
  public isSkipPayment: boolean = false;

  // Form
  public customerInfoForm: FormGroup; // Form group for customer information
  public submitTitle: string = $localize`Save`;

  // Address
  public country = new FormControl('US', [Validators.required]); // Form control for country selection
  public state = new FormControl(); // Form control for state/province selection
  public city = new FormControl({ value: null, disabled: true }); // Form control for city selection
  public addressF = new FormControl({ value: null, hidden: true }, [
    Validators.required,
  ]); // Form control for address input
  public stateTitle: string = $localize`State`; // Title for state/province input
  public states: string[] = []; // List of states/provinces
  public cities: string[] = []; // List of cities
  public zipTitle: string = $localize`Zip`; // Title for zip/postal code input
  public isCountrySelect: boolean = false;
  public countries: Country[]; // List of countries
  private countryData = ccsJSON; // Data for countries, states, and cities
  public templateID: string = '';
  // Utils
  public submitted: boolean = false; // Flag for form submission

  // Phone
  public countryISO = CountryISO; // ISO country codes
  public preferredCountries: CountryISO[] = [
    // Preferred countries for phone number input
    CountryISO.UnitedStates,
    CountryISO.Mexico,
    CountryISO.Canada,
  ];
  public searchCountryField = SearchCountryField; // Search field for country selection
  public phoneNumberFormat = PhoneNumberFormat; // Phone number format options
  private countriesWStates = [
    // Countries with states/provinces
    'Australia',
    'Austria',
    'Brazil',
    'Germany',
    'India',
    'Malaysia',
    'Mexico',
    'Micronesia',
    'Myanmar',
    'New Zealand',
    'Nigeria',
    'Palau',
    'South Sudan',
    'United States',
  ];
  private countriesWZipCode = ['United States', 'Philippines']; // Countries with zip/postal codes

  constructor(
    private cartService: CartService,
    private $fb: FormBuilder,
    private $afFun: AngularFireFunctions,
    private activatedRoute: ActivatedRoute,
    private rentalService: RentalService,
    private firestoreService: FirestoreService
  ) {
    this.customerInfoForm = this.$fb.group({
      firstName: ['', [Validators.required]], // First name input with validation
      lastName: ['', [Validators.required]], // Last name input with validation
      email: ['', [Validators.required, Validators.email]], // Email input with validation
      phone: ['', [Validators.required]], // Phone number input with validation
      address: ['', [Validators.required]], // Address input with validation
      address2: [''], // Additional address input
      customerID: [''],
      city: ['', [Validators.required]], // City input with validation
      state: ['', [Validators.required]], // State/province input with validation
      zip: ['', [Validators.required]], // Zip/postal code input with validation
      country: ['US', [Validators.required]], // Country selection with default value
    });
  }

  ngAfterViewInit() {
    this.initAddressForm(); // Initialize the address form
  }

  public handleSubmit(e) {
    Swal.fire({
      title: $localize`Saving Customer Information...`
    })
    Swal.showLoading();
    e.preventDefault(); // Prevent the default form submission behavior
    this.submitted = true;
    console.log("this.customerInfoForm", this.customerInfoForm)
    if (!this.customerInfoForm.valid){
      Swal.fire({
        title: $localize`Customer Information`,
        html: $localize`Please enter all the customer information`,
        icon: 'error',
      })
      return;
    }
    try {
      const customerInfo = {
        name: this.customerInfoForm.value.firstName,
        lastName: this.customerInfoForm.value.lastName,
        email: this.customerInfoForm.value.email,
        phone: this.customerInfoForm.value.phone.internationalNumber,
        address: this.customerInfoForm.value.address,
        address2: this.customerInfoForm.value.address2,
        city: this.customerInfoForm.value.city,
        customerID: this.customerInfoForm.value.customerID || "",
        state: this.customerInfoForm.value.state,
        zip: this.customerInfoForm.value.zip,
        country: this.customerInfoForm.value.country,
      };
      this.addToCart(customerInfo);
    } catch (error) {
      Swal.close();
      Swal.fire({
        title: $localize`Error`,
        html: $localize`There was an error trying to save the customer data`,
        icon: 'error',
      });
      console.error(error);
    }
  }

  public get f() {
    // Return controls to check if any field has errors
    return this.customerInfoForm.controls;
  }

  public setStates(event: any) {
    // Function to set states based on the selected country (currently commented out)
    let country = event.target.value;
    this.state.reset();
    this.state.setValue('');
    this.customerInfoForm.controls.state.setValue('');

    this.state.disable();
    if (country) {
      console.log(country);
      let currentCountry = this.countries.find((c) => c.shortName === country);
      console.log(currentCountry);

      if (this.countriesWStates.includes(currentCountry.name)) {
        this.stateTitle = $localize`State`;
      } else {
        this.stateTitle = $localize`Province`;
      }
      if (this.countriesWZipCode.includes(currentCountry.name)) {
        this.zipTitle = $localize`Zip`;
      } else {
        this.zipTitle = $localize`Postal Code`;
      }

      this.states = this.getStatesByCountry(country);
      let noStates = [
        'AA',
        'AE',
        'AP',
        'AS',
        'FM',
        'GU',
        'MH',
        'MP',
        'PR',
        'PW',
        'VI',
      ];
      //si this.states includes noStates, then delete noStates from this.states
      this.states = this.states.filter((state) => !noStates.includes(state));
      console.log(this.states);
      this.isCountrySelect = true;
      this.state.enable();
    }
  }

  private initAddressForm() {
    if (!this.isRentalAvailable) {
      // Return to cart (To be implemented)
    }
    const currentInfo = this.cartObj['customerInfo'];
    const itemsInCart = this.cartObj['items'];

    const cart = this.cartObj;
    if (cart.paymentIntent && cart.paymentIntent.status === 'succeeded') {
      // PAYMENT SUCCEEDED, NO CHANGES ALLOWED
      this.goToPaymentIntent.emit();
    }
    if (!itemsInCart || itemsInCart.length <= 0) {
      Swal.fire({
        title: $localize`Please add at least one item to cart`,
        icon: 'warning',
        text: $localize`Back to cart from customer`,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((_) => {
        this.goBackToCart.emit();
      });
      return;
    }
    let country = 'US';
    this.submitTitle = $localize`Save`;

    if (currentInfo) {
      country = currentInfo.country;
      this.loadInformation(currentInfo);
      this.submitTitle = $localize`Update`;
    }

    if (this.isPartner) {
      this.removeRequiredFields()
    }
    country = country || 'US'


    this.countries = this.getCountries();
    this.countries.sort((a, b) => a.name.localeCompare(b.name));

    this.updateStatesAndCities(country);

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      console.log(state);
      if (state) {
        console.log(state);
        this.customerInfoForm.get('state').setValue(state);
        this.cities = this.getCitiesByState(this.country.value, state);
        this.city.enable();
      }
    });
  }

  private updateStatesAndCities(country: string) {
    this.states = this.getStatesByCountry(country);
    const noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    this.states = this.states.filter((state) => !noStates.includes(state));
  }

  private async isRentalAvailable() {
    return true; // Function to check if rental is available (to be implemented)
  }

  private loadInformation(currentInfo) {
    this.customerInfoForm.controls['firstName'].setValue(currentInfo.name);
    this.customerInfoForm.controls['lastName'].setValue(currentInfo.lastName);
    this.customerInfoForm.controls['email'].setValue(currentInfo.email);
    this.customerInfoForm.controls['phone'].setValue(currentInfo.phone);
    this.customerInfoForm.controls['address'].setValue(currentInfo.address);
    this.customerInfoForm.controls['address2'].setValue(currentInfo.address2);
    this.customerInfoForm.controls['customerID'].setValue(currentInfo.customerID);
    this.customerInfoForm.controls['city'].setValue(currentInfo.city);
    this.customerInfoForm.controls['zip'].setValue(currentInfo.zip);
    this.customerInfoForm.controls['country'].setValue(currentInfo.country || 'US');
    setTimeout(() => {
      this.customerInfoForm.controls['state'].setValue(currentInfo.state);
    }, 500);
  }

  private removeRequiredFields() {
    // Eliminar Validators.required para el control 'address'
    this.customerInfoForm.get('address').clearValidators();
    this.customerInfoForm.get('address').updateValueAndValidity();

    // Eliminar Validators.required para el control 'city'
    this.customerInfoForm.get('city').clearValidators();
    this.customerInfoForm.get('city').updateValueAndValidity();

    // Eliminar Validators.required para el control 'state'
    this.customerInfoForm.get('state').clearValidators();
    this.customerInfoForm.get('state').updateValueAndValidity();

    // Eliminar Validators.required para el control 'zip'
    this.customerInfoForm.get('zip').clearValidators();
    this.customerInfoForm.get('zip').updateValueAndValidity();
  }

  private async addToCart(customerInfo) {
    try {
      const cartID = await this.getCartID();
      // if(this.createRental) {
      //   if (!cartID) {
      //     console.error("No cart found, all go for rentalID");
      //   }
        // if (!this.isRentalAvailable()) return; // Check if rental is available (to be implemented)
        // const cartData = await this.cartService.getCart(cartID).pipe(take(1)).toPromise();
        // if (!cartData.payload.exists) {
          // await this.handleNewCart(cartID, customerInfo);
        // } else {
      //     await this.handleExistingCart(cartID, customerInfo, this.cartObj.rentalID);
      //   }
      // } else {
      //   await this.handleExistingCart(cartID, customerInfo, this.cartObj.rentalID);
      // }
      const type = this.pmtLinkRentalDoc ? Collection.Rentals : Collection.Cart;
      await this.handleExistingCart(type, cartID, customerInfo);

    } catch (error) {
      console.error("Error in addToCart:", error);
    }
  }

  private async getCartID() {
    if (!this.pmtLinkRentalDoc) {
      this.templateID = this.activatedRoute.snapshot.paramMap.get('templateID'); // get the templateID from the URL
      return localStorage.getItem(`FM-cart-uuid-${this.templateID}`);
    } else {
      return this.pmtLinkRentalDoc.id;
    }

  }

  // private async handleNewCart(cartID, customerInfo) {
  //   this.cartData = {}; // Initialize cart data
  //   localStorage.removeItem(`FM-cart-uuid-${this.templateID}`); // Delete local storage cart
  //   const newCartID = await this.cartService.addCustomerInfoToCartAndReturnId({ customerInfo });
  //   localStorage.setItem(`FM-cart-uuid-${this.templateID}`, newCartID); // Create a new cart and add items
  //   this.isSkipPayment ? await this.addIsSkipPayment(newCartID, true) : await this.addTypeOfPayment(newCartID, this.isTestStripe);
  //   Swal.close();
  // }

  private async handleExistingCart(type: Collection.Cart | Collection.Rentals, id: string, customerInfo: any): Promise<void> {
    try {
      if(type == Collection.Rentals) { //Rental Link
        const rental = await this.rentalService.getRentalByIDPromise(id);
        this.cartObj.amountPending = rental.amountPending;
        await this.firestoreService.updateDocument(type, id, {userInfo: customerInfo, 'cartObj.customerInfo': customerInfo }, true);
      } else if (!this.pmtLinkRentalDoc) { //Cart
        this.cartObj = await this.firestoreService.updateDocument(type, id, {customerInfo: customerInfo }, true);
      } else {
        console.warn("Rental ID is missing. Rental information will not be updated.");
      }

      this.updateCart.emit(this.cartObj);
      await this.handlePaymentSettings(type, id);
      Swal.close();
    } catch (error) {
      console.error('Error updating Customer information:', error);
      Swal.close();
    }
  }

  private async handlePaymentSettings(type: Collection.Cart | Collection.Rentals, id: string): Promise<void> {
    try {
      await this.updatePaymentSettings(type, id);
    } catch (error) {
      console.error('Error handling payment settings:', error);
    }
  }

  private async updatePaymentSettings(type: Collection.Cart | Collection.Rentals, id: string): Promise<void> {
    if (this.isSkipPayment) {
      await this.handleTypeOfPayment(type, id, false);
    } else {
      await this.handleTypeOfPayment(type, id, true);
    }
  }

  private async handleTypeOfPayment(type: Collection.Cart | Collection.Rentals, id: string, processPayment: boolean = true): Promise<void> {
    if (processPayment) {
      try {
        await this.updatePaymentOption(type, id);
        this.customerInfoAddedToCart.emit(this.isTestStripe);
      } catch (error) {
        console.error("Error adding test stripe:", error);
      }
    } else {
      try {
        await this.updatePaymentOption(type, id);
        this.customerInfoAddedToCartWOPayment.emit(this.isSkipPayment);
      } catch (error) {
        console.error("Error adding skip payment:", error);
      }
    }
  }

  private async updatePaymentOption(type: Collection.Cart | Collection.Rentals, id: string): Promise<void> {
    try {
      if (id) {
        const paymentUpdate = {
          isSkipPayment: this.isSkipPayment,
          isTestStripe: this.isTestStripe
        }
        const document = await this.firestoreService.updateDocument<Cart | Rental>(type, id, paymentUpdate, true);
        if (type === Collection.Cart) {
          this.updateCart.emit(document as Cart)
        }
      } else {
        console.warn("ID is missing. Payment option will not be updated.");
      }
    } catch (error) {
      console.error("Error updating payment option:", error);
    }
  }

  private getCountries() {
    return this.countryData.getCountries();
  }

  private getStatesByCountry(countryShortName: string) {
    const country = countryShortName || 'US'
    if (!countryShortName) {
      console.error(`getStatesByCountry(${countryShortName}) :: missing country short name, default to US`)
    }
    return this.countryData.getStatesByShort(country);
  }

  private getCitiesByState(countryShortName: string, stateArg: string) {
    const country = countryShortName || 'US'
    if (!countryShortName) {
      console.error(`getCitiesByState(${countryShortName}, ${stateArg}) :: missing country short name, default to US`)
    }

    const state = stateArg || 'Utah'
    if (!stateArg) {
      console.error(`getCitiesByState(${countryShortName}, ${stateArg}) :: missing state argument, default to Utah`)
    }
    return this.countryData.getCities(country, state);
  }
}
