<!--Order Processing tittle-->
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Order Processing</h1>
    </div>

    <div class="col-md-6 text-right">
      <div class="mt-2 mb-2">
        <span style="margin: 0px 5px">
          <span class="badge badge-success">
            <i class="fa fa-check"></i>
          </span>
          - Ready
        </span>
        <span style="margin: 0px 5px">
          <div class="badge badge-warning">
            <i class="fa fa-check"></i>
          </div>
          - Not Ready
        </span>
        <span style="margin: 0px 5px">
          <div class="badge bg-info">
            <i class="fa fa-dollar-sign" style="padding: 0px 3px"></i>
          </div>
          - Pending Balance
        </span>
        <span style="margin: 0px 5px">
          <div class="badge badge-info">
            <i class="fa fa-truck" style="padding: 0px 3px"></i>
          </div>
          - Delivery
        </span>
      </div>
    </div>
  </div>
</div>
<!--Columns with data rows-->
<div class="container-fluid mt-3">
  <div class="row align-items-start">
    <!--Pending Prep of Tomorrow-->
    <!--<div class="col-md-3 mh-100">
    <p class="fs-5 w-100 text-center">Upcoming</p>
    <hr>
    <table class="table table-borderless" *ngIf="ppupcoming.length == 0">
      <tbody class="text-center">
      <tr class="bg-light">
        <td colspan="3">There are no products to prepare tomorrow</td>
      </tr>
      </tbody>
    </table>
    <table id="prepare" class="table table-hover" *ngIf="ppupcoming.length > 0">
      <tbody class="text-center">
      <tr *ngFor="let rental of ppupcoming" (click)="openModalPrepare(rental)">
        <td style="color: white;">.</td>
        <td class="fs-6 align-middle text-center">{{rental.productName1}} ({{rental.productSize}})
          #{{rental.productNumber}}
        </td>
        <td class="fs-6 align-middle text-right"><i class="fas fa-clock text-right text-info"></i></td>
      </tr>
      </tbody>
    </table>
  </div>-->

    <!--Pending Prep-->
    <div class="col-md-4 mh-100">
      <div class="container-fluid m-2 pt-1 p-3 border border-1 rounded text-left">
        <span class="row fs-5 ms-1 mt-2" style="margin-left: 0px !important">Prepared ({{
          preparedcard?.numberpast +
          preparedcard?.numbertoday +
          preparedcard?.numbertomorrow
          }})</span>
        <div class="row mt-4">
          <div *ngIf="preparedcard?.numbertomorrow > 0" class="col text-info fw-bold text-left">
            {{ preparedcard?.numbertomorrow }} Upcoming ({{
            preparedcard?.tomorrowproducts
            }})
          </div>
          <div *ngIf="preparedcard?.numbertoday > 0" class="col text-success fw-bold text-center">
            {{ preparedcard?.numbertoday }} Today ({{
            preparedcard?.todayproducts
            }})
          </div>
          <div *ngIf="preparedcard?.numberpast > 0" class="col text-danger fw-bold text-right">
            {{ preparedcard?.numberpast }} Late ({{ preparedcard?.pastoday }})
          </div>
        </div>
        <div *ngIf="
              preparedcard?.numbertomorrow > 0 ||
                preparedcard?.numbertoday > 0 ||
                preparedcard?.numberpast > 0;
              else nodata
            " class="progress mb-1 bg-danger" style="height: 10px">
          <div class="progress-bar bg-info" role="progressbar" aria-label="Past Today"
            [ngStyle]="{ width: preparedcard?.tomorrowproducts }" aria-valuemin="0" aria-valuemax="100">
          </div>
          <div class="progress-bar bg-success" role="progressbar" aria-label="Today"
            [ngStyle]="{ width: preparedcard?.todayproducts }" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <ng-template #nodata>
          <div class="progress mb-1 bg-secondary" style="height: 10px"></div>
        </ng-template>
      </div>
      <div class="container-fluid mb-3 text-secondary fs-5">
        <span>{{ currentMomentDate }}</span>
      </div>
      <div class="container" *ngIf="
            prepare.today.length == 0 ||
            preparedcard?.numberpast == prepare.today.length
          ">
        <div class="text-center row">
          <div class="bg-light">
            <div colspan="3">There are no products to prepare today</div>
          </div>
        </div>
      </div>

      <!--Prepared data display-->
      <div id="prepare" class="container" *ngIf="prepare.today.length > 0">
        <div *ngFor="let rental of prepare.today" (click)="openModalPrepare(rental)" class="row text-center"
          style="background: white" onmouseover="this.style.background='#E5E5E5';"
          onmouseout="this.style.background='white';">
          <ng-container *ngIf="(rental.formattedDayStart | date : 'YYYYMMdd') >= datenow3">
            <div style="display: block; padding-top: 6px"></div>
            <div class="col-4 fs-8 align-middle text-left">
              <span class="badge badge-secondary rentalNumberBadge text-light">
                {{ rental.rentalNumber }}</span>
              <span *ngIf="rental.formattedDayStart.getHours() == 0; else showHours">
                <span class="badge badge-secondary rentalNumberBadge">All Day</span></span>
              <ng-template #showHours><span class="badge badge-secondary rentalNumberBadge">
                  {{
                  getHourMinuetesFormat(rental.formattedDayStart, rental.formattedDayEnd)
                  }}</span></ng-template>
              <div style="padding: 4px; display: block"></div>
            </div>

            <div class="col-6 fs-8 align-middle text-center">
              {{ rental.productName1 }} ({{ rental.productSize }}) #{{
              rental.productNumber
              }}
              <span class="badge bg-info" *ngIf="rental.isDeliver">Deliver to {{
                rental.deliverInformation.name
                }}</span>
            </div>

            <div class="col-1 fs-8 align-middle text-success text-right">
              <i *ngIf="rental.notes" class="fa fa-comment icon-comment" aria-hidden="true"></i>
            </div>
            <div class="col-1 fs-8 align-middle text-success text-right">
              <i *ngIf="rental.shopComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="container-fluid mb-3 text-secondary fs-5">
        <span>{{ dt }}</span>
      </div>

      <!-- Pending prep for tommorow data -->
      <div class="container" *ngIf="prepare.nextday.length == 0">
        <div class="row text-center">
          <div class="bg-light">
            <div colspan="3">There are no products to prepare tomorrow</div>
          </div>
        </div>
      </div>

      <div id="prepare" class="container" *ngIf="prepare.nextday.length > 0">
        <div *ngFor="let rental of prepare.nextday" (click)="openModalPrepare(rental)" class="row text-center"
          style="background: white" onmouseover="this.style.background='#E5E5E5';"
          onmouseout="this.style.background='white';">
          <div style="display: block; padding-top: 6px"></div>
          <div class="col-4 fs-8 align-middle text-left">
            <span class="badge badge-secondary rentalNumberBadge">
              {{ rental.rentalNumber }}</span><br />
            <span *ngIf="rental.formattedDayStart.getHours() == 0; else showHours">
              <span class="badge badge-secondary rentalNumberBadge">All Day</span></span>
            <ng-template #showHours><span class="badge badge-secondary rentalNumberBadge">
                {{
                getHourMinuetesFormat(rental.formattedDayStart, rental.formattedDayEnd)
                }}</span></ng-template>
            <div style="padding: 4px; display: block"></div>
          </div>

          <div class="col-6 fs-8 align-middle text-center">
            {{ rental.productName1 }} ({{ rental.productSize }}) #{{
            rental.productNumber
            }}
            <span class="badge bg-info" *ngIf="rental.isDeliver">Deliver to {{
              rental.deliverInformation.name
              }}</span>
          </div>

          <div class="col-1 fs-8 align-middle text-success text-right">
            <i *ngIf="rental.notes" class="fa fa-comment icon-comment" aria-hidden="true"></i>
          </div>
          <div class="col-1 fs-8 align-middle text-success text-right">
            <i *ngIf="rental.shopComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>

    <!--Pending to Checked Out-->
    <div class="col-md-4 mh-100">
      <div class="container-fluid m-2 pt-1 p-3 border border-1 rounded text-left">
        <span class="row fs-5 ms-1 mt-2" style="margin-left: 0px !important">Check Out ({{
          checkoutcard?.numberpast +
          checkoutcard?.numbertoday +
          checkoutcard?.numbertomorrow
          }})</span>
        <div class="row mt-4">
          <div *ngIf="checkoutcard?.numbertomorrow > 0" class="col text-info fw-bold text-left">
            {{ checkoutcard?.numbertomorrow }} Upcoming ({{
            checkoutcard?.tomorrowproducts
            }})
          </div>
          <div *ngIf="checkoutcard?.numbertoday > 0" class="col text-success fw-bold text-center">
            {{ checkoutcard?.numbertoday }} Today ({{
            checkoutcard?.todayproducts
            }})
          </div>
          <div *ngIf="checkoutcard?.numberpast > 0" class="col text-danger fw-bold text-right">
            {{ checkoutcard?.numberpast }} Late ({{ checkoutcard?.pastoday }})
          </div>
        </div>
        <div *ngIf="
              checkoutcard?.numbertomorrow > 0 ||
                checkoutcard?.numbertoday > 0 ||
                checkoutcard?.numberpast > 0;
              else nodata
            " class="progress mb-1 bg-danger" style="height: 10px">
          <div class="progress-bar bg-info" role="progressbar" aria-label="Past Today"
            [ngStyle]="{ width: checkoutcard?.tomorrowproducts }" aria-valuemin="0" aria-valuemax="100">
          </div>
          <div class="progress-bar bg-success" role="progressbar" aria-label="Today"
            [ngStyle]="{ width: checkoutcard?.todayproducts }" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <ng-template #nodata>
          <div class="progress mb-1 bg-secondary" style="height: 10px"></div>
        </ng-template>
      </div>
      <div class="container-fluid mb-3 text-secondary fs-5">
        <span>{{ currentMomentDate }}</span>
      </div>

      <div class="container" *ngIf="checkout.today.length == 0">
        <div class="text-center row">
          <div class="bg-light">
            <div colspan="3">There are no orders to check out today</div>
          </div>
        </div>
      </div>

      <div id="checkedout" class="container" *ngIf="checkout.today.length > 0">
        <div *ngFor="let rental of checkout.today" class="row text-center" style="background: white"
          (click)="openModalCheckedOut(rental)" onmouseover="this.style.background='#E5E5E5';"
          onmouseout="this.style.background='white';">
          <ng-container *ngIf="(rental.datestart | date : 'YYYYMMdd') >= datenow3">
            <div style="display: block; padding-top: 6px"></div>

            <div class="col-4 fs-8 align-middle text-left">
              <span class="badge badge-secondary rentalNumberBadge">
                {{ rental.rentalNumber }}</span><br />
              <span *ngIf="rental.datestart.getHours() == 0; else showHours">
                <span class="badge badge-secondary rentalNumberBadge">All Day</span></span>
              <ng-template #showHours><span class="badge badge-secondary rentalNumberBadge">
                  {{
                  getHourMinuetesFormat(rental.datestart, rental.dateend)
                  }}</span>
              </ng-template>
              <div style="padding: 4px; display: block"></div>
            </div>

            <div class="col-4 fs-8 align-middle text-center">
              {{ rental.userInfo.name }}<br />{{ rental.userInfo.lastName }}
            </div>

            <div class="col-4 fs-8 align-middle text-right">
              <i *ngIf="rental.notes" class="fa fa-comment icon-comment" aria-hidden="true"
                style="margin-right: 2px"></i>
              <i *ngIf="rental.shopComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
              <br />
              <span class="badge badge-info" style="margin-right: 2px" *ngIf="rental.isDeliver">
                <i class="fa fa-truck"></i>
              </span>
              <div *ngIf="rental.amountPending > 0" class="badge bg-info" style="margin-right: 2px">
                <i class="fa fa-dollar-sign" style="padding: 0px 3px"></i>
              </div>
              <div *ngIf="rental.isPrepared == true; else elseBlock" class="badge badge-success">
                <i class="fa fa-check"></i>
              </div>
              <ng-template #elseBlock>
                <div class="badge badge-warning" style="margin-right: 3px">
                  <i class="fa fa-check"></i>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="container-fluid mb-3 text-secondary fs-5">
        <span>{{ dt }}</span>
      </div>

      <div id="checkedout" class="container" *ngIf="checkout.nextday.length > 0">
        <div *ngFor="let rental of checkout.nextday" class="text-center row" (click)="openModalCheckedOut(rental)"
          style="background: white" onmouseover="this.style.background='#E5E5E5';"
          onmouseout="this.style.background='white';">
          <div style="display: block; padding-top: 6px"></div>

          <div class="col-4 fs-8 align-middle text-left">
            <span class="badge badge-secondary rentalNumberBadge">
              {{ rental.rentalNumber }}</span><br />
            <span *ngIf="rental.datestart.getHours() == 0; else showHours">
              <span class="badge badge-secondary rentalNumberBadge">All Day</span></span>
            <ng-template #showHours><span class="badge badge-secondary rentalNumberBadge">
                {{
                getHourMinuetesFormat(rental.datestart, rental.dateend)
                }}</span></ng-template>
            <div style="padding: 4px; display: block"></div>
          </div>

          <div class="col-4 fs-8 align-middle text-center">
            {{ rental.userInfo.name }} {{ rental.userInfo.lastName }}
          </div>

          <div class="col-4 fs-8 align-right text-right">
            <i *ngIf="rental.notes" class="fa fa-comment icon-comment" aria-hidden="true" style="margin-right: 2px"></i>
            <i *ngIf="rental.shopComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
            <br />
            <span *ngIf="rental.isDeliver" class="badge badge-info" style="margin-right: 2px">
              <i class="fa fa-truck"></i>
            </span>
            <div class="badge bg-info" *ngIf="rental.amountPending > 0" style="margin-right: 2px">
              <i class="fa fa-dollar-sign" style="padding: 0px 3px"></i>
            </div>
            <div *ngIf="rental.isPrepared == true; else elseBlock" class="badge badge-success">
              <i class="fa fa-check"></i>
            </div>
            <ng-template #elseBlock>
              <div class="badge badge-warning" style="margin-right: 3px">
                <i class="fa fa-check"></i>
              </div>
            </ng-template>
          </div>

          <div class="fs-6 align-middle text-success text-right"></div>

          <div></div>
        </div>
      </div>

      <!-- Pending to checked out for tommorow -->
      <div class="container" *ngIf="checkout.nextday.length == 0">
        <div class="text-center row">
          <div class="bg-light">
            <div colspan="3">There are no orders to check out tomorrow</div>
          </div>
        </div>
      </div>
    </div>

    <!--Pending to Checked In-->
    <div class="col-md-4 mh-100">
      <div class="container-fluid m-2 pt-1 p-3 border border-1 rounded text-left">
        <span class="row fs-5 ms-1 mt-2" style="margin-left: 0px !important">Check In ({{
          checkincard?.numberpast +
          checkincard?.numbertoday +
          checkincard?.numbertomorrow
          }})</span>
        <div class="row mt-4">
          <div *ngIf="checkincard?.numbertomorrow > 0" class="col text-info fw-bold text-left">
            {{ checkincard?.numbertomorrow }} Upcoming ({{
            checkincard?.tomorrowproducts
            }})
          </div>
          <div *ngIf="checkincard?.numbertoday > 0" class="col text-success fw-bold text-center">
            {{ checkincard?.numbertoday }} Today ({{
            checkincard?.todayproducts
            }})
          </div>
          <div *ngIf="checkincard?.numberpast > 0" class="col text-danger fw-bold text-right">
            {{ checkincard?.numberpast }} Late ({{ checkincard?.pastoday }})
          </div>
        </div>
        <div *ngIf="
              checkincard?.numbertomorrow > 0 ||
                checkincard?.numbertoday > 0 ||
                checkincard?.numberpast > 0;
              else nodata
            " class="progress mb-1 bg-danger" style="height: 10px">
          <div class="progress-bar bg-info" role="progressbar" aria-label="Past Today"
            [ngStyle]="{ width: checkincard?.tomorrowproducts }" aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar bg-success" role="progressbar" aria-label="Today"
            [ngStyle]="{ width: checkincard?.todayproducts }" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <ng-template #nodata>
          <div class="progress mb-1 bg-secondary" style="height: 10px"></div>
        </ng-template>
      </div>

      <div class="container" *ngIf="
            rentalstocheckedin.length == 0 ||
            checkincard?.numberpast == rentalstocheckedin.length
          ">
        <div class="text-center">
          <div class="bg-light">
            <div colspan="3">There are no orders to check in</div>
          </div>
        </div>
      </div>

      <div id="checkedout" class="container" *ngIf="rentalstocheckedin.length > 0">
        <div *ngFor="let rental of rentalstocheckedin" class="row text-center" style="width: 100%"
          (click)="openModalCheckedIn(rental)" style="background: white" onmouseover="this.style.background='#E5E5E5';"
          onmouseout="this.style.background='white';">
          <ng-container *ngIf="(rental.dateend | date : 'YYYYMMdd') >= datenow3">
            <div style="display: block; padding-top: 6px"></div>

            <div class="col-4 fs-8 align-middle text-left">
              <span class="badge badge-secondary rentalNumberBadge text-light">
                {{ rental.rentalNumber }}</span><br />

              <span *ngIf="rental.datestart.getHours() == 0; else showHours">
                <span class="badge badge-secondary rentalNumberBadge">
                  All Day</span></span>
              <ng-template #showHours><span class="badge badge-secondary rentalNumberBadge">
                  {{
                  getHourMinuetesFormat(rental.datestart, rental.dateend)
                  }}</span></ng-template>
              <span class="badge badge-secondary rentalNumberBadge">
                {{ rental.dateend | date : "EEE MMM dd, yyyy" }}
              </span>

              <div style="padding: 4px; display: block"></div>
            </div>

            <div class="col-4 fs-8 align-middle text-center">
              {{ rental.userInfo.name }} {{ rental.userInfo.lastName }}
            </div>

            <div class="col-4 fs-8 align-middle text-right">
              <i *ngIf="rental.notes" class="fa fa-comment icon-comment" aria-hidden="true"
                style="margin-right: 2px"></i>
              <i *ngIf="rental.shopComments" class="fa fa-comment icon-shopComment" aria-hidden="true"></i>
              <br />
              <span *ngIf="rental.isDeliver" class="badge badge-info" style="margin-right: 2px">
                <i class="fa fa-truck"></i>
              </span>
              <div class="badge badge-success" style="margin-right: 2px">
                <i class="fa fa-check"></i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!----- Past Due Section ----->
<div class="row">
  <div class="col-md-6">
    <div id="bookingsID" class="card-body p-0 row scrollable">
      <div class="content table-responsive">
        <div class="container-fluid mt-3">
          <h2>Past Due</h2>

          <ng-container *ngIf="lateProductList.length == 0; else pastDueTable">
            <div class="row bg-light">
              <div class="col">
                <p class="text-center">There are no overdue rentals</p>
              </div>
            </div>
          </ng-container>

          <!-- Past due table -->
          <ng-template #pastDueTable>
            <div class="row" id="heyo" [style.background]="backgroundColor" [style.color]="textColor" style="font-weight: 600">
              <div class="col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Rental Date</p>
              </div>
              <div class="col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Name</p>
              </div>
              <div class="col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Status</p>
              </div>
            </div>
          </ng-template>
          <ng-container *ngIf="lateProductList.length !== 0">
            <div class="row tables-divs" *ngFor="let rental of lateProductList; let i = index" style="cursor: pointer"
              (click)="modalIdentifier(i, rental)">
              <div class="col-sm-3 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">
                  {{
                  rental.rentalType == "byHour"
                  ? (rental.dayEnd.toDate() | date : "h:mm a")
                  : (rental.dayEnd.toDate() | date : "MMM d")
                  }}
                </p>
              </div>

              <div class="col-sm-5 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">
                  <span class="badge badge-secondary rentalNumberBadge my-2"
                    style="margin: 0px !important; margin-right: 3px">
                    {{ rental.rentalNumber }}</span>{{ rental.userInfo?.name }} {{
                  rental.userInfo?.lastName
                  }}
                </p>
              </div>

              <div class="col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block"
                style="justify-content: center; align-items: center">
                <span *ngIf="rental.isPrepared && !rental.isCheckedOut" class="badge badge-success my-2"
                  style="margin-right: 5px">Ready to check Out</span>
                <span *ngIf="rental.isCheckedOut" class="badge badge-warning my-2" style="margin-right: 5px">Awaiting
                  check in</span>
                <span *ngIf="!rental.isPrepared && !rental.isCheckedOut" class="badge badge-primary my-2 align-center"
                  style="margin-right: 5px">Awaiting
                  Prep</span>
                <span *ngIf="rental.isCancelled" class="badge badge-danger my-2 text-left"
                  style="margin-right: 5px">Cancelled</span>
                <span class="fs-6 align-right text-danger"><i class="fas fa-clock text-right my-2"></i></span>
              </div>

              <!---Past due table mobile---->
              <div class="row list-mobile">
                <div class="col-12 p-1">
                  <ul class="nav flex-column">
                    <li class="d-block d-sm-none d-md-none">
                      <p class="my-2">
                        <b>Rental Date :</b>
                        {{
                        rental.rentalType == "byHour"
                        ? (rental.dayEnd.toDate() | date : "h:mm a")
                        : (rental.dayEnd.toDate() | date : "MMM d")
                        }}
                      </p>
                    </li>
                    <li class="d-block d-sm-none d-md-none">
                      <p class="my-2">
                        <b>Name :</b>{{ rental.userInfo?.name }}
                        {{
                        rental.userInfo?.lastName
                        }}<span class="badge badge-secondary rentalNumberBadge my-2" style="margin-left: 3px">
                          {{ rental.rentalNumber }}</span>
                      </p>
                    </li>
                    <li class="d-block d-sm-none d-md-none">
                      <b class="my-2">Status : </b>
                      <span *ngIf="rental.isPrepared && !rental.isCheckedOut" class="badge badge-success my-2"
                        style="margin-right: 5px">Ready to
                        check Out</span>
                      <span *ngIf="rental.isCheckedOut" class="badge badge-warning my-2"
                        style="margin-right: 5px">Awaiting check in</span>
                      <span *ngIf="!rental.isPrepared && !rental.isCheckedOut"
                        class="badge badge-primary my-2 align-center" style="margin-right: 5px">Awaiting Prep</span>
                      <span *ngIf="rental.isCancelled" class="badge badge-danger my-2 text-left"
                        style="margin-right: 5px">Cancelled</span>
                      <span class="fs-6 align-right text-danger"><i class="fas fa-clock text-right my-2"></i></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!--Checked In section-->
  <div class="col-md-6">
    <div id="bookingsID" class="card-body p-0 row scrollable">
      <div class="content table-responsive">
        <div class="container-fluid mt-3">
          <h2>Recently Checked-in (24 hours)</h2>

          <ng-container *ngIf="recentlyCompletedRentals.length == 0; else checkedInTable">
            <div class="row bg-light">
              <div class="col">
                <p class="text-center">
                  There are no recently checked in rentals
                </p>
              </div>
            </div>
          </ng-container>

          <!-- Checked In table -->
          <ng-template #checkedInTable>
            <div class="row" id="heyo" [style.background]="backgroundColor" [style.color]="textColor" style="font-weight: 600">
              <div class="col-sm-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Rental Date</p>
              </div>
              <div class="col-sm-5 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Name</p>
              </div>
              <div class="col-sm-3 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">Status</p>
              </div>
            </div>
          </ng-template>
          <ng-container *ngIf="recentlyCompletedRentals.length !== 0">
            <div class="row tables-divs" *ngFor="let rental of recentlyCompletedRentals; let i = index"
              style="cursor: pointer" (click)="modalIdentifier(i, rental)">
              <div class="col-sm-3 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">
                  {{
                  rental.rentalType == "byHour"
                  ? (rental.dayEnd.toDate() | date : "h:mm a")
                  : (rental.dayEnd.toDate() | date : "MMM d")
                  }}
                </p>
              </div>

              <div class="col-sm-6 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <p class="my-2 mx-3">
                  <span class="badge badge-secondary rentalNumberBadge my-2"
                    style="margin: 0px !important; margin-right: 3px">
                    {{ rental.rentalNumber }}</span>{{ rental.userInfo?.name }} {{
                  rental.userInfo?.lastName
                  }}
                </p>
              </div>

              <div class="col-sm-3 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <span class="badge badge-success my-2 align-middle">
                  Completed
                </span>
              </div>

              <!---Checked in table mobile---->
              <div class="row list-mobile">
                <div class="col-12 p-1">
                  <ul class="nav flex-column">
                    <li class="d-block d-sm-none d-md-none">
                      <p class="my-2">
                        <b>Rental Date :</b>
                        {{
                        rental.rentalType == "byHour"
                        ? (rental.dayEnd.toDate() | date : "h:mm a")
                        : (rental.dayEnd.toDate() | date : "MMM d")
                        }}
                      </p>
                    </li>
                    <li class="d-block d-sm-none d-md-none">
                      <p class="my-2">
                        <b>Name : </b>{{ rental.userInfo?.name }}
                        {{
                        rental.userInfo?.lastName
                        }}<span class="badge badge-secondary rentalNumberBadge my-2" style="margin-left: 3px">
                          {{ rental.rentalNumber }}</span>
                      </p>
                    </li>
                    <li class="d-block d-sm-none d-md-none">
                      <b class="my-2">Status : </b>
                      <span class="badge badge-success my-2" style="margin-right: 3px">Completed</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal to View Product-->
<div class="modal fade" id="ProductModal" aria-labelledby="ProductModal" aria-hidden="true" data-bs-backdrop="static"
  data-bs-keyboard="false" data-bs-focus="false">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="productdata">
      <div class="modal-header">
        <h5 class="modal-title fs-5 col-8" id="ProductModalLabel">Data Product</h5>
        <div class="col-3">
          <a href="partner/bookings/{{ productdata.id }}">View Booking</a>
        </div>
        <button type="button" class="btn-close col-1" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Rental Date:</div>
          <div class="col text-right">
            {{ productdata.dayStart }} {{ productdata.dayEnd }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Product Number:</div>
          <div class="col text-right">
            {{ productdata.productNumber }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6">
          <div class="col-7 text-left fw-bolder">
            Product Name:
          </div>
          <div class="col-5 text-right">
            {{ productdata.product }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Product Size:</div>
          <div class="col text-right">
            {{ productdata.size }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6" *ngIf="productdata.isDeliver">
          <div class="col text-left fw-bolder">Deliver</div>
          <div class="col text-right">
            <span class="badge bg-info badge-top">Deliver to {{
              productdata.deliverInformation.name
              }}</span>
          </div>
        </div>

        <!-- Comments -->
        <div class="align-items-start mt-2 fs-6 rounded border p-1"
          [ngClass]="productdata.notes ? 'comment' : 'bg-light'">
          <div class="row" [ngClass]="productdata.notes ? 'comments' : ''">
            <div class="col-3 text-left fw-bolder">Comments:</div>
            <ng-container *ngIf="productdata.notes">
              <div class="col-5 text-right info">
                <a href="mailto: {{ rentalaux?.userInfo.email }} ">{{
                  rentalaux?.userInfo.email
                  }}</a>
              </div>
              <div class="col-4 text-right info">
                <a href="tel: {{ rentalaux?.userInfo.phone }}">{{
                  rentalaux?.userInfo.phone
                  }}</a>
              </div>
            </ng-container>
          </div>
          <div class="row userInfo">
            <div class="col-12" *ngIf="productdata.notes !== undefined">
              <pre>{{ CommentsConversion(productdata.notes) }}</pre>
            </div>
          </div>
        </div>

        <!-- Shop Comments -->
        <div class="align-items-start mt-2 fs-6 rounded border p-1 shop-btn"
          [ngClass]="productdata.shopComments ? 'shop-comment' : 'bg-light'" (click)="
              openModalShopComment(productdata.shopComments, 'productdata');
              setInputFocus()
            ">
          <div class="row" [ngClass]="productdata.shopComments ? 'comments' : ''">
            <div class="col text-left fw-bolder">
              Shop Comments:

              <i class="fas fa-edit"></i>
            </div>
          </div>
          <div class="row userInfo">
            <div class="col-12" *ngIf="productdata.shopComments">
              <pre>{{ CommentsConversion(productdata.shopComments) }}</pre>
            </div>
          </div>
        </div>

        <!--addons-->
        <div class="align-items-start mt-2 fs-6 rounded border p-1 bg-light">
          <div class="row align-items-start mt-2 fs-6">
            <div class="col text-left fw-bolder">
              Addons
            </div>
            <div class="col text-right">
              None Selected
            </div>
          </div>

          <table class="table table-sm table-borderless text-center tabletext"
            *ngIf="productdata.widgetList.length > 0">
            <thead class="titleeditaddons">
              <tr>
                <th class="text-start">Title</th>
                <th class="text-center">Selection</th>
                <th class="text-end">Price</th>
              </tr>
            </thead>
            <tbody *ngFor="let widget of productdata.widgetList" style="margin-top: -0.1rem">
              <ng-container *ngIf="widget.widgetType == 'product'">
                <ng-container *ngIf="widget.selectedOption != undefined">
                  <tr>
                    <td class="text-start">{{ widget.element.name }}</td>
                    <td>{{ widget.selectedOption.size }}</td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ {{ widget.selectedOption.price }} USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.selectedOption == undefined">
                  <tr>
                    <td class="text-start">{{ widget.element.name }}</td>
                    <td><i>None</i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ 0 USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'quantity'">
                <tr>
                  <td class="text-start">{{ widget.element.label }}</td>
                  <td>{{ widget.element.inputValue }}</td>
                  <td class="text-end">
                    <p class="badge badge-light text-dark badgedarkgray">
                      $ {{ widget.element.price ? widget.element.price : "0" }} USD
                    </p>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'dropdown' || widget.widgetType == 'radios'">
                <ng-container *ngIf="widget.selectedOption != undefined">
                  <tr>
                    <td class="text-start">{{ widget.element.label }}</td>
                    <td>{{ widget.selectedOption.text }}</td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ {{ widget.selectedOption.price }} USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.selectedOption == undefined">
                  <tr>
                    <td class="text-start">{{ widget.element.label }}</td>
                    <td><i>None</i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ 0 USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'checkbox'">
                <tr>
                  <td class="text-start">{{ widget.element.label }}</td>
                  <td><i>{{ widget.element.inputValue == true ? "Selected" : "No Selected" }}</i></td>
                  <td class="text-end">
                    <p class="badge badge-light text-dark badgedarkgray">
                      $ {{ widget.element.price ? widget.element.price : "0" }} USD
                    </p>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'price'">
                <tr>
                  <td class="text-start">{{ widget.element.label }}</td>
                  <td><i>Price</i></td>
                  <td class="text-end">
                    <p class="badge badge-light text-dark badgedarkgray">
                      $ {{ widget.element.price ? widget.element.price : "0" }} USD
                    </p>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'heightInput'">
                <tr>
                  <td class="text-start">Height</td>
                  <td><i>
                      {{ widget.element.feetInput > 0 ? widget.element.feetInput : 0 }}ft
                      {{ widget.element.inchInput > 0 ? widget.element.inchInput : 0 }}in
                    </i></td>
                  <td class="text-end">
                    <p class="badge badge-light text-dark badgedarkgray">
                      $ 0 USD
                    </p>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="widget.widgetType == 'weightInput'">
                <tr>
                  <td class="text-start">Weight</td>
                  <td><i>
                      {{ widget.element.inputValue > 0 ? widget.element.inputValue : 0 }}lbs
                    </i></td>
                  <td class="text-end">
                    <p class="badge badge-light text-dark badgedarkgray">
                      $ 0 USD
                    </p>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div>
          <hr />
        </div>

        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Prepared:</div>
          <div class="col text-right">
            <div *ngIf="productdata.isPrepared == true; else notCheckedOut" class="badge badge-success">
              Ready to Check Out
            </div>
            <ng-template #notCheckedOut>
              <div class="badge badge-danger">Not Prepared for Check Out</div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" id="preparebtn" class="btn btn-success" (click)="preparedBike(id, position, productdata)"
          [disabled]="productdata['dataIsLoading']">
          Ready for Check Out
          <i class="fa fa-spinner fa-spin" *ngIf="productdata?.['dataIsLoading']"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Modal to Checked Out-->
<div class="modal fade" id="CheckedOutModal" aria-labelledby="CheckedOutModal" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false" data-bs-focus="false">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="checkedoutdata">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-3 col-3 col-sm-3">
            <h4 class="modal-title" id="exampleModalLabel">
              <b class="fs-6">Check Out</b>
            </h4>
          </div>
          <div class="col-lg-5 col-6 col-sm-5 fw-bold justify-content-center align-items-center d-flex">
            <fieldset *ngIf="
                  checkedoutdata.isReservation &&
                  checkedoutdata.amountPending > 0
                " class="border p-2 firsttotal">
              <legend class="float-none w-auto p-2 firsttotal">
                Amount Paid
              </legend>
              <p class="lasttotal">
                {{ checkedoutdata.amountPaid.toFixed(2) | currency }}
              </p>
            </fieldset>
            <fieldset *ngIf="
                  checkedoutdata.isReservation &&
                  checkedoutdata.amountPending > 0
                " class="border p-2 firsttotal">
              <legend class="float-none w-auto p-2 firsttotal">
                Pending Amount
              </legend>
              <p class="lasttotal">
                {{ checkedoutdata.amountPending.toFixed(2) | currency }}
              </p>
            </fieldset>
          </div>
          <div class="col-lg-3 col-2 col-sm-3">
            <a href="partner/bookings/{{ checkedoutdata.id }}">View Booking</a>
          </div>

          <div class="col-lg-1 col-1 col-sm-1 text-right align-items right">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <hr />
        <!--Data of the rental-->
        <div>
          <div class="row align-items-start mt-2 fs-6">
            <div class="col-4 text-left fw-bolder">Rental Date:</div>

            <div class="col-8 text-right">
              {{ checkedoutdata.datestart }} {{ checkedoutdata.dateend }}
            </div>
          </div>
          <div class="row align-items-start mt-2 fs-6">
            <div class="col text-left fw-bolder">Rental Number:</div>
            <div class="col text-right">
              {{ checkedoutdata.rentalNumber }}
            </div>
          </div>
          <div class="row align-items-start mt-2 fs-6">
            <div class="col text-left fw-bolder">Name of Renter:</div>
            <div class="col text-right">
              {{ checkedoutdata.name }}
            </div>
          </div>
          <div class="row align-items-start mt-2 fs-6" *ngIf="checkedoutdata.isDeliver">
            <div class="col text-left fw-bolder">Deliver</div>
            <div class="col text-right">
              <span class="badge bg-info">Delivered to
                {{ checkedoutdata.deliverInformation.name }}</span>
            </div>
          </div>

          <!-- Comments -->
          <div class="align-items-start mt-2 fs-6 rounded border p-1"
            [ngClass]="checkedoutdata.notes ? 'comment' : 'bg-light'">
            <div class="row" [ngClass]="checkedoutdata.notes ? 'comments' : ''">
              <div class="col-3 text-left fw-bolder">Comments:</div>
              <ng-container *ngIf="checkedoutdata.notes">
                <div class="col-5 text-right info">
                  <a href="mailto: {{ rentalUserInfo?.email }} ">{{
                    rentalUserInfo?.email
                    }}</a>
                </div>
                <div class="col-4 text-right info">
                  <a href="tel: {{ rentalUserInfo?.phone }}">{{
                    rentalUserInfo?.phone
                    }}</a>
                </div>
              </ng-container>
            </div>
            <div class="row userInfo">
              <div class="col-12" *ngIf="checkedoutdata.notes !== undefined">
                <pre>{{ CommentsConversion(checkedoutdata.notes) }}</pre>
              </div>
            </div>
          </div>

          <!-- Shop Comments -->
          <div class="align-items-start mt-2 fs-6 rounded border p-1 shop-btn" [ngClass]="
                checkedoutdata.shopComments ? 'shop-comment' : 'bg-light'
              " (click)="
                openModalShopComment(
                  checkedoutdata.shopComments,
                  'checkedoutdata'
                );
                setInputFocus()
              ">
            <div class="row" [ngClass]="checkedoutdata.shopComments ? 'comments' : ''">
              <div class="col text-left fw-bolder">
                Shop Comments:

                <i class="fas fa-edit"></i>
              </div>
            </div>
            <div class="row userInfo">
              <div class="col-12" *ngIf="checkedoutdata.shopComments">
                <pre>{{ CommentsConversion(checkedoutdata.shopComments) }}</pre>
              </div>
            </div>
          </div>

          <div class="row align-items-start justify-content-center mt-2 fs-6">
            <!--<div class="col-12 d-grid gap-2">
              <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddCharge"
                (click)="addPaymentMethod()">
                <i class="fas fa-check"></i> Add payment Method
              </button>
            </div> -->
            <div *ngIf="rental.amountPending > 0" class="col-sm-6 d-grid gap-2 mt-1">
              <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddCharge"
                (click)="completePayment(checkedoutdata)" *ngIf="rental.paymentID != 'session.payment_intent'">
                <i class="fas fa-check"></i> Complete Payment
              </button>
            </div>
            <div class="d-grid gap-2 mt-1" *ngIf="rental.paymentID != 'session.payment_intent'" [ngClass]="{
                  'col-sm-12': rental.amountPending == 0,
                  'col-sm-6': rental.amountPending != 0
                }">
              <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddSP"
                (click)="displayAddSecurityDeposit()">
                <i class="fas fa-plus-square me-2"></i> Add Security Deposit
              </button>
              <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnCancelSP"
                (click)="hideAddSecurity()">
                <i class="fas fa-ban"></i> Cancel Security Deposit
              </button>
            </div>
            <div class="container-fluid mt-1 border" id="accordionSecurity">
              <form class="mt-1 mb-2">
                <div class="mb-3">
                  <label for="txtDescription" class="form-label">Description</label>
                  <input type="text" class="form-control" id="txtDescriptionSecurity" value="Security Deposit"
                    disabled />
                </div>
                <div class="mb-3">
                  <label for="txtAmount" class="form-label">Amount</label>
                  <input type="text" class="form-control" id="txtAmountSecurity" (keyup)="writeTextSecurity()" />
                </div>
                <div id="SecurityText" class="form-text text-right"></div>
                <div class="d-grid gap-2">
                  <button type="button" class="btn btn-sm btn-primary" type="button" (click)="securityCharge()">
                    <i class="fas fa-money-check"></i> Add Security Deposit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div *ngIf="charges" class="align-items-start mt-2 ps-2 pe-2 fs-6">
            <div *ngFor="let charge of charges; let i = index" class="row bg-light border mb-1 rounded">
              <div class="col-6 text-left pt-2 pb-1 fw-bolder">
                {{ charge.type == "deposit" ? "Security Deposit" : "Charge" }}
              </div>
              <div class="col-6 text-right pt-2 pb-1">
                $ {{ charge.amount / 100 }}
              </div>
              <div class="col-3 text-left pt-1 pb-2">
                {{ charge.type == "refund" ? "Refund" : "Payment" }}
              </div>
              <div class="col-9 text-right pt-1 pb-2">
                <span *ngIf="
                      charge.status == 'succeeded' && charge.type == 'deposit'
                    " class="badge bg-success me-1">Confirmed</span>
                <span *ngIf="
                      charge.status == 'canceled' && charge.type == 'deposit'
                    " class="badge bg-danger me-1">Canceled</span>
                <span *ngIf="
                      charge.status == 'requires_capture' &&
                      charge.type == 'deposit'
                    " class="badge bg-danger me-1 hover" (click)="cancelPayment(charge, 'checkout')">Cancel</span>
                <span *ngIf="
                      charge.status == 'requires_capture' &&
                      charge.type == 'deposit'
                    " class="badge bg-success me-3 hover" (click)="confirmPayment(charge, 'checkout')">Confirm</span>
                <span class="badge bg-info bgdate">{{
                  formatDate(charge.created)
                  }}</span>
              </div>
            </div>
          </div>
          <!--<div class="row align-items-start mt-2 fs-6">
            <div class="col text-left fw-bolder">
              Waiver signed:
              <a *ngIf="
                    !(
                      checkedoutdata.waiver == null ||
                      checkedoutdata.waiver == undefined ||
                      checkedoutdata.waiver == []
                    )
                  " href="{{ checkedoutdata.waiver }}" target="_blank" class="badge badge-primary link-light"><i
                  class="fas fa-book"></i></a>
            </div>
            <ng-container *ngIf="!multipleWaivers && !smartwaiveractive">
              <div *ngIf="
                    !(
                      checkedoutdata.waiverSignature == null ||
                      checkedoutdata.waiverSignature == undefined ||
                      checkedoutdata.waiverSignature == ''
                    );
                    else waivernodigitally
                  " class="col text-right">
                <div *ngIf="
                      !(
                        checkedoutdata.waiver == null ||
                        checkedoutdata.waiver == undefined ||
                        checkedoutdata.waiver == []
                      );
                      else waivernotupload
                    ">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-primary text-light"
                    *ngIf="!multipleWaivers">
                    Waiver Digitally Signed and Uploaded
                  </a>
                  <br />
                  <b>{{ checkedoutdata.waiverSignature }}</b>
                </div>
              </div>
              <ng-template #waivernotupload>
                <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-success text-light"
                  *ngIf="!multipleWaivers">
                  Waiver Digitally Signed
                </a>
                <br />
                <b>{{ checkedoutdata.waiverSignature }}</b>
              </ng-template>
              <ng-template #waivernodigitally>
                <div id="tooltip" *ngIf="
                      !(
                        checkedoutdata.waiver == null ||
                        checkedoutdata.waiver == undefined ||
                        checkedoutdata.waiver == []
                      );
                      else pendingwaiver
                    " class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" data-toggle="tooltip"
                    title="Sign the Digital Waiver" class="badge badge-success text-light">Waiver
                    Uploaded</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                  <br />
                  <b>{{ checkedoutdata.waiverSignature }}</b>
                </div>
              </ng-template>
              <ng-template #pendingwaiver>
                <div id="tooltip" class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-danger text-white"
                    data-toggle="tooltip" title="Sign the Digital Waiver" style="background-color: orangered">Waiver Not
                    Signed</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                </div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="multipleWaivers && !smartwaiveractive">
              <div *ngIf="checkedoutdata.waiversSigned; else waivernodigitally" class="col text-right">
                <div *ngIf="
                      !(
                        checkedoutdata.waiver == null ||
                        checkedoutdata.waiver == undefined ||
                        checkedoutdata.waiver == []
                      );
                      else waivernotupload
                    ">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-primary text-light"
                    *ngIf="multipleWaivers">
                    Waiver {{ waiversSigned }}/{{ waiversArray.length }}
                    Digitally Signed and Uploaded
                  </a>
                  <br />
                </div>
              </div>
              <ng-template #waivernotupload>
                <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-success text-light"
                  *ngIf="multipleWaivers">
                  Waiver {{ waiversSigned }}/{{ waiversArray.length }} Digitally
                  Signed
                </a>
                <br />
              </ng-template>
              <ng-template #waivernodigitally>
                <div id="tooltip" *ngIf="checkedoutdata.waiversSigned; else pendingwaiver" class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" data-toggle="tooltip"
                    title="Sign the Digital Waiver" class="badge badge-success text-light">Waiver
                    Uploaded</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                  <br />
                </div>
              </ng-template>
              <ng-template #pendingwaiver>
                <div id="tooltip" class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-danger text-white"
                    data-toggle="tooltip" title="Sign the Digital Waiver" style="background-color: orangered">Waiver Not
                    Signed</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                </div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="smartwaiveractive">
              <div *ngIf="smartwaiversigned; else waivernodigitally" class="col text-right">
                <div *ngIf="
                      !(
                        checkedoutdata.waiver == null ||
                        checkedoutdata.waiver == undefined ||
                        checkedoutdata.waiver == []
                      );
                      else waivernotupload
                    ">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-primary text-light"
                    *ngIf="!multipleWaivers">
                    SmartWaiver Digitally Signed and Uploaded
                  </a>
                  <br />
                  <b>{{ smartwaiverdata[0]?.waiver.firstName }}
                    {{ smartwaiverdata[0]?.waiver.middleName }}
                    {{ smartwaiverdata[0]?.waiver.lastName }}</b>
                </div>
              </div>
              <ng-template #waivernotupload>
                <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-success text-light"
                  *ngIf="!multipleWaivers">
                  SmartWaiver Digitally Signed
                </a>
                <br />
                <b>{{ smartwaiverdata[0]?.waiver.firstName }}
                  {{ smartwaiverdata[0]?.waiver.middleName }}
                  {{ smartwaiverdata[0]?.waiver.lastName }}</b>
              </ng-template>
              <ng-template #waivernodigitally>
                <div id="tooltip" *ngIf="
                      !(
                        checkedoutdata.waiver == null ||
                        checkedoutdata.waiver == undefined ||
                        checkedoutdata.waiver == []
                      );
                      else pendingwaiver
                    " class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" data-toggle="tooltip"
                    title="Sign the Digital Waiver" class="badge badge-success text-light">Waiver
                    Uploaded</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                  <br />
                </div>
              </ng-template>
              <ng-template #pendingwaiver>
                <div id="tooltip" class="col text-right">
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" class="badge badge-danger text-white"
                    data-toggle="tooltip" title="Sign the Digital Waiver"
                    style="background-color: orangered">SmartWaiver
                    Not Signed</a>
                  <a href="/waiver/{{ checkedoutdata.id }}" target="_blank" id="tooltiptext">
                    <div class="textinside">Sign the Digital Waiver</div>
                  </a>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <div class="col-12 mt-4">
            <input class="form-control" type="file" id="id{{ checkedoutdata.id }}" accept=".pdf"
              (change)="uploadPDFWaiver(id, $event)" />
            <div *ngIf="
                  checkedoutdata.waiver == null ||
                  checkedoutdata.waiver == undefined ||
                  checkedoutdata.waiver == []
                ">
              <div class="d-grid gap-2">
                <label class="btn btn-primary btn-sm" for="id{{ checkedoutdata.id }}">
                  <i class="fas fa-upload"></i> Upload Waiver Signed
                </label>
              </div>
            </div>
            <ng-template #notUpload></ng-template>
          </div>-->
        </div>
        <hr />
        <!--Data of products-->
        <div *ngFor="let item of checkedoutdata.items; let i = index" class="bg-light p-2 rounded border">
          <div class="row align-items-start mt-2 ps-2 pe-2 fs-6">
            <div class="col text-left fw-bolder">Product:</div>
            <div class="col text-right">
              <p class="badge badge-info text-light">
                {{ item.productName }}
              </p>
            </div>
          </div>
          <div class="row align-items-start ps-2 pe-2 fs-6">
            <div class="col text-left fw-bolder">Product Size:</div>
            <div class="col text-right">
              <p class="badge badge-info text-light">
                {{ item.productSize }}
              </p>
            </div>
          </div>
          <div class="row align-items-start ps-2 pe-2 fs-6">
            <div class="col text-left fw-bolder">Product Number:</div>
            <div class="col text-right">
              <p class="badge badge-info text-light">
                <a style="color: white" (click)="closeOpenModals()"
                  [routerLink]="['/partner/inventory/', item.productId]">#{{
                  item.productNumber ? item.productNumber : "0"
                  }}</a>
              </p>
            </div>
          </div>

          <div class="align-items-start m-2 mb-3 fs-6 rounded border p-1 bg-light">
            <div class="row align-items-start ps-2 pe-2 fs-6">
              <div class="col text-left">
                <b>Addons:</b>
              </div>
            </div>

            <table class="table table-sm table-borderless text-center tabletext" *ngIf="item.widgetList.length > 0">
              <thead class="titleeditaddons">
                <tr>
                  <th class="text-start">Title</th>
                  <th>Selection</th>
                  <th class="text-end">Price</th>
                </tr>
              </thead>
              <tbody *ngFor="let widget of item.widgetList" style="margin-top: -0.5rem">
                <ng-container *ngIf="widget.widgetType == 'product'">
                  <ng-container *ngIf="widget.selectedOption != undefined">
                    <tr>
                      <td class="text-start">{{ widget.element.name }}</td>
                      <td>{{ widget.selectedOption.size }}</td>
                      <td class="text-end">
                        <p class="badge badge-light text-dark badgedarkgray">
                          $ {{ widget.selectedOption.price }} USD
                        </p>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="widget.selectedOption == undefined">
                    <tr>
                      <td class="text-start">{{ widget.element.name }}</td>
                      <td><i>None</i></td>
                      <td class="text-end">
                        <p class="badge badge-light text-dark badgedarkgray">
                          $ 0 USD
                        </p>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'quantity'">
                  <tr>
                    <td class="text-start">{{ widget.element.label }}</td>
                    <td>{{ widget.element.inputValue }}</td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ {{ widget.element.price ? widget.element.price : "0" }} USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'dropdown' || widget.widgetType == 'radios'">
                  <ng-container *ngIf="widget.selectedOption != undefined">
                    <tr>
                      <td class="text-start">{{ widget.element.label }}</td>
                      <td>{{ widget.selectedOption.text }}</td>
                      <td class="text-end">
                        <p class="badge badge-light text-dark badgedarkgray">
                          $ {{ widget.selectedOption.price }} USD
                        </p>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="widget.selectedOption == undefined">
                    <tr>
                      <td class="text-start">{{ widget.element.label }}</td>
                      <td><i>None</i></td>
                      <td class="text-end">
                        <p class="badge badge-light text-dark badgedarkgray">
                          $ 0 USD
                        </p>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'checkbox'">
                  <tr>
                    <td class="text-start">{{ widget.element.label }}</td>
                    <td><i>{{ widget.element.inputValue == true ? "Selected" : "No Selected" }}</i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ {{ widget.element.price ? widget.element.price : "0" }} USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'price'">
                  <tr>
                    <td class="text-start">{{ widget.element.label }}</td>
                    <td><i>Price</i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ {{ widget.element.price ? widget.element.price : "0" }} USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'heightInput'">
                  <tr>
                    <td class="text-start">Height</td>
                    <td><i>
                        {{ widget.element.feetInput > 0 ? widget.element.feetInput : 0 }}ft
                        {{ widget.element.inchInput > 0 ? widget.element.inchInput : 0 }}in
                      </i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ 0 USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="widget.widgetType == 'weightInput'">
                  <tr>
                    <td class="text-start">Weight</td>
                    <td><i>
                        {{ widget.element.inputValue > 0 ? widget.element.inputValue : 0 }}lbs
                      </i></td>
                    <td class="text-end">
                      <p class="badge badge-light text-dark badgedarkgray">
                        $ 0 USD
                      </p>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div *ngIf="!item.isPrepared; else preparedProduct" class="text-right">
            <button type="button" id="preparebtn" class="btn btn-success" (click)="preparedBike(id, i, item)">
              Product ready for check out
            </button>
          </div>
          <ng-template #preparedProduct>
            <div class="text-right">
              <button type="button" id="preparebtn" class="btn btn-outline-secondary" disabled>
                Product is prepared
              </button>
            </div>
          </ng-template>

          <div class="col-12 mt-4">
            <div class="d-grid gap-2">
              <button type="button" class="btn btn-primary" (click)="compressImageCo(id, item, i, 'Check Out')">
                Upload Check Out Image
              </button>
            </div>
          </div>
          <div class="col-12 mt-1">
            Check Out Images
            <br />
            <ng-container *ngIf="item.imagesco == undefined || item.imagesco.length == 0">
              <span class="text-secondary">No Check Out Images</span>
            </ng-container>
            <div class="container-fluid">
              <div class="styleimgthumb justify-content-center align-items-center"
                *ngFor="let i of item.imagesco; let j = index">
                <ng-container *ngIf="
                      i.urlthumbnail == '' || i.urlthumbnail == undefined;
                      else imageco
                    ">
                  <img src="../../../assets/images/notAvailable.png" class="imginsidediv"
                    (click)="openModalImg(i.url)" />
                </ng-container>
                <ng-template #imageco>
                  <img src="{{ i.url }}" class="imginsidediv" (click)="openModalImg(i.url)" />
                </ng-template>
              </div>
              <!--<a href="{{i.url}}" target="_blank">Image #{{j+1}}</a>-->
            </div>
          </div>
          <div class="col-12 mt-1">
            Check In Images
            <br />
            <ng-container *ngIf="item.imagesci == undefined || item.imagesci.length == 0">
              <span class="text-secondary">No Check In Images</span>
            </ng-container>
            <div class="container-fluid">
              <div class="styleimgthumb justify-content-center align-items-center"
                *ngFor="let i of item.imagesci; let j = index">
                <ng-container *ngIf="
                      i.urlthumbnail == '' || i.urlthumbnail == undefined;
                      else imageco
                    ">
                  <img src="../../../assets/images/notAvailable.png" class="imginsidediv"
                    (click)="openModalImg(i.url)" />
                </ng-container>
                <ng-template #imageco>
                  <img src="{{ i.url }}" class="imginsidediv" (click)="openModalImg(i.url)" />
                </ng-template>
              </div>
              <!--<a href="{{i.url}}" target="_blank">Image #{{j+1}}</a>-->
            </div>
          </div>
          <div class="row align-items-start mt-2 fs-6">
            <div *ngIf="item.isPrepared == false || item.isPrepared == undefined; else isPrepared" class="d-grid gap-2">
              <button class="btn btn-danger" type="button" disabled>
                Product Not Prepared
              </button>
            </div>
            <ng-template #isPrepared>
              <div *ngIf="item.isCheckedOut == false || item.isCheckedOut == undefined" class="col-6 me-2">
                <button class="col-12 ms-2 btn btn-outline-secondary" id="checkedoutbtn" type="button"
                  (click)="cancelPrepared(id, i)">
                  Revert
                </button>
              </div>
              <div *ngIf="item.isCheckedOut == false || item.isCheckedOut == undefined; else isCheckedOut"
                class="col me-2">
                <button class="col btn btn-success" id="checkedoutbtn" type="button"
                  [disabled]="checkedoutdata.items[i]?.['dataIsLoading']" (click)="checkDeposits(id, i, 'checkout')">
                  Product Checked Out
                  <i class="fa fa-spinner fa-spin" *ngIf="checkedoutdata.items[i]?.['dataIsLoading']"></i>
                </button>
              </div>
            </ng-template>
            <ng-template #isCheckedOut>
              <div class="col-6 me-2">
                <button class="col-12 ms-2 btn btn-outline-secondary" id="checkedoutbtn" type="button"
                  (click)="cancelCheckOut(id, i)">
                  Revert
                </button>
              </div>
              <div class="col me-2">
                <button class="col btn btn-success" type="button" disabled>
                  The Product has been Checked Out
                  <i class="fa fa-spinner fa-spin" *ngIf="checkedoutdata.items[i]?.['dataIsLoading']"></i>
                </button>
              </div>
            </ng-template>
          </div>
          <hr />
        </div>

      </div>
    </div>
  </div>
</div>

<!--Modal to Checked In-->
<div class="modal fade" id="CheckedInModal" aria-labelledby="CheckedInModal" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false" data-bs-focus="false">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="checkedindata">
      <div class="modal-header">
        <h5 class="modal-title fs-5 col-8" id="CheckedInModalLabel">Check In</h5>
        <div class="col-3">
          <a href="partner/bookings/{{ checkedindata.id }}">View Booking</a>
        </div>
        <button type="button" class="btn-close col-1" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row align-items-start mt-2 fs-6">
          <div class="col-4 text-left fw-bolder">Rental Date:</div>
          <div class="col-8 text-right">
            {{ checkedindata.datestart }} {{ checkedindata.dateend }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Rental Number:</div>
          <div class="col text-right">
            {{ checkedindata.rentalNumber }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6">
          <div class="col text-left fw-bolder">Name of Renter:</div>
          <div class="col text-right">
            {{ checkedindata.name }}
          </div>
        </div>
        <div class="row align-items-start mt-2 fs-6" *ngIf="checkedindata.isDeliver">
          <div class="col text-left fw-bolder">Deliver</div>
          <div class="col text-right">
            <span class="badge bg-info">Delivered to {{ checkedindata.deliverInformation.name }}</span>
          </div>
        </div>

        <!-- Comments -->
        <div class="align-items-start mt-2 fs-6 rounded border p-1"
          [ngClass]="checkedindata.notes ? 'comment' : 'bg-light'">
          <div class="row" [ngClass]="checkedindata.notes ? 'comments' : ''">
            <div class="col-3 text-left fw-bolder">Comments:</div>
            <ng-container *ngIf="checkedindata.notes">
              <div class="col-5 text-right info">
                <a href="mailto: {{ rentalUserInfo?.email }} ">{{
                  rentalUserInfo?.email
                  }}</a>
              </div>
              <div class="col-4 text-right info">
                <a href="tel: {{ rentalUserInfo?.phone }}">{{
                  rentalUserInfo?.phone
                  }}</a>
              </div>
            </ng-container>
          </div>
          <div class="row userInfo">
            <div class="col-12" *ngIf="checkedindata.notes !== undefined">
              <pre>{{ CommentsConversion(checkedindata.notes) }}</pre>
            </div>
          </div>
        </div>

        <!-- Shop Comments -->
        <div class="align-items-start mt-2 fs-6 rounded border p-1 shop-btn" [ngClass]="
              checkedindata.shopComments ? 'shop-comment' : 'bg-light'
            " (click)="
              openModalShopComment(
                checkedindata.shopComments,
                'checkedindata'
              );
              setInputFocus()
            ">
          <div class="row" [ngClass]="checkedindata.shopComments ? 'comments' : ''">
            <div class="col text-left fw-bolder">
              Shop Comments:

              <i class="fas fa-edit"></i>
            </div>
          </div>
          <div class="row userInfo">
            <div class="col-12" *ngIf="checkedindata.shopComments">
              <pre>{{ CommentsConversion(checkedindata.shopComments) }}</pre>
            </div>
          </div>
        </div>

        <div *ngIf="charges" class="align-items-start mt-2 ps-2 pe-2 fs-6">
          <div *ngFor="let charge of charges; let i = index" class="row bg-light border mb-1 rounded">
            <div class="col-6 text-left pt-2 pb-1 fw-bolder">
              {{ charge.type == "deposit" ? "Security Deposit" : "Charge" }}
            </div>
            <div class="col-6 text-right pt-2 pb-1">
              $ {{ charge.amount / 100 }}
            </div>
            <div class="col-3 text-left pt-1 pb-2">
              {{ charge.type == "refund" ? "Refund" : "Payment" }}
            </div>
            <div class="col-9 text-right pt-1 pb-2">
              <span *ngIf="charge.status == 'succeeded' && charge.type == 'deposit'"
                class="badge bg-success me-1">Confirmed</span>
              <span *ngIf="charge.status == 'canceled' && charge.type == 'deposit'"
                class="badge bg-danger me-1">Canceled</span>
              <span *ngIf="
                    charge.status == 'requires_capture' &&
                    charge.type == 'deposit'
                  " class="badge bg-danger me-1 hover" (click)="cancelPayment(charge, 'checkin')">Cancel</span>
              <span *ngIf="
                    charge.status == 'requires_capture' &&
                    charge.type == 'deposit'
                  " class="badge bg-success me-3 hover" (click)="confirmPayment(charge, 'checkin')">Confirm</span>
              <span class="badge bg-info bgdate">{{
                formatDate(charge.created)
                }}</span>
            </div>
          </div>
        </div>
        <hr />
        <form [formGroup]="maintenanceForm">
          <ng-container formArrayName="products">
            <div *ngFor="let item of checkedindata.items; let i = index" [formGroupName]="i"
              class="bg-light p-2 rounded border">
              <div class="row align-items-start ps-2 pe-2 mt-2 fs-6">
                <div class="col text-left fw-bolder">Product:</div>
                <div class="col text-right">
                  <p class="badge badge-info text-light">
                    {{ item.productName }}
                  </p>
                </div>
              </div>
              <div class="row align-items-start ps-2 pe-2 fs-6">
                <div class="col text-left fw-bolder">Product Size:</div>
                <div class="col text-right">
                  <p class="badge badge-info text-light">
                    {{ item.productSize }}
                  </p>
                </div>
              </div>

              <div class="row align-items-start ps-2 pe-2 fs-6">
                <div class="col text-left fw-bolder">Product Number:</div>
                <div class="col text-right">
                  <p class="badge badge-info text-light">
                    <a style="color: white" (click)="closeOpenModals()"
                      [routerLink]="['/partner/inventory/', item.productId]">#{{
                      item.productNumber ? item.productNumber : "0"
                      }}</a>
                  </p>
                </div>
              </div>

              <div class="align-items-start m-2 mb-3 fs-6 rounded border p-1 bg-light">
                <div class="row align-items-start ps-2 pe-2 fs-6">
                  <div class="col text-left">
                    <b>Addons:</b>
                  </div>
                </div>

                <table class="table table-sm table-borderless text-center tabletext" *ngIf="item.widgetList.length > 0">
                  <thead class="titleeditaddons">
                    <tr>
                      <th class="text-start">Title</th>
                      <th>Selection</th>
                      <th class="text-end">Price</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let widget of item.widgetList" style="margin-top: -0.5rem">
                    <ng-container *ngIf="widget.widgetType == 'product'">
                      <ng-container *ngIf="widget.selectedOption != undefined">
                        <tr>
                          <td class="text-start">{{ widget.element.name }}</td>
                          <td>{{ widget.selectedOption.size }}</td>
                          <td class="text-end">
                            <p class="badge badge-light text-dark badgedarkgray">
                              $ {{ widget.selectedOption.price }} USD
                            </p>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="widget.selectedOption == undefined">
                        <tr>
                          <td class="text-start">{{ widget.element.name }}</td>
                          <td><i>None</i></td>
                          <td class="text-end">
                            <p class="badge badge-light text-dark badgedarkgray">
                              $ 0 USD
                            </p>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'quantity'">
                      <tr>
                        <td class="text-start">{{ widget.element.label }}</td>
                        <td>{{ widget.element.inputValue }}</td>
                        <td class="text-end">
                          <p class="badge badge-light text-dark badgedarkgray">
                            $ {{ widget.element.price ? widget.element.price : "0" }} USD
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'dropdown' || widget.widgetType == 'radios'">
                      <ng-container *ngIf="widget.selectedOption != undefined">
                        <tr>
                          <td class="text-start">{{ widget.element.label }}</td>
                          <td>{{ widget.selectedOption.text }}</td>
                          <td class="text-end">
                            <p class="badge badge-light text-dark badgedarkgray">
                              $ {{ widget.selectedOption.price }} USD
                            </p>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="widget.selectedOption == undefined">
                        <tr>
                          <td class="text-start">{{ widget.element.label }}</td>
                          <td><i>None</i></td>
                          <td class="text-end">
                            <p class="badge badge-light text-dark badgedarkgray">
                              $ 0 USD
                            </p>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'checkbox'">
                      <tr>
                        <td class="text-start">{{ widget.element.label }}</td>
                        <td><i>{{ widget.element.inputValue == true ? "Selected" : "No Selected" }}</i></td>
                        <td class="text-end">
                          <p class="badge badge-light text-dark badgedarkgray">
                            $ {{ widget.element.price ? widget.element.price : "0" }} USD
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'price'">
                      <tr>
                        <td class="text-start">{{ widget.element.label }}</td>
                        <td><i>Price</i></td>
                        <td class="text-end">
                          <p class="badge badge-light text-dark badgedarkgray">
                            $ {{ widget.element.price ? widget.element.price : "0" }} USD
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'heightInput'">
                      <tr>
                        <td class="text-start">Height</td>
                        <td><i>
                            {{ widget.element.feetInput > 0 ? widget.element.feetInput : 0 }}ft
                            {{ widget.element.inchInput > 0 ? widget.element.inchInput : 0 }}in
                          </i></td>
                        <td class="text-end">
                          <p class="badge badge-light text-dark badgedarkgray">
                            $ 0 USD
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="widget.widgetType == 'weightInput'">
                      <tr>
                        <td class="text-start">Weight</td>
                        <td><i>
                            {{ widget.element.inputValue > 0 ? widget.element.inputValue : 0 }}lbs
                          </i></td>
                        <td class="text-end">
                          <p class="badge badge-light text-dark badgedarkgray">
                            $ 0 USD
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <ng-container *ngIf="item.isCheckedOut">
                <div *ngIf="item.isCheckedIn == false || item.isCheckedIn == undefined">
                  <div class="row align-items-start mt-2 fs-6">
                    <div class="col text-left fw-bolder">
                      Needs Maintenance?:
                    </div>
                    <div class="col text-right">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="radioSelect{{ i }}" id="radioSelect{{ i }}"
                          value="yes" (click)="enableForm(i, item); resetForm(i, item)" [checked]="item.isMaintenance" />
                        <label class="form-check-label" for="yesradio">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="radioSelect{{ i }}" id="radioSelect{{ i }}"
                          value="no" (click)="disabledForm(i)" />
                        <label class="form-check-label" for="noradio">No</label>
                      </div>
                    </div>
                  </div>
                  <div id="repeatedNumber{{ i }}" class="errormssg">
                    <i class="fas fa-exclamation-circle"></i> You need to answer
                    this question
                  </div>
                  <div id="disabledform{{ i }}" class="bg-white border border-1 not-displayed"
                    [ngClass]="{ displayed: item.isMaintenance }">
                    <div class="row align-items-start mt-2 fs-6">
                      <div class="col text-left fw-bolder ml-2">
                        Type of Maintenance:
                      </div>
                    </div>
                    <div class="row align-items-start mt-2 fs-6" *ngIf="maintenanceForm.get(['products', i])">
                      <div class="col text-left ml-2">
                        <div class="form-check">
                          <input class="form-check-input" [ngClass]="{
                                'is-invalid':
                                  maintenanceForm.get(['products', i]).errors &&
                                  maintenanceSubmitted
                              }" [checked]="item.isCleaning" formControlName="cleaningCheck" (change)="
                                onCheckBoxChange($event, 'cleaningCheck', i)
                              " type="checkbox" value="Cleaning" name="cleaningCheck{{ i }}"
                            id="cleaningCheck{{ i }}" />
                          <label class="form-check-label" for="cleaningCheck{{ i }}">
                            Cleaning
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" [ngClass]="{
                                'is-invalid':
                                  maintenanceForm.get(['products', i]).errors &&
                                  maintenanceSubmitted
                              }" [checked]="
                                maintenanceForm.get([
                                  'products',
                                  i,
                                  'tuneupCheck'
                                ]).value
                              " formControlName="tuneupCheck" (change)="
                                onCheckBoxChange($event, 'tuneupCheck', i)
                              " type="checkbox" value="TuneUp" name="tuneupCheck{{ i }}" id="tuneupCheck{{ i }}" />
                          <label class="form-check-label" for="tuneupCheck{{ i }}">
                            Tune-Up
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" [ngClass]="{
                                'is-invalid':
                                  maintenanceForm.get(['products', i]).errors &&
                                  maintenanceSubmitted
                              }" [checked]="item.isDamaged" formControlName="damagedCheck" (change)="
                                onCheckBoxChange($event, 'damagedCheck', i)
                              " type="checkbox" value="Damaged" name="damagedcheck{{ i }}" id="damagedcheck{{ i }}" />
                          <label class="form-check-label" for="damagedcheck{{ i }}">
                            Damaged
                          </label>
                        </div>
                      </div>
                      <div class="col text-right-ml2">
                        <input class="form-check-input" type="checkbox" value="Unavailable"
                          name="unavailableCheck{{ i }}" id="unavailableCheck{{ i }}" />
                        <label class="form-check-label" for="unavailableCheck{{ i }}">
                          Set as Unavailable
                        </label>
                      </div>

                      <div class="text-danger mt-2 fs-6" *ngIf="
                            maintenanceForm.get(['products', i]).errors &&
                            maintenanceSubmitted
                          ">
                        At least one checkbox is required.
                      </div>
                    </div>
                    <div class="row align-items-start mt-2 fs-6" *ngIf="maintenanceForm.get(['products', i])">
                      <div class="col text-left">
                        <textarea class="form-control" [ngClass]="{
                              'is-invalid':
                                maintenanceForm.get([
                                  'products',
                                  i,
                                  'description'
                                ]).errors && maintenanceSubmitted
                            }" formControlName="description" id="description{{ i }}" rows="3" name="description{{ i }}"
                          placeholder="Reason for maintenance..." required></textarea>
                      </div>
                      <div class="text-danger mt-2 fs-6" *ngIf="
                            maintenanceForm.get(['products', i, 'description'])
                              .errors && maintenanceSubmitted
                          ">
                        Please add a description
                      </div>
                    </div>

                    <!-- Add a button or input[type="submit"] to submit the form -->

                    <!--<div class="bg-light border">
                      <div class="row align-items-start mt-2 p-1 fs-6">
                        <div class="d-grid gap-2 p-4">
                          <button class="btn btn-sm btn-primary" id="addBtn{{ i }}"
                            (click)="enableDamageCharge(i, 'add')">
                            Add a charge for this maintenance
                          </button>
                          <button class="btn btn-sm btn-secondary hide" id="cancelBtn{{ i }}"
                            (click)="enableDamageCharge(i, 'cancel')">
                            Cancel Add Charges
                          </button>
                        </div>
                      </div>
                      <div class="row align-items-start mt-2 fs-6 displayNone" id="formtocharge{{ i }}">
                        <div class="col">
                          <form class="row g-3 p-2">
                            <div class="col-mb-12 mb-3">
                              <label class="form-label">Description <b style="color: red">*</b></label>
                              <input type="text" class="form-control" id="descriptioncharge{{ i }}"
                                (keyup)="validate(i, 'desc')" />
                              <span class="text-danger hide" id="descriptionerror{{ i }}"><i
                                  class="fas fa-info-circle"></i> This field
                                can not be empty</span>
                            </div>
                            <div class="col-mb-12 mb-3">
                              <label class="form-label">Amount <b style="color: red">*</b></label>
                              <input type="text" class="form-control" id="amountcharge{{ i }}"
                                (keyup)="validate(i, 'amt')"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
                              <span class="text-danger hide" id="amounterror{{ i }}"><i class="fas fa-info-circle"></i>
                                This field
                                can not be empty</span>
                              <div id="ChargeText{{ i }}" class="form-text text-right">
                              </div>
                            </div>
                            <div class="col-mb-12 mb-3 form-check justify-content-end align-items-end text-right">
                              <button class="btn btn-secondary" [disabled]="!validateCharge"
                                (click)="createNewChargeCard(i)">
                                Charge to a new Card
                              </button>
                              <button class="btn btn-primary ms-2" [disabled]="!validateCharge"
                                (click)="createMaintenanceCharge(i)">
                                Charge
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>
                <div class="col-12 mt-4">
                  <div class="d-grid gap-2">
                    <button type="button" class="btn btn-primary" (click)="compressImageCo(id, item, i, 'Check In')">
                      Upload Check In Image
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-1">
                  Check Out Images
                  <br />
                  <ng-container *ngIf="item.imagesco == undefined || item.imagesco.length == 0">
                    <span class="text-secondary">No Check Out Images</span>
                  </ng-container>
                  <div class="container-fluid">
                    <div class="styleimgthumb justify-content-center align-items-center"
                      *ngFor="let i of item.imagesco; let j = index">
                      <ng-container *ngIf="
                            i.urlthumbnail == '' || i.urlthumbnail == undefined;
                            else imageco
                          ">
                        <img src="../../../assets/images/notAvailable.png" class="imginsidediv" id="imgco{{ j }}"
                          (click)="openModalImg(i.url)" />
                      </ng-container>
                      <ng-template #imageco>
                        <img src="{{ i.url }}" class="imginsidediv" (click)="openModalImg(i.url)" />
                      </ng-template>
                    </div>
                    <!--<a href="{{i.url}}" target="_blank">Image #{{j+1}}</a>-->
                  </div>
                </div>
                <div class="col-12 mt-1">
                  Check In Images
                  <br />
                  <ng-container *ngIf="item.imagesci == undefined || item.imagesci.length == 0">
                    <span class="text-secondary">No Check In Images</span>
                  </ng-container>
                  <div class="container-fluid">
                    <div class="styleimgthumb justify-content-center align-items-center"
                      *ngFor="let i of item.imagesci; let j = index">
                      <ng-container *ngIf="
                            i.urlthumbnail == '' || i.urlthumbnail == undefined;
                            else imageco
                          ">
                        <img src="../../../assets/images/notAvailable.png" class="imginsidediv" id="imgci{{ j }}"
                          (click)="openModalImg(i.url)" />
                      </ng-container>
                      <ng-template #imageco>
                        <img src="{{ i.url }}" class="imginsidediv withimage" (click)="openModalImg(i.url)" />
                      </ng-template>
                    </div>
                    <!--<a href="{{i.url}}" target="_blank">Image #{{j+1}}</a>-->
                  </div>
                </div>
              </ng-container>

              <div class="row align-items-start mt-2 fs-6">
                <ng-container *ngIf="item.isCheckedOut; else checkedOutRevert">
                  <div *ngIf="!item.isCheckedIn || item.isCheckedIn == undefined" class="col-6 me-2">
                    <button class="col-12 ms-2 btn btn-outline-secondary" type="button" (click)="cancelCheckOut(id, i)">
                      Revert
                    </button>
                  </div>
                  <div *ngIf="!item.isCheckedIn || item.isCheckedIn == undefined; else CheckedInP" class="col me-2">
                    <button style="position: relative" class="col btn btn-success" id="checkedinbtn" type="button"
                      [disabled]="checkedindata.products[i]?.['dataIsLoading']"
                      (click)="checkDeposits(id, i, 'checkin')">
                      Product Checked In
                      <i class="fa fa-spinner fa-spin" *ngIf="checkedindata.products[i]?.['dataIsLoading']"></i>
                    </button>
                  </div>
                </ng-container>

                <div class="row align-items-start mt-2 fs-6">
                  <ng-template class="d-grid gap-2" #checkedOutRevert>
                    <button type="button" class="btn" disabled>
                      This item has been reverted back to check out
                    </button>
                  </ng-template>
                </div>
                <ng-template #CheckedInP>
                  <div class="col-6 me-2">
                    <button class="col-12 ms-2 btn btn-outline-secondary" type="button" (click)="cancelCheckIn(id, i)">
                      Revert
                    </button>
                  </div>
                  <div class="col me-2">
                    <button class="col btn btn-success" type="button" disabled>
                      The Product has been Checked In
                      <i class="fa fa-spinner fa-spin" *ngIf="checkedindata.products[i]?.['dataIsLoading']"></i>
                    </button>
                  </div>
                </ng-template>
              </div>
              <hr />
            </div>
          </ng-container>
        </form>
      </div>
      <!--<div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      <button *ngIf="productscheckedout[0].isPrepared == true; else isNotPrepared" type="button"
          class="btn btn-success">Check Out the Booking</button>
      <ng-template #isNotPrepared>
          <button type="button" class="btn btn-danger" disabled>You Can Not Checked Out the Booking</button>
      </ng-template>
  </div>-->
    </div>
  </div>
</div>

<!--Modal to Confirm Payments-->
<div class="modal fade" id="DepositsModal" aria-labelledby="DepositsModal" aria-hidden="true" data-bs-backdrop="static"
  data-bs-keyboard="false" data-bs-focus="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="DepositsModalLabel">Deposits to confirm</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="openModalBack()"></button>
      </div>
      <div class="modal-body">
        <div class="pt-2 pb-2 rounded-bottom">
          <div class="row m-3" *ngIf="deposits.length <= 0; else withdeposits">
            <div class="col-12 text-center">There's nothing to show</div>
          </div>
          <ng-template #withdeposits>
            <div class="row m-3 bg-light border rounded pb-1" *ngFor="let deposit of deposits">
              <div class="col-6 text-left fs-5 pt-2 pb-2">
                {{ "Security Deposit" }}
              </div>
              <div class="col-6 text-right pt-2 pb-2 fs-5 text-primary">
                ${{ deposit.amount / 100 }}
              </div>
              <div class="col-6 text-left titlefont text-secondary pb-1">
                {{ deposit.type == "refund" ? "Refund" : "Payment" }}
              </div>
              <div class="col-6 text-right pb-1">
                <span *ngIf="
                      deposit.status == 'canceled' && deposit.type == 'deposit'
                    " class="badge bg-danger me-1">Canceled</span>
                <span *ngIf="
                      deposit.status == 'succeeded' && deposit.type == 'deposit'
                    " class="badge bg-success me-1">Confirmed</span>
                <span *ngIf="
                      deposit.status == 'requires_capture' &&
                      deposit.type == 'deposit'
                    " class="badge bg-danger me-1 hover" (click)="cancelPayment(deposit, 'deposit')">Cancel</span>
                <span *ngIf="
                      deposit.status == 'requires_capture' &&
                      deposit.type == 'deposit'
                    " class="badge bg-success me-3 hover" (click)="confirmPayment(deposit, 'deposit')">Confirm</span>
                <span class="badge bg-info bgdate">{{
                  convertDate(deposit.created)
                  }}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal to add charges-->
<div class="modal fade" *ngIf="isAddPaymentMethod == false" id="chargeModal" data-bs-backdrop="static"
  data-bs-keyboard="false" data-bs-focus="false" aria-labelledby="chargeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form (submit)="handleSubmit($event)" class="needs-validation" id="checkoutForm" novalidate>
          <div class="form__stripe mt-2">
            <div class="form-group">
              <div id="payment-request-button">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
              </div>
              <div class="row justify-content-around">
                <div class="col-12 mt-2 text-right justify-content-end align-items-end">
                  <button type="button" class="btn btn-secondary" (click)="backModal()">
                    Cancel
                  </button>
                  <button id="submit" type="submit" class="btn btn-primary ms-2">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                  </button>
                </div>
              </div>

              <div id="payment-message" class="hidden"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" *ngIf="isAddPaymentMethod" id="paymentModal" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Payment Method</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="checkoutForm" (ngSubmit)="handleSubmitPayment($event)" class="needs-validation"
          id="checkoutForm" novalidate>
          <div class="row">
            <div class="form__stripe1" *ngIf="!isLoading">
              <div class="row">
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <label for="name">First Name <b class="text-danger">*</b></label>
                  <div class="input-group mb-3">
                    <input type="text" id="name" placeholder="First Name" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.firstName.errors
                        }" formControlName="firstName" required />
                    <div *ngIf="submittedpayment && co.firstName.errors" class="invalid-feedback">
                      <div *ngIf="co.firstName.errors.required">
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <label for="name">Last Name <b class="text-danger">*</b></label>
                  <div class="input-group mb-3">
                    <input type="text" id="lastName" placeholder="Last Name" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.lastName.errors
                        }" formControlName="lastName" required />
                    <div *ngIf="submittedpayment && co.lastName.errors" class="invalid-feedback">
                      <div *ngIf="co.lastName.errors.required">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="email">Email <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="email" #emailInput id="email" placeholder="Email" class="form-control" [ngClass]="{
                            'is-invalid': submittedpayment && co.email.errors
                          }" formControlName="email" [(ngModel)]="email" required />
                      <div *ngIf="submittedpayment && co.email.errors" class="invalid-feedback">
                        <div *ngIf="co.email.errors.required">
                          Email is required
                        </div>
                        <div *ngIf="co.email.errors.email">
                          Email is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="phone">Phone <b class="text-danger">*</b></label>
                    <!--   <input type="text" #phoneInput id="phone"
            (keypress)="onlyNumber($event,15)"
            placeholder="Phone Number" class="form-control"
            formControlName="phone" required> -->
                    <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{
                          'is-invalid': submittedpayment && co.phone.errors
                        }" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                      [phoneValidation]="true" [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National"
                      formControlName="phone" id="phone">
                    </ngx-intl-tel-input>
                    <div *ngIf="submittedpayment && co.phone.errors" class="invalid-feedback">
                      <div *ngIf="co.phone.errors.required">
                        Phone is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-12">
                  <div class="form-group">
                    <label for="address">Address <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" #addressInput id="address" placeholder="Address" class="form-control"
                        [ngClass]="{
                            'is-invalid': submittedpayment && co.address.errors
                          }" formControlName="address" required />
                      <div *ngIf="submittedpayment && co.address.errors" class="invalid-feedback">
                        <div *ngIf="co.address.errors.required">
                          Address is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-12">
                  <div class="form-group">
                    <label for="address2">Address 2</label>
                    <div class="input-group mb-3">
                      <input type="text" #addressInput id="address2" placeholder="Address 2" class="form-control"
                        formControlName="address2" />
                      <div class="invalid-feedback">
                        Please put a valid address
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-7 col-lg-7" *ngIf="states.length <= 0">
                  <div class="form-group">
                    <label for="city">City <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="city" placeholder="City" class="form-control" [ngClass]="{
                            'is-invalid': submittedpayment && co.city.errors
                          }" formControlName="city" required />
                      <div *ngIf="submittedpayment && co.city.errors" class="invalid-feedback">
                        <div *ngIf="co.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-4 col-lg-4" *ngIf="states.length > 0">
                  <div class="form-group">
                    <label for="city">City <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="city" placeholder="City" class="form-control" [ngClass]="{
                            'is-invalid': submittedpayment && co.city.errors
                          }" formControlName="city" required />
                      <div *ngIf="submittedpayment && co.city.errors" class="invalid-feedback">
                        <div *ngIf="co.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-3 col-lg-3" *ngIf="states.length > 0">
                  <div class="form-group">
                    <label for="state">{{ stateTitle }} <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <select id="state" class="form-select" [formControl]="state" formControlName="state" [ngClass]="{
                            'is-invalid': submittedpayment && co.state.errors
                          }" [required]="states.length > 0">
                        <option value="" selected disabled>Select State</option>
                        <option *ngFor="let state of states" [value]="state">
                          {{ state }}
                        </option>
                      </select>
                      <div *ngIf="submittedpayment && co.state.errors" class="invalid-feedback">
                        <div *ngIf="co.state.errors.required">
                          {{ stateTitle }} is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-2 col-lg-2">
                  <div class="form-group">
                    <label for="zip">{{ zipTitle }} <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="zip" placeholder="{{
                            zipTitle == 'Postal Code' ? 'P.C.' : zipTitle
                          }}" class="form-control" [ngClass]="{
                            'is-invalid': submittedpayment && co.zip.errors
                          }" formControlName="zip" required />
                      <div *ngIf="submittedpayment && co.zip.errors" class="invalid-feedback">
                        <div *ngIf="co.zip.errors.required">
                          {{ zipTitle }} is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-3 col-lg-3">
                  <div class="form-group">
                    <label for="country">Country <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <select id="country" class="form-select" [ngClass]="{
                            'is-invalid': submittedpayment && co.country.errors
                          }" (change)="setStates($event)" formControlName="country" required>
                        <option *ngFor="let country of countries" [selected]="country.shortName == 'US'"
                          [value]="country.shortName">
                          {{ country.name }}
                        </option>
                      </select>
                      <div *ngIf="submittedpayment && co.country.errors" class="invalid-feedback">
                        <div *ngIf="co.country.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form__stripe mt-2">
            <div class="form-group">
              <div id="payment-request-button">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
              </div>
              <div class="row justify-content-center">
                <hr *ngIf="!isHidden" class="col-11" />
              </div>
              <div class="row mb-3">
                <div class="col-6 col-md-5 offset-md-1 fs-6 text-left text-bold" *ngIf="!isHidden">
                  Payment Total:
                </div>
                <div class="col-6 col-md-2 offset-md-3 fs-6 text-right text-bold" *ngIf="!isHidden">
                  {{ amountToPAYwTaxes.toFixed(2) | currency }}
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="col-4">
                  <button id="submit" type="submit" class="btn btn-primary">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                  </button>
                </div>
              </div>

              <div id="payment-message" class="hidden"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal Shop -->
<div class="modal fade" id="editShopComments" tabindex="-1" aria-labelledby="editShopCommentsLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="editShopCommentsLabel">
          Shop comments
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="myForm">
          <div class="row">
            <div class="col-md-12 mb-2">
              <label for="shopComments"> <b>Shop Comments </b></label>
              <div class="input-group">
                <textarea #myTextarea class="form-control" id="shopComments" formControlName="shopComments" rows="4"
                  placeholder="Type here...."></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button [disabled]="shopSaveButton" type="button" class="btn btn-primary"
          *ngIf="this.typemodal === 'productdata'" (click)="saveChanges(productdata.id)">
          Save
        </button>
        <button [disabled]="shopSaveButton" type="button" class="btn btn-primary"
          *ngIf="this.typemodal === 'checkedoutdata'" (click)="saveChanges(checkedoutdata.id)">
          Save
        </button>
        <button [disabled]="shopSaveButton" type="button" class="btn btn-primary"
          *ngIf="this.typemodal === 'checkedindata'" (click)="saveChanges(checkedindata.id)">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
