import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

//Components
import { BookingCatalogBreadcrumbsComponent } from '../v2/components/commons/booking-flows/catalog/booking-catalog-breadcrumbs/booking-catalog-breadcrumbs.component';
import { BookingFlowWrapperComponent } from '../v2/components/public/booking-flow-wrapper/booking-flow-wrapper.component';
import { BookingFlowConfigModalComponent } from './booking-suite/bookingFlowConfigModal/bookingFlowConfigModal.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { BookingTimeslotListComponent } from './booking-timeslot-list/booking-timeslot-list.component';
import { PartnerComponent } from './partner.component';

import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateAccountComponent } from './create-account/create-account.component';
//
import { RouterModule } from '@angular/router';

//Material Angular
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import {MatLuxonDateModule} from "@angular/material-luxon-adapter"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';

//Navigation
import { HeaderComponent } from './includes/navigation/header/header.component';
import { FooterComponent } from './includes/navigation/footer/footer.component';
import { SidebarComponent } from './includes/navigation/sidebar/sidebar.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DialogConfirmComponent } from './includes/dialog-confirm/dialog-confirm.component';

//Additional Services
import { GridsterModule } from 'angular-gridster2';
import { QRCodeModule } from 'angularx-qrcode';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { BookingNextStatusComponent } from './booking-next-status/booking-next-status.component';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingsUpload2Component } from './bookings-upload2/bookings-upload2.component';
import { BookingProductsTable2Component } from './booking-products-table2/booking-products-table2.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { LogListComponent } from './log-list/log-list.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ReportModule } from './report/report.modulec';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { BookingCalendarComponent } from './booking-calendar/booking-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { FlatpickrModule } from 'angularx-flatpickr';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WaiverEditorComponent } from './waiver-editor/waiver-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DiscountsCodesGeneratorComponent } from './discounts-codes-generator/discounts-codes-generator.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { UsersManagementComponent } from './users-management/users-management.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserStatusComponent } from './users-management/user-status/user-status.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ReportProductComponent } from './report-product/report-product.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ImageLibraryComponent } from './image-library/image-library.component';
import { CalendarProductComponent } from './calendar-product/calendar-product.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { ImageLibraryNoImagesComponent } from './image-library-no-images/image-library-no-images.component';
import { ProductNameFilterPipe } from './pipes/product-name-filter.pipe';
import { BookingPipe } from './pipes/booking.pipe';

import { BookingSuiteComponent } from './booking-suite/booking-suite.component';
import { TemplateEditorComponent } from './booking-suite/template-editor/template-editor.component';
import { PageEmbedsComponent } from './page-embeds/page-embeds.component';
import { CompanyComponent } from './company/company.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImgFilterPipe } from './pipes/objectProperty-filter.pipe';
import { FullCalendarModule } from '@fullcalendar/angular';
import { InventoryComponent } from './inventory/inventory.component';
import { PageFormComponent } from './booking-suite/page-form/page-form.component';
import { ProductLocationComponent } from './product-location/product-location.component';
import { InventoryPageComponent } from './inventory-page-builder/inventory-page/inventory-page.component';
import { InventoryPageBuilderComponent } from './inventory-page-builder/inventory-page-builder.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DndModule } from 'ngx-drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { InventoryPageViewComponent } from './inventory-page-view/inventory-page-view.component';
import { InventoryWidgetDisplayComponent } from './inventory-page-builder/inventory-widget-display/inventory-widget-display.component';
import { InventoryWidgetEditorComponent } from './inventory-page-builder/inventory-widget-editor/inventory-widget-editor.component';
import { SavedWidgetPageComponent } from './inventory-page-builder/saved-widget-page/saved-widget-page.component';
// import { ProductWidgetComponent } from './product-widget/product-widget.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { BadPageComponent } from './booking-suite/bad-page/bad-page.component';
import { IframeTestComponent } from './booking-suite/iframe-test/iframe-test.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NotifyMeModalComponent } from './booking-suite/notify-me-modal/notify-me-modal.component';
import { ComponentsModule } from './components/components.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { ProductSizesComponent } from './product-sizes/product-sizes.component';
import { FilterBookingsPipe } from './pipes/filter-bookings.pipe';
import { SharedDirectivesModule } from "../shared-directives/shared-directives.module";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { BigqReportsModule } from './bigq-reports/bigq-reports.module';
import { CustomerInfoComponent } from './booking-suite/customer-info/customer-info.component';
import { PaymentIntentComponent } from './booking-suite/payment-intent/payment-intent.component';
import { CheckoutSuccessComponent } from './booking-suite/checkout-success/checkout-success.component';
import { CalendarProductRebuildComponent } from './calendar-product-rebuild/calendar-product-rebuild.component';
import { LogoTransitionComponent } from './booking-suite/logo-transition/logo-transition.component';
import { OrderProcessingComponent } from './order-processing/order-processing.component';
import { CategoriesComponent } from './categories/categories.component';
import { InventoryPageWrapperComponent } from './inventory-page-wrapper/inventory-page-wrapper.component';
import { DateRangeComponent } from './booking-suite/date-range/date-range.component';
import { ContentCardComponent } from './booking-suite/content-card/content-card.component';
import { Cart2Component } from './booking-suite/cart2/cart2.component';
import { SearchTemplateByDateComponent } from './booking-suite/search-template-by-date/search-template-by-date.component';
import { AffiliateCRUD_ModalComponent } from './affiliate/affiliateCRUD_Modal/affiliateCRUD_Modal.component';
import { BookingCatalogViewComponent } from '../v2/components/commons/booking-flows/catalog/booking-catalog-view/booking-catalog-view.component';
import { BookingCatalogCreatorComponent } from '../v2/components/partner/booking-catalog-creator/booking-catalog-creator.component';
import { BookingFooterComponent } from '../v2/components/commons/booking-flows/catalog/booking-footer/booking-footer.component';
import { BookingHeaderComponent } from '../v2/components/commons/booking-flows/catalog/booking-header/booking-header.component';
import { GridstackModule } from 'gridstack/dist/angular';
import { CartComponent } from '../v2/components/public/booking-flow-wrapper/cart/cart.component';
import { InventoryFilterSearchWrapperComponent } from '../v2/components/commons/booking-flows/inventory-filter-search/inventory-filter-search-wrapper/inventory-filter-search-wrapper.component';
import { ErrorMessageComponent } from '../v2/components/commons/error-message/error-message.component';
import { FleetmaidSpinnerComponent } from '../v2/components/commons/fleetmaid-spinner/fleetmaid-spinner.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  declarations: [
    BookingFooterComponent,
    BookingHeaderComponent,
    BookingCatalogCreatorComponent,
    BookingCatalogBreadcrumbsComponent,
    BookingFlowWrapperComponent,
    BookingCatalogViewComponent,
    PartnerComponent,
    CreateAccountComponent,
    ProductNameFilterPipe,
    BookingPipe,
    //Navigation
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DialogConfirmComponent,
    FileUploadComponent,
    BookingNextStatusComponent,
    LogListComponent,
    AddNoteComponent,
    BookingCalendarComponent,
    WaiverEditorComponent,
    DiscountsCodesGeneratorComponent,
    ReceiptComponent,
    UsersManagementComponent,
    UserInfoComponent,
    UserStatusComponent,
    ReportProductComponent,
    ImageLibraryComponent,
    CalendarProductComponent,
    ImageLibraryNoImagesComponent,
    BookingSuiteComponent,
    TemplateEditorComponent,
    PageEmbedsComponent,
    CompanyComponent,
    ImgFilterPipe,
    ImgFilterPipe,
    InventoryComponent,
    PageFormComponent,
    ProductLocationComponent,
    InventoryPageComponent,
    InventoryPageBuilderComponent,
    InventoryPageViewComponent,
    InventoryWidgetDisplayComponent,
    InventoryWidgetEditorComponent,
    SavedWidgetPageComponent,
    DeleteConfirmationComponent,
    BadPageComponent,
    ProductSizesComponent,
    IframeTestComponent,
    NotifyMeModalComponent,
    FilterBookingsPipe,
    CustomerInfoComponent,
    PaymentIntentComponent,
    CheckoutSuccessComponent,
    CalendarProductRebuildComponent,
    LogoTransitionComponent,
    OrderProcessingComponent,
    CategoriesComponent,
    InventoryPageWrapperComponent,
    DateRangeComponent,
    ContentCardComponent,
    Cart2Component,
    SearchTemplateByDateComponent,
    BookingsComponent,
    BookingsUpload2Component,
    BookingProductsTable2Component,
    BookingTimeslotListComponent,
    BookingFlowConfigModalComponent,
    AffiliateComponent,
    AffiliateCRUD_ModalComponent,
    CartComponent,
    InventoryFilterSearchWrapperComponent,
    ErrorMessageComponent,
    FleetmaidSpinnerComponent
  ],
    imports: [
      TooltipModule.forRoot(),
      GridstackModule,
      GridsterModule,
        DashboardModule,
        ReportModule,
        CommonModule,
        RouterModule,
        MatSidenavModule,
        MatDividerModule,
        MatSortModule,
        MatTableModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        AppRoutingModule,
        MatTabsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatLuxonDateModule,
        ReactiveFormsModule,
        MatInputModule,
        FormsModule,
        NgSelectModule,
        MatCardModule,
        MatGridListModule,
        MatButtonToggleModule,
        FontAwesomeModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatSliderModule,
        MatPaginatorModule,
        QRCodeModule,
        ImageCropperModule,
        NgbModule,
        NgbModalModule,
        CKEditorModule,
        NgxDaterangepickerMd.forRoot(),
        HttpClientModule,
        DataTablesModule,
        FullCalendarModule,
        FullCalendarModule,
        DragDropModule,
        // FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: [DateAdapter, BookingsComponent],
            useFactory: adapterFactory
        }),
        MatSelectModule,
        MultiSelectModule,
        DropdownModule,
        MatTooltipModule,
        NgChartsModule,
        NgxIntlTelInputModule,
        NgbPopoverModule,
        MatStepperModule,
        MaintenanceModule,
        ComponentsModule,
        CdkAccordionModule,
        DndModule,
        ColorPickerModule,
        ComponentsModule,
        MatCheckboxModule,
        BigqReportsModule,
        SharedDirectivesModule,
        FontAwesomeModule

    ],
    providers: [
      {
      provide: MatDialogRef,
      useValue: [],
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: [],
    },
  ],
  exports: [FilterBookingsPipe],
})
export class PartnerModule {}
