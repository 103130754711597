import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './services/auth.guard';

import {DashboardComponent} from './partner/dashboard/dashboard.component';
import {ReportComponent} from './partner/report/report.component';
import {PartnerComponent} from "./partner/partner.component";

import { BookingsComponent } from './partner/bookings/bookings.component';

import {WaiverComponent} from './waiver/waiver.component';
import { BookingCalendarComponent } from './partner/booking-calendar/booking-calendar.component';
import { WaiverEditorComponent } from './partner/waiver-editor/waiver-editor.component';
import { DiscountsCodesGeneratorComponent } from './partner/discounts-codes-generator/discounts-codes-generator.component';
import {UsersManagementComponent} from "./partner/users-management/users-management.component";
import { ReportProductComponent } from 'src/app/partner/report-product/report-product.component';
import {ImageLibraryComponent} from "./partner/image-library/image-library.component";
import { CalendarProductComponent } from './partner/calendar-product/calendar-product.component';
import { BookingSuiteComponent } from './partner/booking-suite/booking-suite.component';
import { PageEmbedsComponent } from './partner/page-embeds/page-embeds.component';
import { CompanyComponent } from './partner/company/company.component';
import { InventoryComponent } from './partner/inventory/inventory.component';
import { ProductLocationComponent } from './partner/product-location/product-location.component';

import { LoginComponent } from './login.component';
import { InventoryPageComponent } from './partner/inventory-page-builder/inventory-page/inventory-page.component';
import { InventoryPageBuilderComponent } from './partner/inventory-page-builder/inventory-page-builder.component';
import { SavedWidgetPageComponent } from './partner/inventory-page-builder/saved-widget-page/saved-widget-page.component';

import { MaintenanceComponent } from './partner/maintenance/maintenance.component';
import { ProductSizesComponent } from './partner/product-sizes/product-sizes.component';
import { SalesReportComponent } from './partner/bigq-reports/sales-report/sales-report.component';
import { IframeTestComponent } from './partner/booking-suite/iframe-test/iframe-test.component';
import { TaxReportComponent } from './partner/bigq-reports/tax-report/tax-report.component';
import { CalendarProductRebuildComponent } from './partner/calendar-product-rebuild/calendar-product-rebuild.component';
import { OrderProcessingComponent } from './partner/order-processing/order-processing.component';
import { CategoriesComponent } from './partner/categories/categories.component';
import { SearchTemplateByDateComponent } from './partner/booking-suite/search-template-by-date/search-template-by-date.component';
import { AffiliateComponent } from './partner/affiliate/affiliate.component';
import { AffiliateCookieSetterComponent } from './components/public/affiliateCookieSetter/affiliateCookieSetter.component';
import { BadPageComponent } from './partner/booking-suite/bad-page/bad-page.component';
import { BookingFlowWrapperComponent } from './v2/components/public/booking-flow-wrapper/booking-flow-wrapper.component';
import { OnNavigationLeaveGuard } from './v2/guards/on-navigation-leave.guard';
import { RouteQueryGuard } from './v2/guards/route-query.guard';
import { BookingCatalogCreatorComponent } from './v2/components/partner/booking-catalog-creator/booking-catalog-creator.component';
import { InventoryFilterSearchWrapperComponent } from './v2/components/commons/booking-flows/inventory-filter-search/inventory-filter-search-wrapper/inventory-filter-search-wrapper.component';

const routes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {
    path: 'affiliate/:af', component: AffiliateCookieSetterComponent
  },
  {
    path: 'affiliate/:af/:co', component: AffiliateCookieSetterComponent // supports legacy affiliate links
  },
  {
    path: '404', component: BadPageComponent
  },
  {
    path: 'waiver/:id', component: WaiverComponent,
  },
  {
    path: 'waiver', component: WaiverComponent,
  },
  // http://localhost:4200/book/NFNmVeLDAiOBVPxVmnzn/search/SG6dOahr37J6tOZrJbzM?location=XXPSTGMyYH93CTWdWP5o&startDate=2023-10-22&endDate=2023-10-25

  {path: 'book/:templateID', component: BookingFlowWrapperComponent, canActivate: [RouteQueryGuard], children: [
    {path: '', component: BookingFlowWrapperComponent}, // redirects to catalog, oldFlow, etc depending on template preference
    {path: 'catalog', component: BookingFlowWrapperComponent},
    {path: 'catalog/:productGroupID', component: BookingFlowWrapperComponent},
    {path: 'search', component: SearchTemplateByDateComponent},
    {path: 'search/:productGroupID', component: BookingFlowWrapperComponent},
    {path: 'cart', component: BookingFlowWrapperComponent},
    {path: 'item-edit/:productGroupID', component: BookingFlowWrapperComponent},
    {path: 'customer-info', component: BookingFlowWrapperComponent},
    {path: 'payment-intent', component: BookingFlowWrapperComponent},
    {path: 'checkout-success', component: BookingFlowWrapperComponent},
  ]},

  {path: 'test/iframe', component: IframeTestComponent},

  {
    path: 'partner', component: PartnerComponent,
    children: [{path: '', component: DashboardComponent, canActivate: [AuthGuard]},
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      {path: 'affiliate', component:AffiliateComponent, canActivate: [AuthGuard]},
      {path: 'waiver', component: WaiverEditorComponent, canActivate: [AuthGuard]},
      {path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
      {path: 'reports', component: ReportProductComponent, canActivate: [AuthGuard]},
      {path: 'order', component: OrderProcessingComponent, canActivate: [AuthGuard]},
      {path: 'order/:rentalID', component: OrderProcessingComponent, canActivate: [AuthGuard]},
      {path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard]},
      {path: 'inventory/:productID', component: InventoryComponent, canActivate: [AuthGuard]},
      {path: 'bookings', component: BookingsComponent, canActivate: [AuthGuard]},
      {path: 'bookings/:bookingID', component: BookingsComponent, canActivate: [AuthGuard]},
      {path: 'bookingCalendar', component: BookingCalendarComponent, canActivate: [AuthGuard]},
      {path: 'discountProduct', redirectTo: 'discounts', pathMatch: 'full'},
      {path: 'discountsCodesGenerator', redirectTo: 'discounts', pathMatch: 'full'},
      {path: 'discounts', component: DiscountsCodesGeneratorComponent, canActivate: [AuthGuard]},
      {path: 'usersManagement', component: UsersManagementComponent, canActivate: [AuthGuard]},
      {path: 'library', component: ImageLibraryComponent, canActivate: [AuthGuard]},
      {path: 'inventoryCalendar', component: CalendarProductComponent, canActivate: [AuthGuard]},
      {path: 'inventoryCalendarRebuild', component: CalendarProductRebuildComponent, canActivate: [AuthGuard]},
      {path: 'bookingSuite/:templateID', redirectTo: 'booking-suite/:templateID', pathMatch: 'full'},
      {path: 'booking-suite/:templateID', component: BookingCatalogCreatorComponent, canActivate: [AuthGuard, RouteQueryGuard], canDeactivate: [OnNavigationLeaveGuard]},
      {path: 'bookingSuite', redirectTo: 'booking-suite', pathMatch: 'full'},
      {path: 'booking-suite', component: BookingSuiteComponent, canActivate: [AuthGuard]},
      {path: 'page-embeds', component: PageEmbedsComponent, canActivate: [AuthGuard]},
      {path: 'company', component: CompanyComponent, canActivate: [AuthGuard]},
      {path: 'inventoryrebuild', redirectTo: 'inventory', pathMatch: 'full'},
      {path: 'inventoryrebuild/:productID', redirectTo: 'inventory/:productID', pathMatch: 'full'},
      {path: 'productLocation', redirectTo: 'shop'},
      {path: 'shop', component: ProductLocationComponent, canActivate: [AuthGuard]},
      {path: 'inventoryPageBuilder', component: InventoryPageBuilderComponent, canActivate: [AuthGuard]},
      {path: 'inventoryPageBuilder/:pageId', component: InventoryPageComponent, canActivate: [AuthGuard]},
      {path: 'inventoryPageBuilder/savedWidgetEditor/:widgetId', component: SavedWidgetPageComponent, canActivate: [AuthGuard]},
      {path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthGuard]},
      {path: 'product-sizes', component: ProductSizesComponent, canActivate: [AuthGuard]},
      {path: 'sales-report', component: SalesReportComponent, canActivate: [AuthGuard]},
      {path: 'tax-report', component: TaxReportComponent, canActivate: [AuthGuard]},
      {path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard]},
      {path: 'abc', component: InventoryFilterSearchWrapperComponent, canActivate: [AuthGuard]}
    ]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}

