/* Models */
import { AppError, ErrorLevel } from '../../models/errors.model'
import { ErrorCodes } from '../../models/errors.model';

export const CRUD_ERRORS: { [key: string]: AppError } = {
    [ErrorCodes.DELETION_FAILED]: {
        code: ErrorCodes.DELETION_FAILED,
        message: "An error occured in the deletion process. Please contact Fleetmaid support for assistance.",
        level: ErrorLevel.ERROR,
        override: false
    },
};