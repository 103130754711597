import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTabsModule } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { WidgetService } from 'src/app/services/widget.service';
import { User } from 'src/app/models/storage/user.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-inventory-page-builder',
  templateUrl: './inventory-page-builder.component.html',
  styleUrls: ['./inventory-page-builder.component.scss']
})
export class InventoryPageBuilderComponent {
  protected inventoryPageTitlesWhileEditing: {[id: string]: string} = {}; // used to store the template title while editing
  inventoryPages = [];
  productsList = [];
  inventoryPageMap = {};
  layoutForm;
  selectedLayoutId;
  updatedProductsList = [];
  productLayoutChange;
  chosenLayout;
  productGroups;
  widgetList = [];
  inventoryPagesIdList = [];
  groupsToUpdate = [];
  dataIsAvailable = false;
  defaultID;
  layoutTitleName;
  parentLoaded = false;
  lo = false;
  organizedInvPages;
  defaultPageArray = [];
  defaultPage = null;
  subs = new Subscription(); // group of subscriptions
  widgetsLoaded = false;
  groupsLoaded = false;
  inventoryPagesLoaded = false;
  companyID;
  firstCall = true;
  edit: boolean[] = [];
  calledAlready = false;
  inputTitle: string = '';

  documentId: string = '';
  newWidgetName = null;
  invPagesAvailable;

  widgetEdit = null;

  protected currentUser: User;
  protected projectId: string = environment.firebase.projectId;

  constructor(private productsService: ProductsService, private router: Router, private currentUserService: CurrentUserService,
              private widgetService: WidgetService){
                this.currentUser = this.currentUserService.currentUser;
              }

  async ngOnInit() {

    this.layoutForm = new FormGroup({
      'title': new FormControl("", [
        Validators.required,
      ]),
    })

    // Gets the company ID
    this.companyID = await this.currentUserService.currentUser.companyId;
    console.log("COMPANY ID")
    console.log(this.companyID)
    // Called everytime there is a change to inventory pages
    this.subs.add(this.widgetService.getInventoryPagesWithParamCompanyID(this.companyID).subscribe(async (data: any) =>{
      this.inventoryPages = [];
      this.inventoryPagesIdList = [];
      this.inventoryPages = data;

      if(this.dataIsAvailable){
        console.log("RAN AFTER DATA AVAIl")
      }
      console.log("INV PAGE SUB")
      console.log(this.inventoryPages)
      // Setting defaultId variable and setting
      let defaultIndex;

      // look for default page, if it does not exist then create one
      for( let i = 0; i <= this.inventoryPages.length - 1; i++){
        if(this.inventoryPages[i].isDefault){
          this.defaultID = this.inventoryPages[i].id
        }
        // If you get to the end of the loop then add a new default page and add it to the array and set defaultID
        if(this.inventoryPages.length - 1 == i && !this.defaultID){
          let newInvPage = await this.productsService.addInventoryPage('Default', true);
          this.inventoryPages.push(newInvPage);
          this.defaultID = newInvPage.id
        }
      }

      // Sorting groups by groupName in alphabetical order
      function compare( a, b ) {
        if ( a.title < b.title ){
          return -1;
        }
        if ( a.title > b.title ){
          return 1;
        }
        return 0;
      }

      // Organize inventory pages by dates created
      this.inventoryPages = this.inventoryPages.sort( compare );

      // Moves the defualt to the front
      this.inventoryPages.forEach((page, ind) => {
        if(page.isDefault){
          defaultIndex = ind;
        }
        this.inventoryPagesIdList.push(page.id);
      })
      this.inventoryPages.splice(0, 0, this.inventoryPages[defaultIndex]);
      this.inventoryPages.splice(defaultIndex + 1, 1);
      this.inventoryPagesLoaded = true;
      console.log("FIRED")
      this.allSubsLoaded();
    }))


    // Called everytime there is a change to saved widgets
    this.subs.add(this.widgetService.getWidgetsWithParamCompanyID(this.companyID).subscribe((widget) => {
      this.widgetList = [];
      this.widgetList = widget;
      console.log("WIDGET LIST")
      console.log(this.widgetList)

      // Sorting groups by groupName in alphabetical order
      function compare( a, b ) {
        if ( a.widgetName < b.widgetName ){
          return -1;
        }
        if ( a.widgetName > b.widgetName ){
          return 1;
        }
        return 0;
      }

      this.widgetList = this.widgetList.sort( compare );

      this.widgetsLoaded = true;
      this.allSubsLoaded();
    }))

    // Called everytime there is a change to product groups
    this.subs.add(this.productsService.getProductGroupsByCompanyID(this.companyID).subscribe((groups) => {
      this.productGroups = groups;
      console.log("GROUPS SUB")
      console.log(this.productGroups)
      // Sorting groups by groupName in alphabetical order
      function compare( a, b ) {
        if ( a.groupName < b.groupName ){
          return -1;
        }
        if ( a.groupName > b.groupName ){
          return 1;
        }
        return 0;
      }

      this.productGroups = this.productGroups.sort( compare );

      this.groupsLoaded = true;
      this.allSubsLoaded();
    }))
  };

  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
  }

  async allSubsLoaded(){
    console.log("ALL SUBS CALLED")
    if(this.groupsLoaded && this.widgetsLoaded && this.inventoryPagesLoaded && this.firstCall){
      console.log("ALL SUBS PASSED")
      this.firstCall = false;
      await this.validateGroupInventoryId();
      this.dataIsAvailable = true;
    }
  }

  get title() {
    return this.layoutForm.get('title');
  }


  // To validate last name
  validateLayoutTitle(control: AbstractControl) {
    if (control.value.length <= 3) {
      return { validLname: true };
    }
    return null;
  }

  //Checks to make sure all groups have an inventoryId that exists and if not it sets them to default
  validateGroupInventoryId(){
    console.log("INVENTORY OAGES ID")
    console.log(this.inventoryPagesIdList)
    this.productGroups.forEach(group => {
      // If product doesnt have an inventoryPageId or has a non-existing inventoryPageId
      // set it to the default inventory page
      if(!group['inventoryPageId'] || !this.inventoryPagesIdList.includes(group['inventoryPageId'])){
        console.log("NEED TO UPDATE")
        console.log(this.defaultID)
        console.log(group)
        this.groupsToUpdate.push(group)
        // this.productsService.updateProductGroupInventoryPageId(group.id, group['inventoryPageId']);
      }
    })
    this.productsService.batchUpdateGroupInvId(this.groupsToUpdate, this.defaultID)

    this.groupsToUpdate = [];
    return
  }

  // Adds a new template to the database
  createNewTemplate() {
    this.productsService.addInventoryPage(this.layoutForm.get('title')?.value, false);
    this.closeTemplateModal();
    this.layoutForm = new FormGroup({
      'title': new FormControl("", [Validators.required]),
    })
  }

  // Displays a message with how many products are attached to waiver and deletes layout
  deleteLayout(id){
    this.productsService.searchProductGroupsAttachedToInvPage(id)
    .then((product) => {
      if (product.length <= 0) {
        Swal.fire({
          title: 'Delete Layout',
          html: 'Are you sure you want to delete this layout?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#7066e0',
          cancelButtonText: 'Confirm',
          showConfirmButton: true,
          confirmButtonColor: '#6e7881',
          confirmButtonText: 'Cancel',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isDismissed) {
            this.productsService.deleteInventoryPage(id);
            Swal.fire({
              title: 'Layout deleted',
              html: 'The layout has been deleted permanently',
              icon: 'success',
            });
          }
        });
      } else {
        Swal.fire({
          title: 'Layout in use',
          html:
            'This layout is associated with ' +
            product.length +
            ' product(s), change this before removing this layout permanently',
          icon: 'error',
        });
      }
    });
    // this.productsService.deleteInventoryPage(id);
  }

  // Used to display Inventory Page Name on Inventory pages
  getInventoryPageName(pageId){
    // let invPage = JSON.parse
    // console.log(JSON.parse(JSON.stringify(this.inventoryPages)))
    // console.log(this.inventoryPages)
    let found = '';
    this.inventoryPages.forEach(data =>{
      if(data.id == pageId){
        found = data.title;
      }
    })
    return found
  }

  closeProductLayoutModal(){
    this.productLayoutChange = '';
    this.chosenLayout = '';
    $('#productLayoutModal').modal('hide')
  }

  openCreateTemplateModal() {
    $('#createTemplateModal').modal('show')
  }

  closeTemplateModal() {
    $('#createTemplateModal').modal('hide')
  }

  getLayoutIdModal(Id){
    this.selectedLayoutId = Id;
    console.log(this.selectedLayoutId)
  }

  unselectLayoutId(){
    this.selectedLayoutId = '';
  }

  selectProductLayout(group, i) { //Method to select a waiver with checkbox
    if(group.inventoryPageId == this.selectedLayoutId){
      group.inventoryPageId = this.defaultID;
    }
    else{
      group.inventoryPageId = this.selectedLayoutId;
    }

    this.updatedProductsList.push(group);
  }

  saveLayoutItems() { //Function to save the products changes
    this.selectedLayoutId = '';
        Swal.fire({
          title: "Loading..."
        })
        Swal.showLoading();
        let promises = [];
        this.updatedProductsList.forEach(group => { //For each selected waiver
          let promise = new Promise(async (resolve, reject) => { //Promise
            await this.productsService.updateProductGroupInventoryPageId(group.id, group.inventoryPageId); //Update the product
            resolve(true)
          });
          promises.push(promise)
        })

        Promise.all(promises).then((_) => {
          setTimeout(() => {
            Swal.close();
            Swal.fire({
              title: "Changes Saved",
              html: "The products have been successfully reassigned",
              icon: "success"
            })
          })
        })
        this.updatedProductsList = [];
        this.selectedLayoutId = '';
        $('#productModal').modal('hide')
  }



  openProductLayoutModal(group){
    this.productLayoutChange = group;

    this.inventoryPages.forEach(page => {
      if(page.id == this.productLayoutChange['inventoryPageId']){
        this.chosenLayout = page;
      }
    })

    // this.chosenLayout = group.
    console.log("PRODUCT LAYOUT CHNAGE")
    console.log(this.productLayoutChange)

    $('#productLayoutModal').modal('show')
  }


  saveProductLayout(){
    console.log("CHOSENLAYOUT");
    console.log(this.chosenLayout)
    this.productLayoutChange.inventoryPageId = this.chosenLayout.id;

      Swal.fire({
        title: "Loading..."
      })
      Swal.showLoading();
      let promise = new Promise(async (resolve, reject) => { //Promise
        await this.productsService.updateProductGroupInventoryPageId(this.productLayoutChange.id, this.productLayoutChange.inventoryPageId); //Update the product
        resolve(true)
      });
      promise.then((_) => {
        setTimeout(() => {
          Swal.close();
          Swal.fire({
            title: "Changes Saved",
            html: "The products layout has been updated",
            icon: "success"
          })
        })
      })
      this.productLayoutChange = '';
      this.chosenLayout = '.'
      $('#productLayoutModal').modal('hide')
    }



  //   switch(action){
  //     case 'open':
  //       this.inventoryPageTitleWhileEditing = this.inventoryPages[index].title;
  //       break;
  //     case 'save':
  //       this.inventoryPages[index].title = this.inventoryPageTitleWhileEditing; // helps to make the change in the UI look more instant before the subscription updates the data
  //       this.saveLayout(this.inventoryPages[index].id, this.inventoryPageTitleWhileEditing);
  //       break;
  //   }

  //   this.edit[index] = !this.edit[index]; // Closes an open input field
  // }

    //close or open input  title
    protected inventoryPageTitleInteraction(index: number, action?: 'open' | 'save' | 'cancel'): void {
      switch(action){
        case 'open':
          this.inventoryPageTitlesWhileEditing[this.inventoryPages[index].id] = this.inventoryPages[index].title;
          break;
        case 'save':
          this.inventoryPages[index].title = this.inventoryPageTitlesWhileEditing[this.inventoryPages[index].id]; // helps to make the change in the UI look more instant before the subscription updates the data
          this.saveLayout(this.inventoryPages[index].id, this.inventoryPageTitlesWhileEditing[this.inventoryPages[index].id]);
          break;
        case 'cancel':
          if(this.inventoryPageTitlesWhileEditing[this.inventoryPages[index].id]){
            delete this.inventoryPageTitlesWhileEditing[this.inventoryPages[index].id];
          }
      }

      this.edit[index] = !this.edit[index]; // Closes an open input field
    }

  private saveLayout(inventoryPageID: string, title: string) : void {
  this.productsService
      .updateTitleandIdslayouts(inventoryPageID, title)
      .then(() => {
        Swal.fire({
          toast: true,
          position: 'bottom-right',
          title: 'Title updated successfully',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          toast: true,
          position: 'bottom-right',
          title: 'Error updating title. Please try again later',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  activateEditWidgetTitle(index: number, widget){
    this.widgetEdit = index;
    console.log("WIDGETE DIT")
    console.log(this.widgetEdit)
    this.newWidgetName = JSON.parse(JSON.stringify(widget.widgetName));
  }

  closeEditWidgetTitle(e, widget){
    this.widgetEdit = null;
    this.newWidgetName = null;
  }

  saveNewWidgetName(widget){
    this.productsService
    .updateWidgetName(widget.id, this.newWidgetName)
    .then(() => {
      console.log("SAVE WIDGET NAME")
      console.log(this.newWidgetName)
      widget.widgetName = this.newWidgetName;
      this.widgetEdit = null;
      this.newWidgetName = null;

      Swal.fire({
        title: 'Title updated successfully',
        toast: true,
        position: 'bottom-right',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .catch((error) => {
      Swal.fire({
        toast: true,
        position: 'bottom-right',
        title: 'Error updating title. Please try again later',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    });
  }

  deleteWidget(widgetID){
    // this.productsService.getAllInventoryPage().then(invPages => {
    //   // loop through pages and look for saved widget Id
    // })
    let pagesLocatedOn = [];
    this.inventoryPages.forEach( page => {
      page.widgetList.forEach(widget => {
        if(widget.savedWidgetId && widget.savedWidgetId == widgetID){
          pagesLocatedOn.push(page)
        }
      })
    })
    console.log("Pages located")
    console.log(pagesLocatedOn)

    if (pagesLocatedOn.length <= 0) {
      Swal.fire({
        title: 'Delete Widget',
        html: 'Are you sure you want to delete this widget?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Confirm',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          this.productsService.deleteWidget(widgetID);
          Swal.fire({
            title: 'Widget deleted',
            html: 'The widget has been deleted permanently',
            icon: 'success',
          });
        }
      });
    } else {
      let modalHtml = '';
      pagesLocatedOn.forEach(page => {
        modalHtml += '<br>' + '&#x2022 ' + page.title
      })
      console.log("modal html")
      console.log(modalHtml)
      Swal.fire({
        title: 'Widget in use',
        html:
          'This widget is associated with ' +
          pagesLocatedOn.length +
          ' page(s), change this before removing this widget permanently ' + modalHtml,
        icon: 'error',
      });
    }
  }

} //End of class
