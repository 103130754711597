import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeleteConfirmationService } from 'src/app/services/delete-confirmation.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})

export class DeleteConfirmationComponent implements OnInit, OnDestroy {
  constructor(private deleteConfirmationService: DeleteConfirmationService) {
  }

  @ViewChild('deleteConfirmationForm') public deleteConfirmationForm: NgForm;
  private id: string = null;
  private subs: Subscription = new Subscription();
  protected submissionBtnIsDisabled: boolean = true;
  protected extras: any = {};
  protected inputValue: string = null;
  protected title: string = null;

  public ngOnInit(): void {
    this.subs.add(this.deleteConfirmationService.getInfo.subscribe((res) => {
      this.inputValue = "";
      this.title = res['title'];
      this.id = res['id'];
      this.extras = res['extras']
    }));
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected validateMatchingInput(): void {
    if (this.inputValue.trim() != this.title.trim()) {
      this.submissionBtnIsDisabled = true;
    } else {
      this.submissionBtnIsDisabled = false;
    }
  }

  protected delete(): void {
    this.deleteConfirmationService.validationPassed({ id: this.id, title: this.title, extras: this.extras });
  }
}