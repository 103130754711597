// src/app/services/error.service.ts
import { Injectable } from '@angular/core';
import { AppError } from '../models/errors.model';

/* Errors */
import { GLOBAL_ERRORS } from '../errors/errors-global';
import { DATE_RANGE_ERRORS } from '../errors/errors-components/errors-date-range';
import { CRUD_ERRORS } from 'src/app/v2/errors/errors-components/errors-crud';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    constructor(){
    }

    private errors: { [key: string]: AppError } = {
        ...GLOBAL_ERRORS,
        ...DATE_RANGE_ERRORS,  // Merge component-specific errors
        ...CRUD_ERRORS
    };

    // Method to get an error by its code
    public getErrorByCode(code: string, overrideMsg?: string): AppError | undefined {
        const error = this.errors[code];
        if(overrideMsg){
            error.message = overrideMsg;
            error.override = true;
        }

        return error;
    }
}