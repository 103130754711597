import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';

/* Models */
import { BatchOperation } from 'src/app/v2/models/firestore-interaction.model';
import { Collection, SubCollection } from 'src/app/v2/models/collection-reference.model';
import { WidgetType } from 'src/app/models/widget.model';

/* Services */
import { CurrentUserService } from 'src/app/services/current-user.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { Product } from 'src/app/models/storage/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService {

  constructor(private firestoreService: FirestoreService,
    private currentUserService: CurrentUserService,
  ) { }

  public async deleteProductGroup(productGroupID: string): Promise<void> {
    let batchOperations: BatchOperation[] = [], // Document creations, updates, deletes
      savedWidgetsWithDeletedPG: string[] = [],
      inventoryPagesWithDeletedPG: string[] = [],
      deletedProductIDs: string[] = [];
    
      const lastModified: Date = new Date();

    /* Products */
    const products: Product[] = await lastValueFrom(this.firestoreService.getCollection(Collection.Products, [
      { field: 'companyID', operator: '==', value: this.currentUserService.currentUser.companyId },
      { field: 'productGroupID', operator: '==', value: productGroupID },
      { field: 'isActive', operator: '==', value: true }], []).pipe(take(1)));

    products.forEach((prod: Product) => {
      deletedProductIDs.push(prod.id);

      const obj = {
        userID: this.currentUserService.currentUser.id,
        firstName: this.currentUserService.currentUser.firstName,
        lastName: this.currentUserService.currentUser.lastName,
        timestamp: lastModified,
        details: "Product Deleted",
      }

      // Product - Writes a log
      batchOperations.push({ operationType: 'set', collection: Collection.Products, parentDocOfSubCollectionID: prod.id, subCollection: SubCollection.Log, documentData: obj });

      // Product - Soft Delete
      batchOperations.push({ operationType: 'update', collection: Collection.Products, documentID: prod.id, documentData: { isActive: false, isRetired: true, lastModified: lastModified } });
    })

    if (deletedProductIDs.length > 0) {
      console.warn('Attempting to delete the following products', deletedProductIDs);
    }

    /* Product Group */
    
    const productGroupLogObj = {
      userID: this.currentUserService.currentUser.id,
      firstName: this.currentUserService.currentUser.firstName,
      lastName: this.currentUserService.currentUser.lastName,
      timestamp: lastModified,
      details: "Product Group Deleted with " + products.length + " products",
    }

    // Product Group - Writes a log
    batchOperations.push({ operationType: 'set', collection: Collection.ProductGroup, parentDocOfSubCollectionID: productGroupID, subCollection: SubCollection.Log, documentData: productGroupLogObj });

    // Product Group - Soft Delete
    batchOperations.push({ operationType: 'update', collection: Collection.ProductGroup, documentID: productGroupID, documentData: { isActive: false, isRetired: true, lastModified: lastModified } });

    /* Saved Widgets */
    const savedWidgets = await lastValueFrom(this.firestoreService.getCollection(Collection.Widgets, [
      { field: 'companyID', operator: '==', value: this.currentUserService.currentUser.companyId },
      { field: 'isActive', operator: '==', value: true }], []).pipe(take(1)));

    savedWidgets.forEach(widget => {
      if (widget.widgetType === WidgetType.product && widget.element?.['groupId'] === productGroupID) {
        savedWidgetsWithDeletedPG.push(widget.id);

        widget['isActive'] = false;
        widget.lastModified = lastModified;
        // Widgets - Soft Delete
        batchOperations.push({ operationType: 'update', collection: Collection.Widgets, documentID: widget.id, documentData: { isActive: false } });
      }
    });

    if (savedWidgetsWithDeletedPG.length > 0) {
      console.warn(`Attempting to delete the following savedWidgets: ${savedWidgetsWithDeletedPG}`);
    }

    /* Inventory Pages */
    const inventoryPages = await lastValueFrom(this.firestoreService.getCollection(Collection.InventoryPages, [
      { field: 'companyID', operator: '==', value: this.currentUserService.currentUser.companyId },
      { field: 'isActive', operator: '==', value: true }], []).pipe(take(1)));

    inventoryPages.forEach((page) => {
      let hasChanges: boolean = false;
      page.widgetList = page.widgetList.filter(widget => {
        if (savedWidgetsWithDeletedPG.includes(widget.savedWidgetId) || widget?.element?.['groupId'] == productGroupID) {
          hasChanges = true;
          return false;
        }
        return true;
      });

      if (page?.cartWidgetList) {
        page.cartWidgetList = page.cartWidgetList.filter(widget => {
          if (savedWidgetsWithDeletedPG.includes(widget.savedWidgetId) || widget?.element?.groupId == productGroupID) {
            hasChanges = true;
            return false;
          }
          return true;
        });
      }

      if (hasChanges) {
        inventoryPagesWithDeletedPG.push(page.id);
        page.lastModified = lastModified; // update the inventory pages last modified date
        // Inventory Pages - Widget removal
        batchOperations.push({ operationType: 'update', collection: Collection.InventoryPages, documentID: page.id, documentData: page });
      }
    });

    if (inventoryPagesWithDeletedPG.length > 0) {
      console.warn(`Attempting to delete the following widgets with the following productGroupID: ${productGroupID}, from the following inventory pages: `, inventoryPagesWithDeletedPG);
    }

    try {
      await this.firestoreService.batchWrite(batchOperations);
    }
    catch (err) {
      throw new Error("Error - Failed to delete product group.");
    }
  }
}
