export enum Collection {
    AffiliateClicks = "affiliateClicks",
    Affiliates = "affiliates",
    BookingPages = "bookingPages",
    BookingTemplates = "bookingTemplates",
    Cart = "cart",
    CheckoutOptions = "checkoutOptions",
    Companies = "companies",
    Coupons = "coupons",
    DiscountCodes = "discountCodes",
    Emails = "emails",
    Groups = "groups",
    ImageLibrary = "imageLibrary",
    InventoryPageLayouts = "inventoryPageLayouts",
    InventoryPages = "inventoryPages",
    Maintenance = "maintenance",
    PdfPayments = "pdfPayments",
    ProductGroup = "productGroup",
    ProductLocations = "productLocations",
    ProductSizeTypes = "productSizeTypes",
    ProductSizes = "productSizes",
    Products = "products",
    ReleaseNotes = "releaseNotes",
    ReleaseNotesApproved = "releaseNotesApproved",
    RentalCategories = "rentalCategories",
    RentalPhotos = "rentalPhotos",
    RentalSearch = "rentalSearch",
    Rentals = "rentals",
    StripeTransactions = "stripeTransactions",
    Surveys = "surveys",
    Users = "users",
    Version = "version",
    Widgets = "widgets"
}

export enum SubCollection {
    Log = "log"
}

// export const Subcollections = {
//     [Collection.Products]: {
//         Log: 'log',
//         Images: 'images',
//     },
//     [Collection.ProductGroup]: {
//         Log: 'log',
//         Test: 'testtest',
//         Details: 'details',
//     },
// } as const;